.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}
.terminal {
  bottom: -20px;
  height: 200px;
}
.sticky {
  position: fixed;
  width: 100%;
}

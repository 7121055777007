@import url("assets/css/style.css");
@import url("assets/css/vendor.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.container {
  position: relative;
}
/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-appear .page-enter {
  opacity: 0;
  z-index: 1;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

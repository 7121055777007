.martin-title {
  font-weight: 500;
  font-size: 60px;
  color: white;
}
.bg-primary {
  background-color: #6d9eeb !important;
}
.tagline {
  color: white;
  font-weight: 400;
}

.landing-hero {
  padding-top: 150px;
  padding-bottom: 250px;
}

.hero-section {
  height: "550px";
}

#code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #0062ff;
  font-weight: 500;
  font-size: 70px;
}
.image-cropper {
  height: auto;
  width: 40%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border-style: solid;
  border-width: 4px 15px 15px 4px;
  border-color: #6d9eeb;
}

.typing {
  font-weight: 500;
  color: #0062ff;
  margin-right: 7px;
  margin-left: 7px;
}

.col-12.col-md-6.col-lg-4 {
  cursor: pointer;
}
figure:hover {
  cursor: pointer;
}

.project-gallery {
  margin-top: -10vh;
  margin-bottom: 0px;
  padding-bottom: 10px;
}

.icon {
  max-height: 90px;
  max-width: 90px;
  padding: 0px;
  float: right;
}

.experience-section {
  margin-top: 130px;
}

.projects-header {
  margin-top: 10px;
}

.contact-header {
  margin-top: 50px;
}

.parallax-mountains {
  /* The image used */

  background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url("../../assets/images/mountains.webp");

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 100%;
}

.overlay {
  background: rgba(0, 0, 0, 0.6);
}

.parallax-desk {
  /* The image used */
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../assets/images/coffee.webp");

  /* Full height */
  height: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

ul.project-cards li:nth-child(2) {
  margin-top: 40px !important;
}
ul.project-cards li:nth-child(3) {
  margin-top: 80px !important;
}
#hamburger {
  display: none;
}

.hero-background {
  background-color: #67c2ff;

  /* background-image: linear-gradient(90deg, #8BC6EC 0%, #9599E2 100%); */

  /* background: linear-gradient(90deg, #8BC6EC, #9599E2, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite; */
}

.parallax {
  perspective: 1px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.parallax__layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.parallax__layer--base {
  transform: translateZ(0);
}
.parallax__layer--back {
  transform: translateZ(-1px);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.blinking-cursor {
  border-right-style: solid;
  border-width: 10px;
  font-size: 30px;
  padding-top: 20px;

  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

.display-none {
  display: none;
}
@media screen and (max-width: 640px) {
  /* smartphones, Android phones, landscape iPhone */
  .image-cropper {
    width: 80%;
  }
  .project-gallery {
    margin-bottom: 0px;
  }

  .socials li .icon-mail {
    display: none;
  }

  .container .aos-init {
    width: 100%;
  }
  .icon {
    max-height: 18vw;
    max-width: 18vw;
    padding: 0px;
  }
  .parallax-mountains {
    overflow: hidden;
  }

  .landing-hero {
    padding-top: 100px;
  }
  h5 {
    padding-bottom: 10px;
  }
  .martin-title {
    font-size: 40px;
    margin-left: 20px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  #code {
    font-size: 40px;
  }
  .blinking-cursor {
    font-size: 20px;
  }
  .tagline {
    font-size: 25px !important;
  }

  .svg-container {
    height: 21px;
  }

  ul.project-cards li:nth-child(2) {
    margin-top: 0px !important;
  }
  ul.project-cards li:nth-child(3) {
    margin-top: 0px !important;
  }

  ul.project-cards li {
    margin-bottom: 40px;
  }

  #hamburger {
    display: block;
  }
}

.card-text {
  height: 80px !important;
}

.card-cell {
  padding: 10px;
}
@media screen and (max-width: 640px) {
  .card-text {
    height: 90px !important;
  }
  .card-cell {
    padding-left: 0px;
    padding-right: 0px;
  }
}

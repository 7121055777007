@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap");
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #0030ae;
  --indigo: #6610f2;
  --purple: #6E9EEB;
  --pink: #e83e8c;
  --red: #fc2c38;
  --orange: #f3a83c;
  --yellow: #e3c038;
  --green: #54af5d;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #555;
  --gray-dark: #333;
  --dark-blue: #0030ae;
  --transparent-white: rgba(255, 255, 255, 0.2);
  --transparent-black: rgba(0, 0, 0, 0.2);
  --primary: #6E9EEB;
  --secondary: #e5e5e5;
  --success: #54af5d;
  --info: #17a2b8;
  --warning: #e3c038;
  --danger: #fc2c38;
  --light: #f5f5f5;
  --dark: #151515;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1600px;
  --font-family-sans-serif: "Rubik", sans-serif;
  --font-family-monospace: "Roboto Mono", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #151515;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1em;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #6E9EEB;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #2f1b74;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: "Roboto Mono", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  color: #888;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 1em;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  line-height: 1.25;
  color: inherit;
}

h1,
.h1 {
  font-size: 3.125rem;
}

h2,
.h2 {
  font-size: 2.5rem;
}

h3,
.h3 {
  font-size: 2rem;
}

h4,
.h4 {
  font-size: 1.75rem;
}

h5,
.h5 {
  font-size: 1.5rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.25;
}

.display-2 {
  font-size: 4.375rem;
  font-weight: 300;
  line-height: 1.25;
}

.display-3 {
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.25;
}

.display-4 {
  font-size: 3.4375rem;
  font-weight: 300;
  line-height: 1.25;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #888;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #555;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #151515;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #151515;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1300px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #151515;
  background-color: transparent;
}
.table th,
.table td {
  padding: 1.875rem;
  vertical-align: top;
  border-top: 1px solid #e5e5e5;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e5e5e5;
}
.table tbody + tbody {
  border-top: 2px solid #e5e5e5;
}

.table-sm th,
.table-sm td {
  padding: 0.9375rem;
}

.table-bordered {
  border: 1px solid #e5e5e5;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e5e5e5;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #151515;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ccc3e9;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a090d7;
}

.table-hover .table-primary:hover {
  background-color: #bcb0e2;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #bcb0e2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f8f8f8;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f1f1f1;
}

.table-hover .table-secondary:hover {
  background-color: #ebebeb;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #ebebeb;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cfe9d2;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a6d5ab;
}

.table-hover .table-success:hover {
  background-color: #bee1c2;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bee1c2;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f7edc7;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f0de98;
}

.table-hover .table-warning:hover {
  background-color: #f4e6b1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f4e6b1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fec4c7;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fd9198;
}

.table-hover .table-danger:hover {
  background-color: #feabaf;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #feabaf;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafafa;
}

.table-hover .table-light:hover {
  background-color: #efefef;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #efefef;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bdbdbd;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #858585;
}

.table-hover .table-dark:hover {
  background-color: #b0b0b0;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b0b0b0;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #151515;
  border-color: #282828;
}

.table .thead-light th {
  color: #444;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.table-dark {
  color: #fff;
  background-color: #151515;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #282828;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(3.375rem + 2px);
  padding: 0.9375rem 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #151515;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #151515;
  background-color: #fff;
  border-color: #917ae0;
  outline: 0;
  box-shadow: 0;
}
.form-control::placeholder {
  color: #999;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #151515;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.9375rem + 1px);
  padding-bottom: calc(0.9375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1.1rem + 1px);
  padding-bottom: calc(1.1rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #151515;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(2.3125rem + 2px);
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(3.8875rem + 2px);
  padding: 1.1rem 1.1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #888;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #54af5d;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(84, 175, 93, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #54af5d;
  padding-right: 3.375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2354AF5D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.46875rem);
  background-size: calc(0.75em + 0.9375rem) calc(0.75em + 0.9375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #54af5d;
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: 3.375rem;
  background-position: top calc(0.375em + 0.46875rem) right
    calc(0.375em + 0.46875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #54af5d;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2354AF5D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.9375rem)
      calc(0.75em + 0.9375rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #54af5d;
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #54af5d;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #54af5d;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #54af5d;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #77bf7e;
  background-color: #77bf7e;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #54af5d;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #54af5d;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #54af5d;
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fc2c38;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(252, 44, 56, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #fc2c38;
  padding-right: 3.375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fc2c38' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fc2c38' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.46875rem);
  background-size: calc(0.75em + 0.9375rem) calc(0.75em + 0.9375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #fc2c38;
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: 3.375rem;
  background-position: top calc(0.375em + 0.46875rem) right
    calc(0.375em + 0.46875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #fc2c38;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fc2c38' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fc2c38' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.9375rem)
      calc(0.75em + 0.9375rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #fc2c38;
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #fc2c38;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #fc2c38;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #fc2c38;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fd5e67;
  background-color: #fd5e67;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #fc2c38;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #fc2c38;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #fc2c38;
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #151515;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.9375rem 1.875rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #151515;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-primary:hover {
  color: #fff;
  background-color: #3b2293;
  border-color: #371f89;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0 rgba(99, 73, 190, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #371f89;
  border-color: #331d7e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(99, 73, 190, 0.5);
}

.btn-secondary {
  color: #151515;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-secondary:hover {
  color: #151515;
  background-color: #d2d2d2;
  border-color: #cccccc;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0 rgba(198, 198, 198, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #151515;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #151515;
  background-color: #cccccc;
  border-color: #c5c5c5;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(198, 198, 198, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #54af5d;
  border-color: #54af5d;
}
.btn-success:hover {
  color: #fff;
  background-color: #46964e;
  border-color: #428e4a;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0 rgba(110, 187, 117, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #54af5d;
  border-color: #54af5d;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #428e4a;
  border-color: #3e8545;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(110, 187, 117, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #151515;
  background-color: #e3c038;
  border-color: #e3c038;
}
.btn-warning:hover {
  color: #151515;
  background-color: #d7b11e;
  border-color: #cba81d;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0 rgba(196, 166, 51, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #151515;
  background-color: #e3c038;
  border-color: #e3c038;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #151515;
  background-color: #cba81d;
  border-color: #c09e1b;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(196, 166, 51, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #fc2c38;
  border-color: #fc2c38;
}
.btn-danger:hover {
  color: #fff;
  background-color: #fb0614;
  border-color: #f20311;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0 rgba(252, 76, 86, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #fc2c38;
  border-color: #fc2c38;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f20311;
  border-color: #e50310;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(252, 76, 86, 0.5);
}

.btn-light {
  color: #151515;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-light:hover {
  color: #151515;
  background-color: #e2e2e2;
  border-color: gainsboro;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #151515;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #151515;
  background-color: gainsboro;
  border-color: #d5d5d5;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}
.btn-dark:hover {
  color: #fff;
  background-color: #020202;
  border-color: black;
}
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5);
}

.btn-outline-primary {
  color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(72, 41, 178, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #6E9EEB;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(72, 41, 178, 0.5);
}

.btn-outline-secondary {
  color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-outline-secondary:hover {
  color: #151515;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #e5e5e5;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #151515;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5);
}

.btn-outline-success {
  color: #54af5d;
  border-color: #54af5d;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #54af5d;
  border-color: #54af5d;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #54af5d;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #54af5d;
  border-color: #54af5d;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #e3c038;
  border-color: #e3c038;
}
.btn-outline-warning:hover {
  color: #151515;
  background-color: #e3c038;
  border-color: #e3c038;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(227, 192, 56, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #e3c038;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #151515;
  background-color: #e3c038;
  border-color: #e3c038;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(227, 192, 56, 0.5);
}

.btn-outline-danger {
  color: #fc2c38;
  border-color: #fc2c38;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #fc2c38;
  border-color: #fc2c38;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #fc2c38;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fc2c38;
  border-color: #fc2c38;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.5);
}

.btn-outline-light {
  color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:hover {
  color: #151515;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f5f5f5;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #151515;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
}

.btn-outline-dark {
  color: #151515;
  border-color: #151515;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #151515;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #6E9EEB;
  text-decoration: none;
}
.btn-link:hover {
  color: #2f1b74;
  text-decoration: underline;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #555;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 1.1rem 2.2rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #151515;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.625rem 1.25rem;
  clear: both;
  font-weight: 400;
  color: #999;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #080808;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #6E9EEB;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #555;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #555;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.625rem 1.25rem;
  color: #999;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.40625rem;
  padding-left: 1.40625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.65rem;
  padding-left: 1.65rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.9375rem 0.9375rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #151515;
  text-align: center;
  white-space: nowrap;
  background-color: #e5e5e5;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(3.8875rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.1rem 1.1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(2.3125rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #6E9EEB;
  background-color: #6E9EEB;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #917ae0;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3a4ea;
  border-color: #b3a4ea;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #555;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e5e5e5;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #999;
  border: #777 solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 75% 75%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' %3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #6E9EEB;
  background-color: #6E9EEB;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(72, 41, 178, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(72, 41, 178, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(72, 41, 178, 0.5);
}

.custom-switch {
  padding-left: 3.25rem;
}
.custom-switch .custom-control-label::before {
  left: -3.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-3.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #777;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #999;
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(72, 41, 178, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(3.375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #151515;
  vertical-align: middle;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #917ae0;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(145, 122, 224, 0.5);
}
.custom-select:focus::-ms-value {
  color: #151515;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #555;
  background-color: #e5e5e5;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(2.3125rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(3.8875rem + 2px);
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  padding-left: 1.1rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(3.375rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(3.375rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #917ae0;
  box-shadow: 0;
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: rgba(0, 0, 0, 0.1);
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(3.375rem + 2px);
  padding: 0.9375rem 1.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #151515;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 3.375rem;
  padding: 0.9375rem 1.875rem;
  line-height: 1.5;
  color: #151515;
  content: "Browse";
  background-color: #e5e5e5;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #6E9EEB;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3a4ea;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #999;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #6E9EEB;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3a4ea;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #999;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #6E9EEB;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3a4ea;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #999;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #999;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #777;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #777;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #777;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #555;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #999;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e5e5e5 #e5e5e5 #999;
}
.nav-tabs .nav-link.disabled {
  color: #555;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #444;
  background-color: #fff;
  border-color: #999 #999 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: transparent;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0 0;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1599.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: #fff;
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #e5e5e5;
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.875rem;
}

.card-title {
  margin-bottom: 0.9375rem;
}

.card-subtitle {
  margin-top: -0.46875rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.875rem;
}

.card-header {
  padding: 0.9375rem 1.875rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid #e5e5e5;
}
.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.9375rem 1.875rem;
  background-color: transparent;
  border-top: 0 solid #e5e5e5;
}
.card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.card-header-tabs {
  margin-right: -0.9375rem;
  margin-bottom: -0.9375rem;
  margin-left: -0.9375rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: 0.25rem;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.9375rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #555;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #555;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #6E9EEB;
  background-color: #fff;
  border: 0 solid transparent;
}
.page-link:hover {
  z-index: 2;
  color: #2f1b74;
  text-decoration: none;
  background-color: #e5e5e5;
  border-color: #999;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}

.page-item.disabled .page-link {
  color: #555;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #999;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1em;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #6E9EEB;
}
a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #371f89;
}
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(72, 41, 178, 0.5);
}

.badge-secondary {
  color: #151515;
  background-color: #e5e5e5;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #151515;
  background-color: #cccccc;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #54af5d;
}
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #428e4a;
}
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #151515;
  background-color: #e3c038;
}
a.badge-warning:hover,
a.badge-warning:focus {
  color: #151515;
  background-color: #cba81d;
}
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(227, 192, 56, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #fc2c38;
}
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #f20311;
}
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.5);
}

.badge-light {
  color: #151515;
  background-color: #f5f5f5;
}
a.badge-light:hover,
a.badge-light:focus {
  color: #151515;
  background-color: gainsboro;
}
a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #151515;
}
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: black;
}
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e5e5e5;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 1.875rem 1.875rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 5.25rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.875rem 1.875rem;
  color: inherit;
}

.alert-primary {
  color: #1a0f40;
  background-color: #836dcb;
  border-color: #ccc3e9;
}
.alert-primary hr {
  border-top-color: #bcb0e2;
}
.alert-primary .alert-link {
  color: #090517;
}

.alert-secondary {
  color: #525252;
  background-color: #ededed;
  border-color: #f8f8f8;
}
.alert-secondary hr {
  border-top-color: #ebebeb;
}
.alert-secondary .alert-link {
  color: #393939;
}

.alert-success {
  color: #1e3f21;
  background-color: #8bc991;
  border-color: #cfe9d2;
}
.alert-success hr {
  border-top-color: #bee1c2;
}
.alert-success .alert-link {
  color: #0e1c0f;
}

.alert-info {
  color: #083a42;
  background-color: #61c0cf;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #021215;
}

.alert-warning {
  color: #524514;
  background-color: #ecd478;
  border-color: #f7edc7;
}
.alert-warning hr {
  border-top-color: #f4e6b1;
}
.alert-warning .alert-link {
  color: #29230a;
}

.alert-danger {
  color: #5b1014;
  background-color: #fd7078;
  border-color: #fec4c7;
}
.alert-danger hr {
  border-top-color: #feabaf;
}
.alert-danger .alert-link {
  color: #30080a;
}

.alert-light {
  color: #585858;
  background-color: #f8f8f8;
  border-color: #fcfcfc;
}
.alert-light hr {
  border-top-color: #efefef;
}
.alert-light .alert-link {
  color: #3f3f3f;
}

.alert-dark {
  color: #080808;
  background-color: #606060;
  border-color: #bdbdbd;
}
.alert-dark hr {
  border-top-color: #b0b0b0;
}
.alert-dark .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #6E9EEB;
  text-align: center;
  white-space: nowrap;
  background-color: #6E9EEB;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.5rem 0.5rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #999;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #999;
  text-decoration: none;
  background-color: #f5f5f5;
}
.list-group-item-action:active {
  color: #151515;
  background-color: #e5e5e5;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 1.25rem 1.25rem;
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid #e5e5e5;
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #555;
  pointer-events: none;
  background-color: transparent;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1600px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #25155d;
  background-color: #ccc3e9;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #25155d;
  background-color: #bcb0e2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #25155d;
  border-color: #25155d;
}

.list-group-item-secondary {
  color: #777777;
  background-color: #f8f8f8;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #777777;
  background-color: #ebebeb;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #777777;
  border-color: #777777;
}

.list-group-item-success {
  color: #2c5b30;
  background-color: #cfe9d2;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #2c5b30;
  background-color: #bee1c2;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #2c5b30;
  border-color: #2c5b30;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #76641d;
  background-color: #f7edc7;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #76641d;
  background-color: #f4e6b1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #76641d;
  border-color: #76641d;
}

.list-group-item-danger {
  color: #83171d;
  background-color: #fec4c7;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #83171d;
  background-color: #feabaf;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #83171d;
  border-color: #83171d;
}

.list-group-item-light {
  color: #7f7f7f;
  background-color: #fcfcfc;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-dark {
  color: #0b0b0b;
  background-color: #bdbdbd;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #0b0b0b;
  background-color: #b0b0b0;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0b0b0b;
  border-color: #0b0b0b;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #555;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.875rem;
  border-bottom: 0 solid #e5e5e5;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1.875rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.875rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.875rem;
  border-top: 0 solid #e5e5e5;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1600px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #151515;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #6E9EEB !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #371f89 !important;
}

.bg-secondary {
  background-color: #e5e5e5 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cccccc !important;
}

.bg-success {
  background-color: #54af5d !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #428e4a !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #e3c038 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cba81d !important;
}

.bg-danger {
  background-color: #fc2c38 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f20311 !important;
}

.bg-light {
  background-color: #f5f5f5 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: gainsboro !important;
}

.bg-dark {
  background-color: #151515 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e5e5e5 !important;
}

.border-top {
  border-top: 1px solid #e5e5e5 !important;
}

.border-right {
  border-right: 1px solid #e5e5e5 !important;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5 !important;
}

.border-left {
  border-left: 1px solid #e5e5e5 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #6E9EEB !important;
}

.border-secondary {
  border-color: #e5e5e5 !important;
}

.border-success {
  border-color: #54af5d !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #e3c038 !important;
}

.border-danger {
  border-color: #fc2c38 !important;
}

.border-light {
  border-color: #f5f5f5 !important;
}

.border-dark {
  border-color: #151515 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1600px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-20 {
  width: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-20 {
  height: 20% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.625rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.625rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.625rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.625rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.625rem !important;
}

.m-2 {
  margin: 1.25rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1.25rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1.25rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1.25rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1.25rem !important;
}

.m-3 {
  margin: 1.875rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.875rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.875rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.875rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.875rem !important;
}

.m-4 {
  margin: 2.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2.5rem !important;
}

.m-5 {
  margin: 3.125rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3.125rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3.125rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3.125rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3.125rem !important;
}

.m-6 {
  margin: 3.75rem !important;
}

.mt-6,
.my-6 {
  margin-top: 3.75rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 3.75rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 3.75rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 3.75rem !important;
}

.m-7 {
  margin: 4.375rem !important;
}

.mt-7,
.my-7 {
  margin-top: 4.375rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 4.375rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 4.375rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 4.375rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 5rem !important;
}

.m-9 {
  margin: 5.625rem !important;
}

.mt-9,
.my-9 {
  margin-top: 5.625rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 5.625rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 5.625rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 5.625rem !important;
}

.m-10 {
  margin: 6.25rem !important;
}

.mt-10,
.my-10 {
  margin-top: 6.25rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 6.25rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 6.25rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 6.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.625rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.625rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.625rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.625rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.625rem !important;
}

.p-2 {
  padding: 1.25rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1.25rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1.25rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1.25rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1.25rem !important;
}

.p-3 {
  padding: 1.875rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.875rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.875rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.875rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.875rem !important;
}

.p-4 {
  padding: 2.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2.5rem !important;
}

.p-5 {
  padding: 3.125rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3.125rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3.125rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3.125rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3.125rem !important;
}

.p-6 {
  padding: 3.75rem !important;
}

.pt-6,
.py-6 {
  padding-top: 3.75rem !important;
}

.pr-6,
.px-6 {
  padding-right: 3.75rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 3.75rem !important;
}

.pl-6,
.px-6 {
  padding-left: 3.75rem !important;
}

.p-7 {
  padding: 4.375rem !important;
}

.pt-7,
.py-7 {
  padding-top: 4.375rem !important;
}

.pr-7,
.px-7 {
  padding-right: 4.375rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 4.375rem !important;
}

.pl-7,
.px-7 {
  padding-left: 4.375rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 5rem !important;
}

.p-9 {
  padding: 5.625rem !important;
}

.pt-9,
.py-9 {
  padding-top: 5.625rem !important;
}

.pr-9,
.px-9 {
  padding-right: 5.625rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 5.625rem !important;
}

.pl-9,
.px-9 {
  padding-left: 5.625rem !important;
}

.p-10 {
  padding: 6.25rem !important;
}

.pt-10,
.py-10 {
  padding-top: 6.25rem !important;
}

.pr-10,
.px-10 {
  padding-right: 6.25rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 6.25rem !important;
}

.pl-10,
.px-10 {
  padding-left: 6.25rem !important;
}

.m-n1 {
  margin: -0.625rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.625rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.625rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.625rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.625rem !important;
}

.m-n2 {
  margin: -1.25rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -1.25rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -1.25rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -1.25rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -1.25rem !important;
}

.m-n3 {
  margin: -1.875rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.875rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.875rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.875rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.875rem !important;
}

.m-n4 {
  margin: -2.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -2.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -2.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -2.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -2.5rem !important;
}

.m-n5 {
  margin: -3.125rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3.125rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3.125rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3.125rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3.125rem !important;
}

.m-n6 {
  margin: -3.75rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -3.75rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -3.75rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -3.75rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -3.75rem !important;
}

.m-n7 {
  margin: -4.375rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -4.375rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -4.375rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -4.375rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -4.375rem !important;
}

.m-n8 {
  margin: -5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -5rem !important;
}

.m-n9 {
  margin: -5.625rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -5.625rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -5.625rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -5.625rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -5.625rem !important;
}

.m-n10 {
  margin: -6.25rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -6.25rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -6.25rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -6.25rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -6.25rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.625rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.625rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.625rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.625rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.625rem !important;
  }
  .m-sm-2 {
    margin: 1.25rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.25rem !important;
  }
  .m-sm-3 {
    margin: 1.875rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.875rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.875rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.875rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.875rem !important;
  }
  .m-sm-4 {
    margin: 2.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.5rem !important;
  }
  .m-sm-5 {
    margin: 3.125rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3.125rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3.125rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3.125rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3.125rem !important;
  }
  .m-sm-6 {
    margin: 3.75rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3.75rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3.75rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3.75rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3.75rem !important;
  }
  .m-sm-7 {
    margin: 4.375rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4.375rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4.375rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4.375rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4.375rem !important;
  }
  .m-sm-8 {
    margin: 5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important;
  }
  .m-sm-9 {
    margin: 5.625rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 5.625rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 5.625rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 5.625rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 5.625rem !important;
  }
  .m-sm-10 {
    margin: 6.25rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 6.25rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 6.25rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 6.25rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.625rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.625rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.625rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.625rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.625rem !important;
  }
  .p-sm-2 {
    padding: 1.25rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.25rem !important;
  }
  .p-sm-3 {
    padding: 1.875rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.875rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.875rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.875rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.875rem !important;
  }
  .p-sm-4 {
    padding: 2.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.5rem !important;
  }
  .p-sm-5 {
    padding: 3.125rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3.125rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3.125rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3.125rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3.125rem !important;
  }
  .p-sm-6 {
    padding: 3.75rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3.75rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3.75rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3.75rem !important;
  }
  .p-sm-7 {
    padding: 4.375rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4.375rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4.375rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4.375rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4.375rem !important;
  }
  .p-sm-8 {
    padding: 5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important;
  }
  .p-sm-9 {
    padding: 5.625rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 5.625rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 5.625rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 5.625rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 5.625rem !important;
  }
  .p-sm-10 {
    padding: 6.25rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 6.25rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 6.25rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 6.25rem !important;
  }
  .m-sm-n1 {
    margin: -0.625rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.625rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.625rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.625rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.625rem !important;
  }
  .m-sm-n2 {
    margin: -1.25rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1.25rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1.25rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1.25rem !important;
  }
  .m-sm-n3 {
    margin: -1.875rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.875rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.875rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.875rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.875rem !important;
  }
  .m-sm-n4 {
    margin: -2.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n5 {
    margin: -3.125rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3.125rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3.125rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3.125rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3.125rem !important;
  }
  .m-sm-n6 {
    margin: -3.75rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -3.75rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -3.75rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -3.75rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -3.75rem !important;
  }
  .m-sm-n7 {
    margin: -4.375rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -4.375rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -4.375rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -4.375rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -4.375rem !important;
  }
  .m-sm-n8 {
    margin: -5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -5rem !important;
  }
  .m-sm-n9 {
    margin: -5.625rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -5.625rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -5.625rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -5.625rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -5.625rem !important;
  }
  .m-sm-n10 {
    margin: -6.25rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -6.25rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.625rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.625rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.625rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.625rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.625rem !important;
  }
  .m-md-2 {
    margin: 1.25rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.25rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.25rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.25rem !important;
  }
  .m-md-3 {
    margin: 1.875rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.875rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.875rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.875rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.875rem !important;
  }
  .m-md-4 {
    margin: 2.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.5rem !important;
  }
  .m-md-5 {
    margin: 3.125rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3.125rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3.125rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3.125rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3.125rem !important;
  }
  .m-md-6 {
    margin: 3.75rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3.75rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3.75rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3.75rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3.75rem !important;
  }
  .m-md-7 {
    margin: 4.375rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4.375rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4.375rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4.375rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4.375rem !important;
  }
  .m-md-8 {
    margin: 5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important;
  }
  .m-md-9 {
    margin: 5.625rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 5.625rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 5.625rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 5.625rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 5.625rem !important;
  }
  .m-md-10 {
    margin: 6.25rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 6.25rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 6.25rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 6.25rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.625rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.625rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.625rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.625rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.625rem !important;
  }
  .p-md-2 {
    padding: 1.25rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.25rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.25rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.25rem !important;
  }
  .p-md-3 {
    padding: 1.875rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.875rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.875rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.875rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.875rem !important;
  }
  .p-md-4 {
    padding: 2.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.5rem !important;
  }
  .p-md-5 {
    padding: 3.125rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3.125rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3.125rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3.125rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3.125rem !important;
  }
  .p-md-6 {
    padding: 3.75rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3.75rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3.75rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3.75rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3.75rem !important;
  }
  .p-md-7 {
    padding: 4.375rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4.375rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4.375rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4.375rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4.375rem !important;
  }
  .p-md-8 {
    padding: 5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important;
  }
  .p-md-9 {
    padding: 5.625rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 5.625rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 5.625rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 5.625rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 5.625rem !important;
  }
  .p-md-10 {
    padding: 6.25rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 6.25rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 6.25rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 6.25rem !important;
  }
  .m-md-n1 {
    margin: -0.625rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.625rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.625rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.625rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.625rem !important;
  }
  .m-md-n2 {
    margin: -1.25rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1.25rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1.25rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1.25rem !important;
  }
  .m-md-n3 {
    margin: -1.875rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.875rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.875rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.875rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.875rem !important;
  }
  .m-md-n4 {
    margin: -2.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.5rem !important;
  }
  .m-md-n5 {
    margin: -3.125rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3.125rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3.125rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3.125rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3.125rem !important;
  }
  .m-md-n6 {
    margin: -3.75rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -3.75rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -3.75rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -3.75rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -3.75rem !important;
  }
  .m-md-n7 {
    margin: -4.375rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -4.375rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -4.375rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -4.375rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -4.375rem !important;
  }
  .m-md-n8 {
    margin: -5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -5rem !important;
  }
  .m-md-n9 {
    margin: -5.625rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -5.625rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -5.625rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -5.625rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -5.625rem !important;
  }
  .m-md-n10 {
    margin: -6.25rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -6.25rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.625rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.625rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.625rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.625rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.625rem !important;
  }
  .m-lg-2 {
    margin: 1.25rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.25rem !important;
  }
  .m-lg-3 {
    margin: 1.875rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.875rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.875rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.875rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.875rem !important;
  }
  .m-lg-4 {
    margin: 2.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.5rem !important;
  }
  .m-lg-5 {
    margin: 3.125rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3.125rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3.125rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3.125rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3.125rem !important;
  }
  .m-lg-6 {
    margin: 3.75rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3.75rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3.75rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3.75rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3.75rem !important;
  }
  .m-lg-7 {
    margin: 4.375rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4.375rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4.375rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4.375rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4.375rem !important;
  }
  .m-lg-8 {
    margin: 5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important;
  }
  .m-lg-9 {
    margin: 5.625rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 5.625rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 5.625rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 5.625rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 5.625rem !important;
  }
  .m-lg-10 {
    margin: 6.25rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 6.25rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 6.25rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 6.25rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.625rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.625rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.625rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.625rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.625rem !important;
  }
  .p-lg-2 {
    padding: 1.25rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.25rem !important;
  }
  .p-lg-3 {
    padding: 1.875rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.875rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.875rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.875rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.875rem !important;
  }
  .p-lg-4 {
    padding: 2.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.5rem !important;
  }
  .p-lg-5 {
    padding: 3.125rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3.125rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3.125rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3.125rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3.125rem !important;
  }
  .p-lg-6 {
    padding: 3.75rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3.75rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3.75rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3.75rem !important;
  }
  .p-lg-7 {
    padding: 4.375rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4.375rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4.375rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4.375rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4.375rem !important;
  }
  .p-lg-8 {
    padding: 5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important;
  }
  .p-lg-9 {
    padding: 5.625rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 5.625rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 5.625rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 5.625rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 5.625rem !important;
  }
  .p-lg-10 {
    padding: 6.25rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 6.25rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 6.25rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 6.25rem !important;
  }
  .m-lg-n1 {
    margin: -0.625rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.625rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.625rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.625rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.625rem !important;
  }
  .m-lg-n2 {
    margin: -1.25rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1.25rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1.25rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1.25rem !important;
  }
  .m-lg-n3 {
    margin: -1.875rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.875rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.875rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.875rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.875rem !important;
  }
  .m-lg-n4 {
    margin: -2.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n5 {
    margin: -3.125rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3.125rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3.125rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3.125rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3.125rem !important;
  }
  .m-lg-n6 {
    margin: -3.75rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -3.75rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -3.75rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -3.75rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -3.75rem !important;
  }
  .m-lg-n7 {
    margin: -4.375rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -4.375rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -4.375rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -4.375rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -4.375rem !important;
  }
  .m-lg-n8 {
    margin: -5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -5rem !important;
  }
  .m-lg-n9 {
    margin: -5.625rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -5.625rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -5.625rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -5.625rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -5.625rem !important;
  }
  .m-lg-n10 {
    margin: -6.25rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -6.25rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.625rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.625rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.625rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.625rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.625rem !important;
  }
  .m-xl-2 {
    margin: 1.25rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1.25rem !important;
  }
  .m-xl-3 {
    margin: 1.875rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.875rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.875rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.875rem !important;
  }
  .m-xl-4 {
    margin: 2.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.5rem !important;
  }
  .m-xl-5 {
    margin: 3.125rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3.125rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3.125rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3.125rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3.125rem !important;
  }
  .m-xl-6 {
    margin: 3.75rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3.75rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3.75rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3.75rem !important;
  }
  .m-xl-7 {
    margin: 4.375rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 4.375rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 4.375rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 4.375rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 4.375rem !important;
  }
  .m-xl-8 {
    margin: 5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important;
  }
  .m-xl-9 {
    margin: 5.625rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 5.625rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 5.625rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 5.625rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 5.625rem !important;
  }
  .m-xl-10 {
    margin: 6.25rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 6.25rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 6.25rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 6.25rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.625rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.625rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.625rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.625rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.625rem !important;
  }
  .p-xl-2 {
    padding: 1.25rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1.25rem !important;
  }
  .p-xl-3 {
    padding: 1.875rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.875rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.875rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.875rem !important;
  }
  .p-xl-4 {
    padding: 2.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.5rem !important;
  }
  .p-xl-5 {
    padding: 3.125rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3.125rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3.125rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3.125rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3.125rem !important;
  }
  .p-xl-6 {
    padding: 3.75rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3.75rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3.75rem !important;
  }
  .p-xl-7 {
    padding: 4.375rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 4.375rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 4.375rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 4.375rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 4.375rem !important;
  }
  .p-xl-8 {
    padding: 5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important;
  }
  .p-xl-9 {
    padding: 5.625rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 5.625rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 5.625rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 5.625rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 5.625rem !important;
  }
  .p-xl-10 {
    padding: 6.25rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 6.25rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 6.25rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 6.25rem !important;
  }
  .m-xl-n1 {
    margin: -0.625rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.625rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.625rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.625rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.625rem !important;
  }
  .m-xl-n2 {
    margin: -1.25rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1.25rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1.25rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1.25rem !important;
  }
  .m-xl-n3 {
    margin: -1.875rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.875rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.875rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.875rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.875rem !important;
  }
  .m-xl-n4 {
    margin: -2.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n5 {
    margin: -3.125rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3.125rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3.125rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3.125rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3.125rem !important;
  }
  .m-xl-n6 {
    margin: -3.75rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -3.75rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -3.75rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -3.75rem !important;
  }
  .m-xl-n7 {
    margin: -4.375rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -4.375rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -4.375rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -4.375rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -4.375rem !important;
  }
  .m-xl-n8 {
    margin: -5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -5rem !important;
  }
  .m-xl-n9 {
    margin: -5.625rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -5.625rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -5.625rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -5.625rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -5.625rem !important;
  }
  .m-xl-n10 {
    margin: -6.25rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -6.25rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: "Roboto Mono", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #6E9EEB !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #2f1b74 !important;
}

.text-secondary {
  color: #e5e5e5 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #bfbfbf !important;
}

.text-success {
  color: #54af5d !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #3a7c41 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #e3c038 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #b59519 !important;
}

.text-danger {
  color: #fc2c38 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #d8030f !important;
}

.text-light {
  color: #f5f5f5 !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cfcfcf !important;
}

.text-dark {
  color: #151515 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #151515 !important;
}

.text-muted {
  color: #888 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #777;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1200px !important;
  }
  .container {
    min-width: 1200px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #999 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e5e5e5;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e5e5e5;
  }
}

.fs-12 {
  font-size: 0.75rem !important;
  line-height: 1.4em;
}

.fs-14 {
  font-size: 0.875rem !important;
  line-height: 1.4em;
}

.fs-16 {
  font-size: 1rem !important;
  line-height: 1.4em;
}

.fs-18 {
  font-size: 1.125rem !important;
  line-height: 1.4em;
}

.fs-20 {
  font-size: 1.25rem !important;
  line-height: 1.4em;
}

.fs-22 {
  font-size: 1.375rem !important;
  line-height: 1.4em;
}

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 1.4em;
}

.fs-26 {
  font-size: 1.625rem !important;
  line-height: 1.4em;
}

.fs-28 {
  font-size: 1.75rem !important;
  line-height: 1.4em;
}

.fs-30 {
  font-size: 1.875rem !important;
  line-height: 1.4em;
}

.fs-32 {
  font-size: 2rem !important;
  line-height: 1.4em;
}

.fs-34 {
  font-size: 2.125rem !important;
  line-height: 1.4em;
}

.fs-36 {
  font-size: 2.25rem !important;
  line-height: 1.4em;
}

.fs-38 {
  font-size: 2.375rem !important;
  line-height: 1.4em;
}

.fs-40 {
  font-size: 2.5rem !important;
  line-height: 1.4em;
}

.fs-50 {
  font-size: 3.125rem !important;
  line-height: 1.4em;
}

.fs-60 {
  font-size: 3.75rem !important;
  line-height: 1.4em;
}

.fs-70 {
  font-size: 4.375rem !important;
  line-height: 1.4em;
}

.fs-80 {
  font-size: 5rem !important;
  line-height: 1.4em;
}

.fs-90 {
  font-size: 5.625rem !important;
  line-height: 1.4em;
}

.fs-100 {
  font-size: 6.25rem !important;
  line-height: 1.4em;
}

.overflow-hidden {
  overflow: hidden;
}

.bg-blue {
  background-color: #0030ae !important;
}

a.bg-blue:hover,
a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #00227b !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

a.bg-indigo:hover,
a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important;
}

.bg-purple {
  background-color: #6E9EEB !important;
}

a.bg-purple:hover,
a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #371f89 !important;
}

.bg-pink {
  background-color: #e83e8c !important;
}

a.bg-pink:hover,
a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important;
}

.bg-red {
  background-color: #fc2c38 !important;
}

a.bg-red:hover,
a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #f20311 !important;
}

.bg-orange {
  background-color: #f3a83c !important;
}

a.bg-orange:hover,
a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #ed920f !important;
}

.bg-yellow {
  background-color: #e3c038 !important;
}

a.bg-yellow:hover,
a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #cba81d !important;
}

.bg-green {
  background-color: #54af5d !important;
}

a.bg-green:hover,
a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #428e4a !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

a.bg-teal:hover,
a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important;
}

.bg-cyan {
  background-color: #17a2b8 !important;
}

a.bg-cyan:hover,
a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #117a8b !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray {
  background-color: #555 !important;
}

a.bg-gray:hover,
a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #3c3c3c !important;
}

.bg-gray-dark {
  background-color: #333 !important;
}

a.bg-gray-dark:hover,
a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #1a1a1a !important;
}

.bg-dark-blue {
  background-color: #0030ae !important;
}

a.bg-dark-blue:hover,
a.bg-dark-blue:focus,
button.bg-dark-blue:hover,
button.bg-dark-blue:focus {
  background-color: #00227b !important;
}

.bg-transparent-white {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

a.bg-transparent-white:hover,
a.bg-transparent-white:focus,
button.bg-transparent-white:hover,
button.bg-transparent-white:focus {
  background-color: rgba(230, 230, 230, 0.2) !important;
}

.bg-transparent-black {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

a.bg-transparent-black:hover,
a.bg-transparent-black:focus,
button.bg-transparent-black:hover,
button.bg-transparent-black:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.bg-image {
  background-size: cover;
  background-position: center;
}
.bg-image::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}
.bg-image > .container {
  position: relative;
  z-index: 20;
}
.bg-image > .image[class*="blured"] {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  position: absolute;
  top: -2.5rem;
  right: -2.5rem;
  bottom: -2.5rem;
  left: -2.5rem;
}

.text-blue {
  color: #0030ae !important;
}

a.text-blue:hover,
a.text-blue:focus {
  color: #001b62 !important;
}

.text-indigo {
  color: #6610f2 !important;
}

a.text-indigo:hover,
a.text-indigo:focus {
  color: #4709ac !important;
}

.text-purple {
  color: #6E9EEB !important;
}

a.text-purple:hover,
a.text-purple:focus {
  color: #2f1b74 !important;
}

.text-pink {
  color: #e83e8c !important;
}

a.text-pink:hover,
a.text-pink:focus {
  color: #c21766 !important;
}

.text-red {
  color: #fc2c38 !important;
}

a.text-red:hover,
a.text-red:focus {
  color: #d8030f !important;
}

.text-orange {
  color: #f3a83c !important;
}

a.text-orange:hover,
a.text-orange:focus {
  color: #d5830d !important;
}

.text-yellow {
  color: #e3c038 !important;
}

a.text-yellow:hover,
a.text-yellow:focus {
  color: #b59519 !important;
}

.text-green {
  color: #54af5d !important;
}

a.text-green:hover,
a.text-green:focus {
  color: #3a7c41 !important;
}

.text-teal {
  color: #20c997 !important;
}

a.text-teal:hover,
a.text-teal:focus {
  color: #158765 !important;
}

.text-cyan {
  color: #17a2b8 !important;
}

a.text-cyan:hover,
a.text-cyan:focus {
  color: #0f6674 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-gray {
  color: #555 !important;
}

a.text-gray:hover,
a.text-gray:focus {
  color: #2f2f2f !important;
}

.text-gray-dark {
  color: #333 !important;
}

a.text-gray-dark:hover,
a.text-gray-dark:focus {
  color: #0d0d0d !important;
}

.text-dark-blue {
  color: #0030ae !important;
}

a.text-dark-blue:hover,
a.text-dark-blue:focus {
  color: #001b62 !important;
}

.text-transparent-white {
  color: rgba(255, 255, 255, 0.2) !important;
}

a.text-transparent-white:hover,
a.text-transparent-white:focus {
  color: rgba(217, 217, 217, 0.2) !important;
}

.text-transparent-black {
  color: rgba(0, 0, 0, 0.2) !important;
}

a.text-transparent-black:hover,
a.text-transparent-black:focus {
  color: rgba(0, 0, 0, 0.2) !important;
}

.text-facebook {
  color: #3b5998 !important;
}

a.text-facebook:hover,
a.text-facebook:focus {
  color: #263961 !important;
}

.text-google-plus {
  color: #dd4b39 !important;
}

a.text-google-plus:hover,
a.text-google-plus:focus {
  color: #ac2d1e !important;
}

.text-twitter {
  color: #00aced !important;
}

a.text-twitter:hover,
a.text-twitter:focus {
  color: #0074a1 !important;
}

.text-linkedin {
  color: #007bb6 !important;
}

a.text-linkedin:hover,
a.text-linkedin:focus {
  color: #00476a !important;
}

.text-pinterest {
  color: #cb2027 !important;
}

a.text-pinterest:hover,
a.text-pinterest:focus {
  color: #89161a !important;
}

.text-git {
  color: #666666 !important;
}

a.text-git:hover,
a.text-git:focus {
  color: #404040 !important;
}

.text-tumblr {
  color: #32506d !important;
}

a.text-tumblr:hover,
a.text-tumblr:focus {
  color: #1a2a39 !important;
}

.text-vimeo {
  color: #aad450 !important;
}

a.text-vimeo:hover,
a.text-vimeo:focus {
  color: #83ad2b !important;
}

.text-flickr {
  color: #ff0084 !important;
}

a.text-flickr:hover,
a.text-flickr:focus {
  color: #b3005c !important;
}

.text-reddit {
  color: #ff4500 !important;
}

a.text-reddit:hover,
a.text-reddit:focus {
  color: #b33000 !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

a.text-dribbble:hover,
a.text-dribbble:focus {
  color: #d11960 !important;
}

.text-skype {
  color: #00aff0 !important;
}

a.text-skype:hover,
a.text-skype:focus {
  color: #0077a4 !important;
}

.text-instagram {
  color: #517fa4 !important;
}

a.text-instagram:hover,
a.text-instagram:focus {
  color: #385771 !important;
}

.text-behance {
  color: #1769ff !important;
}

a.text-behance:hover,
a.text-behance:focus {
  color: #0047ca !important;
}

.text-rss {
  color: #f26522 !important;
}

a.text-rss:hover,
a.text-rss:focus {
  color: #bc440b !important;
}

.text-black {
  color: #000 !important;
}

.m-25 {
  margin: 25% !important;
}

.mt-25,
.my-25 {
  margin-top: 25% !important;
}

.mr-25,
.mx-25 {
  margin-right: 25% !important;
}

.mb-25,
.my-25 {
  margin-bottom: 25% !important;
}

.ml-25,
.mx-25 {
  margin-left: 25% !important;
}

.m-50 {
  margin: 50% !important;
}

.mt-50,
.my-50 {
  margin-top: 50% !important;
}

.mr-50,
.mx-50 {
  margin-right: 50% !important;
}

.mb-50,
.my-50 {
  margin-bottom: 50% !important;
}

.ml-50,
.mx-50 {
  margin-left: 50% !important;
}

.m-75 {
  margin: 75% !important;
}

.mt-75,
.my-75 {
  margin-top: 75% !important;
}

.mr-75,
.mx-75 {
  margin-right: 75% !important;
}

.mb-75,
.my-75 {
  margin-bottom: 75% !important;
}

.ml-75,
.mx-75 {
  margin-left: 75% !important;
}

.m-100 {
  margin: 100% !important;
}

.mt-100,
.my-100 {
  margin-top: 100% !important;
}

.mr-100,
.mx-100 {
  margin-right: 100% !important;
}

.mb-100,
.my-100 {
  margin-bottom: 100% !important;
}

.ml-100,
.mx-100 {
  margin-left: 100% !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.m-20 {
  margin: 20% !important;
}

.mt-20,
.my-20 {
  margin-top: 20% !important;
}

.mr-20,
.mx-20 {
  margin-right: 20% !important;
}

.mb-20,
.my-20 {
  margin-bottom: 20% !important;
}

.ml-20,
.mx-20 {
  margin-left: 20% !important;
}

.p-25 {
  padding: 25% !important;
}

.pt-25,
.py-25 {
  padding-top: 25% !important;
}

.pr-25,
.px-25 {
  padding-right: 25% !important;
}

.pb-25,
.py-25 {
  padding-bottom: 25% !important;
}

.pl-25,
.px-25 {
  padding-left: 25% !important;
}

.p-50 {
  padding: 50% !important;
}

.pt-50,
.py-50 {
  padding-top: 50% !important;
}

.pr-50,
.px-50 {
  padding-right: 50% !important;
}

.pb-50,
.py-50 {
  padding-bottom: 50% !important;
}

.pl-50,
.px-50 {
  padding-left: 50% !important;
}

.p-75 {
  padding: 75% !important;
}

.pt-75,
.py-75 {
  padding-top: 75% !important;
}

.pr-75,
.px-75 {
  padding-right: 75% !important;
}

.pb-75,
.py-75 {
  padding-bottom: 75% !important;
}

.pl-75,
.px-75 {
  padding-left: 75% !important;
}

.p-100 {
  padding: 100% !important;
}

.pt-100,
.py-100 {
  padding-top: 100% !important;
}

.pr-100,
.px-100 {
  padding-right: 100% !important;
}

.pb-100,
.py-100 {
  padding-bottom: 100% !important;
}

.pl-100,
.px-100 {
  padding-left: 100% !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto,
.py-auto {
  padding-top: auto !important;
}

.pr-auto,
.px-auto {
  padding-right: auto !important;
}

.pb-auto,
.py-auto {
  padding-bottom: auto !important;
}

.pl-auto,
.px-auto {
  padding-left: auto !important;
}

.p-20 {
  padding: 20% !important;
}

.pt-20,
.py-20 {
  padding-top: 20% !important;
}

.pr-20,
.px-20 {
  padding-right: 20% !important;
}

.pb-20,
.py-20 {
  padding-bottom: 20% !important;
}

.pl-20,
.px-20 {
  padding-left: 20% !important;
}

@media (min-width: 576px) {
  .m-sm-25 {
    margin: 25% !important;
  }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 25% !important;
  }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 25% !important;
  }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 25% !important;
  }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 25% !important;
  }
  .m-sm-50 {
    margin: 50% !important;
  }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 50% !important;
  }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 50% !important;
  }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 50% !important;
  }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 50% !important;
  }
  .m-sm-75 {
    margin: 75% !important;
  }
  .mt-sm-75,
  .my-sm-75 {
    margin-top: 75% !important;
  }
  .mr-sm-75,
  .mx-sm-75 {
    margin-right: 75% !important;
  }
  .mb-sm-75,
  .my-sm-75 {
    margin-bottom: 75% !important;
  }
  .ml-sm-75,
  .mx-sm-75 {
    margin-left: 75% !important;
  }
  .m-sm-100 {
    margin: 100% !important;
  }
  .mt-sm-100,
  .my-sm-100 {
    margin-top: 100% !important;
  }
  .mr-sm-100,
  .mx-sm-100 {
    margin-right: 100% !important;
  }
  .mb-sm-100,
  .my-sm-100 {
    margin-bottom: 100% !important;
  }
  .ml-sm-100,
  .mx-sm-100 {
    margin-left: 100% !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-20 {
    margin: 20% !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 20% !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 20% !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20% !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 20% !important;
  }
  .p-sm-25 {
    padding: 25% !important;
  }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 25% !important;
  }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 25% !important;
  }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 25% !important;
  }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 25% !important;
  }
  .p-sm-50 {
    padding: 50% !important;
  }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 50% !important;
  }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 50% !important;
  }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 50% !important;
  }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 50% !important;
  }
  .p-sm-75 {
    padding: 75% !important;
  }
  .pt-sm-75,
  .py-sm-75 {
    padding-top: 75% !important;
  }
  .pr-sm-75,
  .px-sm-75 {
    padding-right: 75% !important;
  }
  .pb-sm-75,
  .py-sm-75 {
    padding-bottom: 75% !important;
  }
  .pl-sm-75,
  .px-sm-75 {
    padding-left: 75% !important;
  }
  .p-sm-100 {
    padding: 100% !important;
  }
  .pt-sm-100,
  .py-sm-100 {
    padding-top: 100% !important;
  }
  .pr-sm-100,
  .px-sm-100 {
    padding-right: 100% !important;
  }
  .pb-sm-100,
  .py-sm-100 {
    padding-bottom: 100% !important;
  }
  .pl-sm-100,
  .px-sm-100 {
    padding-left: 100% !important;
  }
  .p-sm-auto {
    padding: auto !important;
  }
  .pt-sm-auto,
  .py-sm-auto {
    padding-top: auto !important;
  }
  .pr-sm-auto,
  .px-sm-auto {
    padding-right: auto !important;
  }
  .pb-sm-auto,
  .py-sm-auto {
    padding-bottom: auto !important;
  }
  .pl-sm-auto,
  .px-sm-auto {
    padding-left: auto !important;
  }
  .p-sm-20 {
    padding: 20% !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 20% !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 20% !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 20% !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 20% !important;
  }
}

@media (min-width: 768px) {
  .m-md-25 {
    margin: 25% !important;
  }
  .mt-md-25,
  .my-md-25 {
    margin-top: 25% !important;
  }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 25% !important;
  }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 25% !important;
  }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 25% !important;
  }
  .m-md-50 {
    margin: 50% !important;
  }
  .mt-md-50,
  .my-md-50 {
    margin-top: 50% !important;
  }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 50% !important;
  }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 50% !important;
  }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 50% !important;
  }
  .m-md-75 {
    margin: 75% !important;
  }
  .mt-md-75,
  .my-md-75 {
    margin-top: 75% !important;
  }
  .mr-md-75,
  .mx-md-75 {
    margin-right: 75% !important;
  }
  .mb-md-75,
  .my-md-75 {
    margin-bottom: 75% !important;
  }
  .ml-md-75,
  .mx-md-75 {
    margin-left: 75% !important;
  }
  .m-md-100 {
    margin: 100% !important;
  }
  .mt-md-100,
  .my-md-100 {
    margin-top: 100% !important;
  }
  .mr-md-100,
  .mx-md-100 {
    margin-right: 100% !important;
  }
  .mb-md-100,
  .my-md-100 {
    margin-bottom: 100% !important;
  }
  .ml-md-100,
  .mx-md-100 {
    margin-left: 100% !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
  .m-md-20 {
    margin: 20% !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 20% !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 20% !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20% !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 20% !important;
  }
  .p-md-25 {
    padding: 25% !important;
  }
  .pt-md-25,
  .py-md-25 {
    padding-top: 25% !important;
  }
  .pr-md-25,
  .px-md-25 {
    padding-right: 25% !important;
  }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 25% !important;
  }
  .pl-md-25,
  .px-md-25 {
    padding-left: 25% !important;
  }
  .p-md-50 {
    padding: 50% !important;
  }
  .pt-md-50,
  .py-md-50 {
    padding-top: 50% !important;
  }
  .pr-md-50,
  .px-md-50 {
    padding-right: 50% !important;
  }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 50% !important;
  }
  .pl-md-50,
  .px-md-50 {
    padding-left: 50% !important;
  }
  .p-md-75 {
    padding: 75% !important;
  }
  .pt-md-75,
  .py-md-75 {
    padding-top: 75% !important;
  }
  .pr-md-75,
  .px-md-75 {
    padding-right: 75% !important;
  }
  .pb-md-75,
  .py-md-75 {
    padding-bottom: 75% !important;
  }
  .pl-md-75,
  .px-md-75 {
    padding-left: 75% !important;
  }
  .p-md-100 {
    padding: 100% !important;
  }
  .pt-md-100,
  .py-md-100 {
    padding-top: 100% !important;
  }
  .pr-md-100,
  .px-md-100 {
    padding-right: 100% !important;
  }
  .pb-md-100,
  .py-md-100 {
    padding-bottom: 100% !important;
  }
  .pl-md-100,
  .px-md-100 {
    padding-left: 100% !important;
  }
  .p-md-auto {
    padding: auto !important;
  }
  .pt-md-auto,
  .py-md-auto {
    padding-top: auto !important;
  }
  .pr-md-auto,
  .px-md-auto {
    padding-right: auto !important;
  }
  .pb-md-auto,
  .py-md-auto {
    padding-bottom: auto !important;
  }
  .pl-md-auto,
  .px-md-auto {
    padding-left: auto !important;
  }
  .p-md-20 {
    padding: 20% !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 20% !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 20% !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20% !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 20% !important;
  }
}

@media (min-width: 1200px) {
  .m-lg-25 {
    margin: 25% !important;
  }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 25% !important;
  }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 25% !important;
  }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 25% !important;
  }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 25% !important;
  }
  .m-lg-50 {
    margin: 50% !important;
  }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 50% !important;
  }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 50% !important;
  }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 50% !important;
  }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 50% !important;
  }
  .m-lg-75 {
    margin: 75% !important;
  }
  .mt-lg-75,
  .my-lg-75 {
    margin-top: 75% !important;
  }
  .mr-lg-75,
  .mx-lg-75 {
    margin-right: 75% !important;
  }
  .mb-lg-75,
  .my-lg-75 {
    margin-bottom: 75% !important;
  }
  .ml-lg-75,
  .mx-lg-75 {
    margin-left: 75% !important;
  }
  .m-lg-100 {
    margin: 100% !important;
  }
  .mt-lg-100,
  .my-lg-100 {
    margin-top: 100% !important;
  }
  .mr-lg-100,
  .mx-lg-100 {
    margin-right: 100% !important;
  }
  .mb-lg-100,
  .my-lg-100 {
    margin-bottom: 100% !important;
  }
  .ml-lg-100,
  .mx-lg-100 {
    margin-left: 100% !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-20 {
    margin: 20% !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 20% !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 20% !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20% !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 20% !important;
  }
  .p-lg-25 {
    padding: 25% !important;
  }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 25% !important;
  }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 25% !important;
  }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 25% !important;
  }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 25% !important;
  }
  .p-lg-50 {
    padding: 50% !important;
  }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 50% !important;
  }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 50% !important;
  }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 50% !important;
  }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 50% !important;
  }
  .p-lg-75 {
    padding: 75% !important;
  }
  .pt-lg-75,
  .py-lg-75 {
    padding-top: 75% !important;
  }
  .pr-lg-75,
  .px-lg-75 {
    padding-right: 75% !important;
  }
  .pb-lg-75,
  .py-lg-75 {
    padding-bottom: 75% !important;
  }
  .pl-lg-75,
  .px-lg-75 {
    padding-left: 75% !important;
  }
  .p-lg-100 {
    padding: 100% !important;
  }
  .pt-lg-100,
  .py-lg-100 {
    padding-top: 100% !important;
  }
  .pr-lg-100,
  .px-lg-100 {
    padding-right: 100% !important;
  }
  .pb-lg-100,
  .py-lg-100 {
    padding-bottom: 100% !important;
  }
  .pl-lg-100,
  .px-lg-100 {
    padding-left: 100% !important;
  }
  .p-lg-auto {
    padding: auto !important;
  }
  .pt-lg-auto,
  .py-lg-auto {
    padding-top: auto !important;
  }
  .pr-lg-auto,
  .px-lg-auto {
    padding-right: auto !important;
  }
  .pb-lg-auto,
  .py-lg-auto {
    padding-bottom: auto !important;
  }
  .pl-lg-auto,
  .px-lg-auto {
    padding-left: auto !important;
  }
  .p-lg-20 {
    padding: 20% !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 20% !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 20% !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20% !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 20% !important;
  }
}

@media (min-width: 1600px) {
  .m-xl-25 {
    margin: 25% !important;
  }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 25% !important;
  }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 25% !important;
  }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 25% !important;
  }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 25% !important;
  }
  .m-xl-50 {
    margin: 50% !important;
  }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 50% !important;
  }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 50% !important;
  }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 50% !important;
  }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 50% !important;
  }
  .m-xl-75 {
    margin: 75% !important;
  }
  .mt-xl-75,
  .my-xl-75 {
    margin-top: 75% !important;
  }
  .mr-xl-75,
  .mx-xl-75 {
    margin-right: 75% !important;
  }
  .mb-xl-75,
  .my-xl-75 {
    margin-bottom: 75% !important;
  }
  .ml-xl-75,
  .mx-xl-75 {
    margin-left: 75% !important;
  }
  .m-xl-100 {
    margin: 100% !important;
  }
  .mt-xl-100,
  .my-xl-100 {
    margin-top: 100% !important;
  }
  .mr-xl-100,
  .mx-xl-100 {
    margin-right: 100% !important;
  }
  .mb-xl-100,
  .my-xl-100 {
    margin-bottom: 100% !important;
  }
  .ml-xl-100,
  .mx-xl-100 {
    margin-left: 100% !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-20 {
    margin: 20% !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 20% !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 20% !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 20% !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 20% !important;
  }
  .p-xl-25 {
    padding: 25% !important;
  }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 25% !important;
  }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 25% !important;
  }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 25% !important;
  }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 25% !important;
  }
  .p-xl-50 {
    padding: 50% !important;
  }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 50% !important;
  }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 50% !important;
  }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 50% !important;
  }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 50% !important;
  }
  .p-xl-75 {
    padding: 75% !important;
  }
  .pt-xl-75,
  .py-xl-75 {
    padding-top: 75% !important;
  }
  .pr-xl-75,
  .px-xl-75 {
    padding-right: 75% !important;
  }
  .pb-xl-75,
  .py-xl-75 {
    padding-bottom: 75% !important;
  }
  .pl-xl-75,
  .px-xl-75 {
    padding-left: 75% !important;
  }
  .p-xl-100 {
    padding: 100% !important;
  }
  .pt-xl-100,
  .py-xl-100 {
    padding-top: 100% !important;
  }
  .pr-xl-100,
  .px-xl-100 {
    padding-right: 100% !important;
  }
  .pb-xl-100,
  .py-xl-100 {
    padding-bottom: 100% !important;
  }
  .pl-xl-100,
  .px-xl-100 {
    padding-left: 100% !important;
  }
  .p-xl-auto {
    padding: auto !important;
  }
  .pt-xl-auto,
  .py-xl-auto {
    padding-top: auto !important;
  }
  .pr-xl-auto,
  .px-xl-auto {
    padding-right: auto !important;
  }
  .pb-xl-auto,
  .py-xl-auto {
    padding-bottom: auto !important;
  }
  .pl-xl-auto,
  .px-xl-auto {
    padding-left: auto !important;
  }
  .p-xl-20 {
    padding: 20% !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 20% !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 20% !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 20% !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 20% !important;
  }
}

.vh-10 {
  height: 10vh;
  min-height: 10vh;
}

.vh-20 {
  height: 20vh;
  min-height: 20vh;
}

.vh-25 {
  height: 25vh;
  min-height: 25vh;
}

.vh-30 {
  height: 30vh;
  min-height: 30vh;
}

.vh-40 {
  height: 40vh;
  min-height: 40vh;
}

.vh-50 {
  height: 50vh;
  min-height: 50vh;
}

.vh-60 {
  height: 60vh;
  min-height: 60vh;
}

.vh-70 {
  height: 70vh;
  min-height: 70vh;
}

.vh-75 {
  height: 75vh;
  min-height: 75vh;
}

.vh-80 {
  height: 80vh;
  min-height: 80vh;
}

.vh-90 {
  height: 90vh;
  min-height: 90vh;
}

.vh-100 {
  height: 100vh;
  min-height: 100vh;
}

.vh-max-10 {
  max-height: 10vh;
}

.vh-max-20 {
  max-height: 20vh;
}

.vh-max-25 {
  max-height: 25vh;
}

.vh-max-30 {
  max-height: 30vh;
}

.vh-max-40 {
  max-height: 40vh;
}

.vh-max-50 {
  max-height: 50vh;
}

.vh-max-60 {
  max-height: 60vh;
}

.vh-max-70 {
  max-height: 70vh;
}

.vh-max-75 {
  max-height: 75vh;
}

.vh-max-80 {
  max-height: 80vh;
}

.vh-max-90 {
  max-height: 90vh;
}

.vh-max-100 {
  max-height: 100vh;
}

@media (max-width: 767.98px) {
  [class*="vh-"] {
    height: auto;
  }
}

.gutter-1 {
  margin: -5px;
}
.gutter-1 > * {
  margin-bottom: 0 !important;
  padding: 5px;
}

.gutter-2 {
  margin: -10px;
}
.gutter-2 > * {
  margin-bottom: 0 !important;
  padding: 10px;
}

.gutter-3 {
  margin: -15px;
}
.gutter-3 > * {
  margin-bottom: 0 !important;
  padding: 15px;
}

.gutter-4 {
  margin: -20px;
}
.gutter-4 > * {
  margin-bottom: 0 !important;
  padding: 20px;
}

.gutter-5 {
  margin: -25px;
}
.gutter-5 > * {
  margin-bottom: 0 !important;
  padding: 25px;
}

@media (min-width: 576px) {
  .gutter-sm-1 {
    margin: -5px;
  }
  .gutter-sm-1 > * {
    margin-bottom: 0 !important;
    padding: 5px;
  }
  .gutter-sm-2 {
    margin: -10px;
  }
  .gutter-sm-2 > * {
    margin-bottom: 0 !important;
    padding: 10px;
  }
  .gutter-sm-3 {
    margin: -15px;
  }
  .gutter-sm-3 > * {
    margin-bottom: 0 !important;
    padding: 15px;
  }
  .gutter-sm-4 {
    margin: -20px;
  }
  .gutter-sm-4 > * {
    margin-bottom: 0 !important;
    padding: 20px;
  }
  .gutter-sm-5 {
    margin: -25px;
  }
  .gutter-sm-5 > * {
    margin-bottom: 0 !important;
    padding: 25px;
  }
}

@media (min-width: 768px) {
  .gutter-md-1 {
    margin: -5px;
  }
  .gutter-md-1 > * {
    margin-bottom: 0 !important;
    padding: 5px;
  }
  .gutter-md-2 {
    margin: -10px;
  }
  .gutter-md-2 > * {
    margin-bottom: 0 !important;
    padding: 10px;
  }
  .gutter-md-3 {
    margin: -15px;
  }
  .gutter-md-3 > * {
    margin-bottom: 0 !important;
    padding: 15px;
  }
  .gutter-md-4 {
    margin: -20px;
  }
  .gutter-md-4 > * {
    margin-bottom: 0 !important;
    padding: 20px;
  }
  .gutter-md-5 {
    margin: -25px;
  }
  .gutter-md-5 > * {
    margin-bottom: 0 !important;
    padding: 25px;
  }
}

@media (min-width: 1200px) {
  .gutter-lg-1 {
    margin: -5px;
  }
  .gutter-lg-1 > * {
    margin-bottom: 0 !important;
    padding: 5px;
  }
  .gutter-lg-2 {
    margin: -10px;
  }
  .gutter-lg-2 > * {
    margin-bottom: 0 !important;
    padding: 10px;
  }
  .gutter-lg-3 {
    margin: -15px;
  }
  .gutter-lg-3 > * {
    margin-bottom: 0 !important;
    padding: 15px;
  }
  .gutter-lg-4 {
    margin: -20px;
  }
  .gutter-lg-4 > * {
    margin-bottom: 0 !important;
    padding: 20px;
  }
  .gutter-lg-5 {
    margin: -25px;
  }
  .gutter-lg-5 > * {
    margin-bottom: 0 !important;
    padding: 25px;
  }
}

@media (min-width: 1600px) {
  .gutter-xl-1 {
    margin: -5px;
  }
  .gutter-xl-1 > * {
    margin-bottom: 0 !important;
    padding: 5px;
  }
  .gutter-xl-2 {
    margin: -10px;
  }
  .gutter-xl-2 > * {
    margin-bottom: 0 !important;
    padding: 10px;
  }
  .gutter-xl-3 {
    margin: -15px;
  }
  .gutter-xl-3 > * {
    margin-bottom: 0 !important;
    padding: 15px;
  }
  .gutter-xl-4 {
    margin: -20px;
  }
  .gutter-xl-4 > * {
    margin-bottom: 0 !important;
    padding: 20px;
  }
  .gutter-xl-5 {
    margin: -25px;
  }
  .gutter-xl-5 > * {
    margin-bottom: 0 !important;
    padding: 25px;
  }
}

.gutter-0 {
  padding: 1px 1px 0 0;
  margin-left: 0;
  margin-right: 0;
}
.gutter-0 > * {
  padding: 0;
  margin: -1px -1px 0 0 !important;
}

.gutter-1 [class*="-double"]::before {
  padding-top: calc(200% + 10px);
}

.gutter-2 [class*="-double"]::before {
  padding-top: calc(200% + 20px);
}

.gutter-3 [class*="-double"]::before {
  padding-top: calc(200% + 30px);
}

.fw-25 {
  flex: 0 0 25% !important;
  width: 25% !important;
}

.fw-50 {
  flex: 0 0 50% !important;
  width: 50% !important;
}

.fw-75 {
  flex: 0 0 75% !important;
  width: 75% !important;
}

.fw-100 {
  flex: 0 0 100% !important;
  width: 100% !important;
}

.fw-auto {
  flex: 0 0 auto !important;
  width: auto !important;
}

.fw-20 {
  flex: 0 0 20% !important;
  width: 20% !important;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?q2xewv");
  src: url("../fonts/icomoon.eot?q2xewv#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?q2xewv") format("truetype"),
    url("../fonts/icomoon.woff?q2xewv") format("woff"),
    url("../fonts/icomoon.svg?q2xewv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: "\ebcc";
}

.icon-airplay:before {
  content: "\ebcd";
}

.icon-alert-circle:before {
  content: "\ebce";
}

.icon-alert-octagon:before {
  content: "\ebcf";
}

.icon-alert-triangle:before {
  content: "\ebd0";
}

.icon-align-center2:before {
  content: "\ebd1";
}

.icon-align-justify2:before {
  content: "\ebd2";
}

.icon-align-left2:before {
  content: "\ebd3";
}

.icon-align-right2:before {
  content: "\ebd4";
}

.icon-anchor2:before {
  content: "\ebd5";
}

.icon-aperture:before {
  content: "\ebd6";
}

.icon-archive2:before {
  content: "\ebd7";
}

.icon-arrow-down2:before {
  content: "\ebd8";
}

.icon-arrow-down-circle:before {
  content: "\ebd9";
}

.icon-arrow-down-left:before {
  content: "\ebda";
}

.icon-arrow-down-right:before {
  content: "\ebdb";
}

.icon-arrow-left2:before {
  content: "\ebdc";
}

.icon-arrow-left-circle:before {
  content: "\ebdd";
}

.icon-arrow-right2:before {
  content: "\ebde";
}

.icon-arrow-right-circle:before {
  content: "\ebdf";
}

.icon-arrow-up2:before {
  content: "\ebe0";
}

.icon-arrow-up-circle:before {
  content: "\ebe1";
}

.icon-arrow-up-left:before {
  content: "\ebe2";
}

.icon-arrow-up-right:before {
  content: "\ebe3";
}

.icon-at-sign:before {
  content: "\ebe4";
}

.icon-award:before {
  content: "\ebe5";
}

.icon-bar-chart2:before {
  content: "\ebe6";
}

.icon-bar-chart-2:before {
  content: "\ebe7";
}

.icon-battery2:before {
  content: "\ebe8";
}

.icon-battery-charging:before {
  content: "\ebe9";
}

.icon-bell2:before {
  content: "\ebea";
}

.icon-bell-off:before {
  content: "\ebeb";
}

.icon-bluetooth2:before {
  content: "\ebec";
}

.icon-bold2:before {
  content: "\ebed";
}

.icon-book2:before {
  content: "\ebee";
}

.icon-book-open:before {
  content: "\ebef";
}

.icon-bookmark2:before {
  content: "\ebf0";
}

.icon-box:before {
  content: "\ebf1";
}

.icon-briefcase2:before {
  content: "\ebf2";
}

.icon-calendar2:before {
  content: "\ebf3";
}

.icon-camera2:before {
  content: "\ebf4";
}

.icon-camera-off:before {
  content: "\ebf5";
}

.icon-cast:before {
  content: "\ebf6";
}

.icon-check2:before {
  content: "\ebf7";
}

.icon-check-circle2:before {
  content: "\ebf8";
}

.icon-check-square2:before {
  content: "\ebf9";
}

.icon-chevron-down2:before {
  content: "\ebfa";
}

.icon-chevron-left2:before {
  content: "\ebfb";
}

.icon-chevron-right2:before {
  content: "\ebfc";
}

.icon-chevron-up2:before {
  content: "\ebfd";
}

.icon-chevrons-down:before {
  content: "\ebfe";
}

.icon-chevrons-left:before {
  content: "\ebff";
}

.icon-chevrons-right:before {
  content: "\ec00";
}

.icon-chevrons-up:before {
  content: "\ec01";
}

.icon-chrome2:before {
  content: "\ec02";
}

.icon-circle2:before {
  content: "\ec03";
}

.icon-clipboard2:before {
  content: "\ec04";
}

.icon-clock:before {
  content: "\ec05";
}

.icon-cloud2:before {
  content: "\ec06";
}

.icon-cloud-drizzle:before {
  content: "\ec07";
}

.icon-cloud-lightning:before {
  content: "\ec08";
}

.icon-cloud-off:before {
  content: "\ec09";
}

.icon-cloud-rain:before {
  content: "\ec0a";
}

.icon-cloud-snow:before {
  content: "\ec0b";
}

.icon-code2:before {
  content: "\ec0c";
}

.icon-codepen2:before {
  content: "\ec0d";
}

.icon-command:before {
  content: "\ec0e";
}

.icon-compass2:before {
  content: "\ec0f";
}

.icon-copy2:before {
  content: "\ec10";
}

.icon-corner-down-left:before {
  content: "\ec11";
}

.icon-corner-down-right:before {
  content: "\ec12";
}

.icon-corner-left-down:before {
  content: "\ec13";
}

.icon-corner-left-up:before {
  content: "\ec14";
}

.icon-corner-right-down:before {
  content: "\ec15";
}

.icon-corner-right-up:before {
  content: "\ec16";
}

.icon-corner-up-left:before {
  content: "\ec17";
}

.icon-corner-up-right:before {
  content: "\ec18";
}

.icon-cpu:before {
  content: "\ec19";
}

.icon-credit-card2:before {
  content: "\ec1a";
}

.icon-crop2:before {
  content: "\ec1b";
}

.icon-crosshair:before {
  content: "\ec1c";
}

.icon-database2:before {
  content: "\ec1d";
}

.icon-delete:before {
  content: "\ec1e";
}

.icon-disc:before {
  content: "\ec1f";
}

.icon-dollar-sign:before {
  content: "\ec20";
}

.icon-download2:before {
  content: "\ec21";
}

.icon-download-cloud:before {
  content: "\ec22";
}

.icon-droplet:before {
  content: "\ec23";
}

.icon-edit2:before {
  content: "\ec24";
}

.icon-edit-2:before {
  content: "\ec25";
}

.icon-edit-3:before {
  content: "\ec26";
}

.icon-external-link2:before {
  content: "\ec27";
}

.icon-eye2:before {
  content: "\ec28";
}

.icon-eye-off:before {
  content: "\ec29";
}

.icon-facebook2:before {
  content: "\ec2a";
}

.icon-fast-forward2:before {
  content: "\ec2b";
}

.icon-feather:before {
  content: "\ec2c";
}

.icon-file2:before {
  content: "\ec2d";
}

.icon-file-minus:before {
  content: "\ec2e";
}

.icon-file-plus:before {
  content: "\ec2f";
}

.icon-file-text2:before {
  content: "\ec30";
}

.icon-film2:before {
  content: "\ec31";
}

.icon-filter2:before {
  content: "\ec32";
}

.icon-flag2:before {
  content: "\ec33";
}

.icon-folder2:before {
  content: "\ec34";
}

.icon-folder-minus:before {
  content: "\ec35";
}

.icon-folder-plus:before {
  content: "\ec36";
}

.icon-gift2:before {
  content: "\ec37";
}

.icon-git-branch:before {
  content: "\ec38";
}

.icon-git-commit:before {
  content: "\ec39";
}

.icon-git-merge:before {
  content: "\ec3a";
}

.icon-git-pull-request:before {
  content: "\ec3b";
}

.icon-github2:before {
  content: "\ec3c";
}

.icon-gitlab2:before {
  content: "\ec3d";
}

.icon-globe2:before {
  content: "\ec3e";
}

.icon-grid:before {
  content: "\ec3f";
}

.icon-hard-drive:before {
  content: "\ec40";
}

.icon-hash:before {
  content: "\ec41";
}

.icon-headphones2:before {
  content: "\ec42";
}

.icon-heart2:before {
  content: "\ec43";
}

.icon-help-circle:before {
  content: "\ec44";
}

.icon-home2:before {
  content: "\ec45";
}

.icon-image2:before {
  content: "\ec46";
}

.icon-inbox2:before {
  content: "\ec47";
}

.icon-info2:before {
  content: "\ec48";
}

.icon-instagram2:before {
  content: "\ec49";
}

.icon-italic2:before {
  content: "\ec4a";
}

.icon-layers:before {
  content: "\ec4b";
}

.icon-layout:before {
  content: "\ec4c";
}

.icon-life-buoy2:before {
  content: "\ec4d";
}

.icon-link2:before {
  content: "\ec4e";
}

.icon-link-2:before {
  content: "\ec4f";
}

.icon-linkedin2:before {
  content: "\ec50";
}

.icon-list2:before {
  content: "\ec51";
}

.icon-loader:before {
  content: "\ec52";
}

.icon-lock2:before {
  content: "\ec53";
}

.icon-log-in:before {
  content: "\ec54";
}

.icon-log-out:before {
  content: "\ec55";
}

.icon-mail:before {
  content: "\ec56";
}

.icon-map2:before {
  content: "\ec57";
}

.icon-map-pin2:before {
  content: "\ec58";
}

.icon-maximize:before {
  content: "\ec59";
}

.icon-maximize-2:before {
  content: "\ec5a";
}

.icon-menu:before {
  content: "\ec5b";
}

.icon-message-circle:before {
  content: "\ec5c";
}

.icon-message-square:before {
  content: "\ec5d";
}

.icon-mic:before {
  content: "\ec5e";
}

.icon-mic-off:before {
  content: "\ec5f";
}

.icon-minimize:before {
  content: "\ec60";
}

.icon-minimize-2:before {
  content: "\ec61";
}

.icon-minus2:before {
  content: "\ec62";
}

.icon-minus-circle2:before {
  content: "\ec63";
}

.icon-minus-square2:before {
  content: "\ec64";
}

.icon-monitor:before {
  content: "\ec65";
}

.icon-moon:before {
  content: "\ec66";
}

.icon-more-horizontal:before {
  content: "\ec67";
}

.icon-more-vertical:before {
  content: "\ec68";
}

.icon-move:before {
  content: "\ec69";
}

.icon-music2:before {
  content: "\ec6a";
}

.icon-navigation:before {
  content: "\ec6b";
}

.icon-navigation-2:before {
  content: "\ec6c";
}

.icon-octagon:before {
  content: "\ec6d";
}

.icon-package:before {
  content: "\ec6e";
}

.icon-paperclip2:before {
  content: "\ec6f";
}

.icon-pause2:before {
  content: "\ec70";
}

.icon-pause-circle2:before {
  content: "\ec71";
}

.icon-percent2:before {
  content: "\ec72";
}

.icon-phone2:before {
  content: "\ec73";
}

.icon-phone-call:before {
  content: "\ec74";
}

.icon-phone-forwarded:before {
  content: "\ec75";
}

.icon-phone-incoming:before {
  content: "\ec76";
}

.icon-phone-missed:before {
  content: "\ec77";
}

.icon-phone-off:before {
  content: "\ec78";
}

.icon-phone-outgoing:before {
  content: "\ec79";
}

.icon-pie-chart2:before {
  content: "\ec7a";
}

.icon-play2:before {
  content: "\ec7b";
}

.icon-play-circle2:before {
  content: "\ec7c";
}

.icon-plus2:before {
  content: "\ec7d";
}

.icon-plus-circle2:before {
  content: "\ec7e";
}

.icon-plus-square2:before {
  content: "\ec7f";
}

.icon-pocket:before {
  content: "\ec80";
}

.icon-power:before {
  content: "\ec81";
}

.icon-printer:before {
  content: "\ec82";
}

.icon-radio:before {
  content: "\ec83";
}

.icon-refresh-ccw:before {
  content: "\ec84";
}

.icon-refresh-cw:before {
  content: "\ec85";
}

.icon-repeat2:before {
  content: "\ec86";
}

.icon-rewind:before {
  content: "\ec87";
}

.icon-rotate-ccw:before {
  content: "\ec88";
}

.icon-rotate-cw:before {
  content: "\ec89";
}

.icon-rss2:before {
  content: "\ec8a";
}

.icon-save2:before {
  content: "\ec8b";
}

.icon-scissors2:before {
  content: "\ec8c";
}

.icon-search2:before {
  content: "\ec8d";
}

.icon-send2:before {
  content: "\ec8e";
}

.icon-server2:before {
  content: "\ec8f";
}

.icon-settings:before {
  content: "\ec90";
}

.icon-share2:before {
  content: "\ec91";
}

.icon-share-2:before {
  content: "\ec92";
}

.icon-shield2:before {
  content: "\ec93";
}

.icon-shield-off:before {
  content: "\ec94";
}

.icon-shopping-bag2:before {
  content: "\ec95";
}

.icon-shopping-cart2:before {
  content: "\ec96";
}

.icon-shuffle:before {
  content: "\ec97";
}

.icon-sidebar:before {
  content: "\ec98";
}

.icon-skip-back:before {
  content: "\ec99";
}

.icon-skip-forward:before {
  content: "\ec9a";
}

.icon-slack2:before {
  content: "\ec9b";
}

.icon-slash:before {
  content: "\ec9c";
}

.icon-sliders2:before {
  content: "\ec9d";
}

.icon-smartphone:before {
  content: "\ec9e";
}

.icon-speaker:before {
  content: "\ec9f";
}

.icon-square2:before {
  content: "\eca0";
}

.icon-star2:before {
  content: "\eca1";
}

.icon-stop-circle2:before {
  content: "\eca2";
}

.icon-sun:before {
  content: "\eca3";
}

.icon-sunrise:before {
  content: "\eca4";
}

.icon-sunset:before {
  content: "\eca5";
}

.icon-tablet2:before {
  content: "\eca6";
}

.icon-tag2:before {
  content: "\eca7";
}

.icon-target:before {
  content: "\eca8";
}

.icon-terminal2:before {
  content: "\eca9";
}

.icon-thermometer2:before {
  content: "\ecaa";
}

.icon-thumbs-down2:before {
  content: "\ecab";
}

.icon-thumbs-up2:before {
  content: "\ecac";
}

.icon-toggle-left2:before {
  content: "\ecad";
}

.icon-toggle-right2:before {
  content: "\ecae";
}

.icon-trash2:before {
  content: "\ecaf";
}

.icon-trash-2:before {
  content: "\ecb0";
}

.icon-trending-down:before {
  content: "\ecb1";
}

.icon-trending-up:before {
  content: "\ecb2";
}

.icon-triangle:before {
  content: "\ecb3";
}

.icon-truck2:before {
  content: "\ecb4";
}

.icon-tv2:before {
  content: "\ecb5";
}

.icon-twitter2:before {
  content: "\ecb6";
}

.icon-type:before {
  content: "\ecb7";
}

.icon-umbrella2:before {
  content: "\ecb8";
}

.icon-underline2:before {
  content: "\ecb9";
}

.icon-unlock2:before {
  content: "\ecba";
}

.icon-upload2:before {
  content: "\ecbb";
}

.icon-upload-cloud:before {
  content: "\ecbc";
}

.icon-user2:before {
  content: "\ecbd";
}

.icon-user-check:before {
  content: "\ecbe";
}

.icon-user-minus:before {
  content: "\ecbf";
}

.icon-user-plus2:before {
  content: "\ecc0";
}

.icon-user-x:before {
  content: "\ecc1";
}

.icon-users2:before {
  content: "\ecc2";
}

.icon-video:before {
  content: "\ecc3";
}

.icon-video-off:before {
  content: "\ecc4";
}

.icon-voicemail:before {
  content: "\ecc5";
}

.icon-volume:before {
  content: "\ecc6";
}

.icon-volume-1:before {
  content: "\ecc7";
}

.icon-volume-2:before {
  content: "\ecc8";
}

.icon-volume-x:before {
  content: "\ecc9";
}

.icon-watch:before {
  content: "\ecca";
}

.icon-wifi2:before {
  content: "\eccb";
}

.icon-wifi-off:before {
  content: "\eccc";
}

.icon-wind:before {
  content: "\eccd";
}

.icon-x:before {
  content: "\ecce";
}

.icon-x-circle:before {
  content: "\eccf";
}

.icon-x-square:before {
  content: "\ecd0";
}

.icon-youtube2:before {
  content: "\ecd1";
}

.icon-zap:before {
  content: "\ecd2";
}

.icon-zap-off:before {
  content: "\ecd3";
}

.icon-zoom-in:before {
  content: "\ecd4";
}

.icon-zoom-out:before {
  content: "\ecd5";
}

.icon-asterisk:before {
  content: "\f069";
}

.icon-plus:before {
  content: "\f067";
}

.icon-question:before {
  content: "\f128";
}

.icon-minus:before {
  content: "\f068";
}

.icon-glass:before {
  content: "\f000";
}

.icon-music:before {
  content: "\f001";
}

.icon-search:before {
  content: "\f002";
}

.icon-envelope-o:before {
  content: "\f003";
}

.icon-heart:before {
  content: "\f004";
}

.icon-star:before {
  content: "\f005";
}

.icon-star-o:before {
  content: "\f006";
}

.icon-user:before {
  content: "\f007";
}

.icon-film:before {
  content: "\f008";
}

.icon-th-large:before {
  content: "\f009";
}

.icon-th:before {
  content: "\f00a";
}

.icon-th-list:before {
  content: "\f00b";
}

.icon-check:before {
  content: "\f00c";
}

.icon-close:before {
  content: "\f00d";
}

.icon-remove:before {
  content: "\f00d";
}

.icon-times:before {
  content: "\f00d";
}

.icon-search-plus:before {
  content: "\f00e";
}

.icon-search-minus:before {
  content: "\f010";
}

.icon-power-off:before {
  content: "\f011";
}

.icon-signal:before {
  content: "\f012";
}

.icon-cog:before {
  content: "\f013";
}

.icon-gear:before {
  content: "\f013";
}

.icon-trash-o:before {
  content: "\f014";
}

.icon-home:before {
  content: "\f015";
}

.icon-file-o:before {
  content: "\f016";
}

.icon-clock-o:before {
  content: "\f017";
}

.icon-road:before {
  content: "\f018";
}

.icon-download:before {
  content: "\f019";
}

.icon-arrow-circle-o-down:before {
  content: "\f01a";
}

.icon-arrow-circle-o-up:before {
  content: "\f01b";
}

.icon-inbox:before {
  content: "\f01c";
}

.icon-play-circle-o:before {
  content: "\f01d";
}

.icon-repeat:before {
  content: "\f01e";
}

.icon-rotate-right:before {
  content: "\f01e";
}

.icon-refresh:before {
  content: "\f021";
}

.icon-list-alt:before {
  content: "\f022";
}

.icon-lock:before {
  content: "\f023";
}

.icon-flag:before {
  content: "\f024";
}

.icon-headphones:before {
  content: "\f025";
}

.icon-volume-off:before {
  content: "\f026";
}

.icon-volume-down:before {
  content: "\f027";
}

.icon-volume-up:before {
  content: "\f028";
}

.icon-qrcode:before {
  content: "\f029";
}

.icon-barcode:before {
  content: "\f02a";
}

.icon-tag:before {
  content: "\f02b";
}

.icon-tags:before {
  content: "\f02c";
}

.icon-book:before {
  content: "\f02d";
}

.icon-bookmark:before {
  content: "\f02e";
}

.icon-print:before {
  content: "\f02f";
}

.icon-camera:before {
  content: "\f030";
}

.icon-font:before {
  content: "\f031";
}

.icon-bold:before {
  content: "\f032";
}

.icon-italic:before {
  content: "\f033";
}

.icon-text-height:before {
  content: "\f034";
}

.icon-text-width:before {
  content: "\f035";
}

.icon-align-left:before {
  content: "\f036";
}

.icon-align-center:before {
  content: "\f037";
}

.icon-align-right:before {
  content: "\f038";
}

.icon-align-justify:before {
  content: "\f039";
}

.icon-list:before {
  content: "\f03a";
}

.icon-dedent:before {
  content: "\f03b";
}

.icon-outdent:before {
  content: "\f03b";
}

.icon-indent:before {
  content: "\f03c";
}

.icon-video-camera:before {
  content: "\f03d";
}

.icon-image:before {
  content: "\f03e";
}

.icon-photo:before {
  content: "\f03e";
}

.icon-picture-o:before {
  content: "\f03e";
}

.icon-pencil:before {
  content: "\f040";
}

.icon-map-marker:before {
  content: "\f041";
}

.icon-adjust:before {
  content: "\f042";
}

.icon-tint:before {
  content: "\f043";
}

.icon-edit:before {
  content: "\f044";
}

.icon-pencil-square-o:before {
  content: "\f044";
}

.icon-share-square-o:before {
  content: "\f045";
}

.icon-check-square-o:before {
  content: "\f046";
}

.icon-arrows:before {
  content: "\f047";
}

.icon-step-backward:before {
  content: "\f048";
}

.icon-fast-backward:before {
  content: "\f049";
}

.icon-backward:before {
  content: "\f04a";
}

.icon-play:before {
  content: "\f04b";
}

.icon-pause:before {
  content: "\f04c";
}

.icon-stop:before {
  content: "\f04d";
}

.icon-forward:before {
  content: "\f04e";
}

.icon-fast-forward:before {
  content: "\f050";
}

.icon-step-forward:before {
  content: "\f051";
}

.icon-eject:before {
  content: "\f052";
}

.icon-chevron-left:before {
  content: "\f053";
}

.icon-chevron-right:before {
  content: "\f054";
}

.icon-plus-circle:before {
  content: "\f055";
}

.icon-minus-circle:before {
  content: "\f056";
}

.icon-times-circle:before {
  content: "\f057";
}

.icon-check-circle:before {
  content: "\f058";
}

.icon-question-circle:before {
  content: "\f059";
}

.icon-info-circle:before {
  content: "\f05a";
}

.icon-crosshairs:before {
  content: "\f05b";
}

.icon-times-circle-o:before {
  content: "\f05c";
}

.icon-check-circle-o:before {
  content: "\f05d";
}

.icon-ban:before {
  content: "\f05e";
}

.icon-arrow-left:before {
  content: "\f060";
}

.icon-arrow-right:before {
  content: "\f061";
}

.icon-arrow-up:before {
  content: "\f062";
}

.icon-arrow-down:before {
  content: "\f063";
}

.icon-mail-forward:before {
  content: "\f064";
}

.icon-share:before {
  content: "\f064";
}

.icon-expand:before {
  content: "\f065";
}

.icon-compress:before {
  content: "\f066";
}

.icon-exclamation-circle:before {
  content: "\f06a";
}

.icon-gift:before {
  content: "\f06b";
}

.icon-leaf:before {
  content: "\f06c";
}

.icon-fire:before {
  content: "\f06d";
}

.icon-eye:before {
  content: "\f06e";
}

.icon-eye-slash:before {
  content: "\f070";
}

.icon-exclamation-triangle:before {
  content: "\f071";
}

.icon-warning:before {
  content: "\f071";
}

.icon-plane:before {
  content: "\f072";
}

.icon-calendar:before {
  content: "\f073";
}

.icon-random:before {
  content: "\f074";
}

.icon-comment:before {
  content: "\f075";
}

.icon-magnet:before {
  content: "\f076";
}

.icon-chevron-up:before {
  content: "\f077";
}

.icon-chevron-down:before {
  content: "\f078";
}

.icon-retweet:before {
  content: "\f079";
}

.icon-shopping-cart:before {
  content: "\f07a";
}

.icon-folder:before {
  content: "\f07b";
}

.icon-folder-open:before {
  content: "\f07c";
}

.icon-arrows-v:before {
  content: "\f07d";
}

.icon-arrows-h:before {
  content: "\f07e";
}

.icon-bar-chart:before {
  content: "\f080";
}

.icon-bar-chart-o:before {
  content: "\f080";
}

.icon-twitter-square:before {
  content: "\f081";
}

.icon-facebook-square:before {
  content: "\f082";
}

.icon-camera-retro:before {
  content: "\f083";
}

.icon-key:before {
  content: "\f084";
}

.icon-cogs:before {
  content: "\f085";
}

.icon-gears:before {
  content: "\f085";
}

.icon-comments:before {
  content: "\f086";
}

.icon-thumbs-o-up:before {
  content: "\f087";
}

.icon-thumbs-o-down:before {
  content: "\f088";
}

.icon-star-half:before {
  content: "\f089";
}

.icon-heart-o:before {
  content: "\f08a";
}

.icon-sign-out:before {
  content: "\f08b";
}

.icon-linkedin-square:before {
  content: "\f08c";
}

.icon-thumb-tack:before {
  content: "\f08d";
}

.icon-external-link:before {
  content: "\f08e";
}

.icon-sign-in:before {
  content: "\f090";
}

.icon-trophy:before {
  content: "\f091";
}

.icon-github-square:before {
  content: "\f092";
}

.icon-upload:before {
  content: "\f093";
}

.icon-lemon-o:before {
  content: "\f094";
}

.icon-phone:before {
  content: "\f095";
}

.icon-square-o:before {
  content: "\f096";
}

.icon-bookmark-o:before {
  content: "\f097";
}

.icon-phone-square:before {
  content: "\f098";
}

.icon-twitter:before {
  content: "\f099";
}

.icon-facebook:before {
  content: "\f09a";
}

.icon-facebook-f:before {
  content: "\f09a";
}

.icon-github:before {
  content: "\f09b";
}

.icon-unlock:before {
  content: "\f09c";
}

.icon-credit-card:before {
  content: "\f09d";
}

.icon-feed:before {
  content: "\f09e";
}

.icon-rss:before {
  content: "\f09e";
}

.icon-hdd-o:before {
  content: "\f0a0";
}

.icon-bullhorn:before {
  content: "\f0a1";
}

.icon-bell-o:before {
  content: "\f0a2";
}

.icon-certificate:before {
  content: "\f0a3";
}

.icon-hand-o-right:before {
  content: "\f0a4";
}

.icon-hand-o-left:before {
  content: "\f0a5";
}

.icon-hand-o-up:before {
  content: "\f0a6";
}

.icon-hand-o-down:before {
  content: "\f0a7";
}

.icon-arrow-circle-left:before {
  content: "\f0a8";
}

.icon-arrow-circle-right:before {
  content: "\f0a9";
}

.icon-arrow-circle-up:before {
  content: "\f0aa";
}

.icon-arrow-circle-down:before {
  content: "\f0ab";
}

.icon-globe:before {
  content: "\f0ac";
}

.icon-wrench:before {
  content: "\f0ad";
}

.icon-tasks:before {
  content: "\f0ae";
}

.icon-filter:before {
  content: "\f0b0";
}

.icon-briefcase:before {
  content: "\f0b1";
}

.icon-arrows-alt:before {
  content: "\f0b2";
}

.icon-group:before {
  content: "\f0c0";
}

.icon-users:before {
  content: "\f0c0";
}

.icon-chain:before {
  content: "\f0c1";
}

.icon-link:before {
  content: "\f0c1";
}

.icon-cloud:before {
  content: "\f0c2";
}

.icon-flask:before {
  content: "\f0c3";
}

.icon-cut:before {
  content: "\f0c4";
}

.icon-scissors:before {
  content: "\f0c4";
}

.icon-copy:before {
  content: "\f0c5";
}

.icon-files-o:before {
  content: "\f0c5";
}

.icon-paperclip:before {
  content: "\f0c6";
}

.icon-floppy-o:before {
  content: "\f0c7";
}

.icon-save:before {
  content: "\f0c7";
}

.icon-square:before {
  content: "\f0c8";
}

.icon-bars:before {
  content: "\f0c9";
}

.icon-navicon:before {
  content: "\f0c9";
}

.icon-reorder:before {
  content: "\f0c9";
}

.icon-list-ul:before {
  content: "\f0ca";
}

.icon-list-ol:before {
  content: "\f0cb";
}

.icon-strikethrough:before {
  content: "\f0cc";
}

.icon-underline:before {
  content: "\f0cd";
}

.icon-table:before {
  content: "\f0ce";
}

.icon-magic:before {
  content: "\f0d0";
}

.icon-truck:before {
  content: "\f0d1";
}

.icon-pinterest:before {
  content: "\f0d2";
}

.icon-pinterest-square:before {
  content: "\f0d3";
}

.icon-google-plus-square:before {
  content: "\f0d4";
}

.icon-google-plus:before {
  content: "\f0d5";
}

.icon-money:before {
  content: "\f0d6";
}

.icon-caret-down:before {
  content: "\f0d7";
}

.icon-caret-up:before {
  content: "\f0d8";
}

.icon-caret-left:before {
  content: "\f0d9";
}

.icon-caret-right:before {
  content: "\f0da";
}

.icon-columns:before {
  content: "\f0db";
}

.icon-sort:before {
  content: "\f0dc";
}

.icon-unsorted:before {
  content: "\f0dc";
}

.icon-sort-desc:before {
  content: "\f0dd";
}

.icon-sort-down:before {
  content: "\f0dd";
}

.icon-sort-asc:before {
  content: "\f0de";
}

.icon-sort-up:before {
  content: "\f0de";
}

.icon-envelope:before {
  content: "\f0e0";
}

.icon-linkedin:before {
  content: "\f0e1";
}

.icon-rotate-left:before {
  content: "\f0e2";
}

.icon-undo:before {
  content: "\f0e2";
}

.icon-gavel:before {
  content: "\f0e3";
}

.icon-legal:before {
  content: "\f0e3";
}

.icon-dashboard:before {
  content: "\f0e4";
}

.icon-tachometer:before {
  content: "\f0e4";
}

.icon-comment-o:before {
  content: "\f0e5";
}

.icon-comments-o:before {
  content: "\f0e6";
}

.icon-bolt:before {
  content: "\f0e7";
}

.icon-flash:before {
  content: "\f0e7";
}

.icon-sitemap:before {
  content: "\f0e8";
}

.icon-umbrella:before {
  content: "\f0e9";
}

.icon-clipboard:before {
  content: "\f0ea";
}

.icon-paste:before {
  content: "\f0ea";
}

.icon-lightbulb-o:before {
  content: "\f0eb";
}

.icon-exchange:before {
  content: "\f0ec";
}

.icon-cloud-download:before {
  content: "\f0ed";
}

.icon-cloud-upload:before {
  content: "\f0ee";
}

.icon-user-md:before {
  content: "\f0f0";
}

.icon-stethoscope:before {
  content: "\f0f1";
}

.icon-suitcase:before {
  content: "\f0f2";
}

.icon-bell:before {
  content: "\f0f3";
}

.icon-coffee:before {
  content: "\f0f4";
}

.icon-cutlery:before {
  content: "\f0f5";
}

.icon-file-text-o:before {
  content: "\f0f6";
}

.icon-building-o:before {
  content: "\f0f7";
}

.icon-hospital-o:before {
  content: "\f0f8";
}

.icon-ambulance:before {
  content: "\f0f9";
}

.icon-medkit:before {
  content: "\f0fa";
}

.icon-fighter-jet:before {
  content: "\f0fb";
}

.icon-beer:before {
  content: "\f0fc";
}

.icon-h-square:before {
  content: "\f0fd";
}

.icon-plus-square:before {
  content: "\f0fe";
}

.icon-angle-double-left:before {
  content: "\f100";
}

.icon-angle-double-right:before {
  content: "\f101";
}

.icon-angle-double-up:before {
  content: "\f102";
}

.icon-angle-double-down:before {
  content: "\f103";
}

.icon-angle-left:before {
  content: "\f104";
}

.icon-angle-right:before {
  content: "\f105";
}

.icon-angle-up:before {
  content: "\f106";
}

.icon-angle-down:before {
  content: "\f107";
}

.icon-desktop:before {
  content: "\f108";
}

.icon-laptop:before {
  content: "\f109";
}

.icon-tablet:before {
  content: "\f10a";
}

.icon-mobile:before {
  content: "\f10b";
}

.icon-mobile-phone:before {
  content: "\f10b";
}

.icon-circle-o:before {
  content: "\f10c";
}

.icon-quote-left:before {
  content: "\f10d";
}

.icon-quote-right:before {
  content: "\f10e";
}

.icon-spinner:before {
  content: "\f110";
}

.icon-circle:before {
  content: "\f111";
}

.icon-mail-reply:before {
  content: "\f112";
}

.icon-reply:before {
  content: "\f112";
}

.icon-github-alt:before {
  content: "\f113";
}

.icon-folder-o:before {
  content: "\f114";
}

.icon-folder-open-o:before {
  content: "\f115";
}

.icon-smile-o:before {
  content: "\f118";
}

.icon-frown-o:before {
  content: "\f119";
}

.icon-meh-o:before {
  content: "\f11a";
}

.icon-gamepad:before {
  content: "\f11b";
}

.icon-keyboard-o:before {
  content: "\f11c";
}

.icon-flag-o:before {
  content: "\f11d";
}

.icon-flag-checkered:before {
  content: "\f11e";
}

.icon-terminal:before {
  content: "\f120";
}

.icon-code:before {
  content: "\f121";
}

.icon-mail-reply-all:before {
  content: "\f122";
}

.icon-reply-all:before {
  content: "\f122";
}

.icon-star-half-empty:before {
  content: "\f123";
}

.icon-star-half-full:before {
  content: "\f123";
}

.icon-star-half-o:before {
  content: "\f123";
}

.icon-location-arrow:before {
  content: "\f124";
}

.icon-crop:before {
  content: "\f125";
}

.icon-code-fork:before {
  content: "\f126";
}

.icon-chain-broken:before {
  content: "\f127";
}

.icon-unlink:before {
  content: "\f127";
}

.icon-info:before {
  content: "\f129";
}

.icon-exclamation:before {
  content: "\f12a";
}

.icon-superscript:before {
  content: "\f12b";
}

.icon-subscript:before {
  content: "\f12c";
}

.icon-eraser:before {
  content: "\f12d";
}

.icon-puzzle-piece:before {
  content: "\f12e";
}

.icon-microphone:before {
  content: "\f130";
}

.icon-microphone-slash:before {
  content: "\f131";
}

.icon-shield:before {
  content: "\f132";
}

.icon-calendar-o:before {
  content: "\f133";
}

.icon-fire-extinguisher:before {
  content: "\f134";
}

.icon-rocket:before {
  content: "\f135";
}

.icon-maxcdn:before {
  content: "\f136";
}

.icon-chevron-circle-left:before {
  content: "\f137";
}

.icon-chevron-circle-right:before {
  content: "\f138";
}

.icon-chevron-circle-up:before {
  content: "\f139";
}

.icon-chevron-circle-down:before {
  content: "\f13a";
}

.icon-html5:before {
  content: "\f13b";
}

.icon-css3:before {
  content: "\f13c";
}

.icon-anchor:before {
  content: "\f13d";
}

.icon-unlock-alt:before {
  content: "\f13e";
}

.icon-bullseye:before {
  content: "\f140";
}

.icon-ellipsis-h:before {
  content: "\f141";
}

.icon-ellipsis-v:before {
  content: "\f142";
}

.icon-rss-square:before {
  content: "\f143";
}

.icon-play-circle:before {
  content: "\f144";
}

.icon-ticket:before {
  content: "\f145";
}

.icon-minus-square:before {
  content: "\f146";
}

.icon-minus-square-o:before {
  content: "\f147";
}

.icon-level-up:before {
  content: "\f148";
}

.icon-level-down:before {
  content: "\f149";
}

.icon-check-square:before {
  content: "\f14a";
}

.icon-pencil-square:before {
  content: "\f14b";
}

.icon-external-link-square:before {
  content: "\f14c";
}

.icon-share-square:before {
  content: "\f14d";
}

.icon-compass:before {
  content: "\f14e";
}

.icon-caret-square-o-down:before {
  content: "\f150";
}

.icon-toggle-down:before {
  content: "\f150";
}

.icon-caret-square-o-up:before {
  content: "\f151";
}

.icon-toggle-up:before {
  content: "\f151";
}

.icon-caret-square-o-right:before {
  content: "\f152";
}

.icon-toggle-right:before {
  content: "\f152";
}

.icon-eur:before {
  content: "\f153";
}

.icon-euro:before {
  content: "\f153";
}

.icon-gbp:before {
  content: "\f154";
}

.icon-dollar:before {
  content: "\f155";
}

.icon-usd:before {
  content: "\f155";
}

.icon-inr:before {
  content: "\f156";
}

.icon-rupee:before {
  content: "\f156";
}

.icon-cny:before {
  content: "\f157";
}

.icon-jpy:before {
  content: "\f157";
}

.icon-rmb:before {
  content: "\f157";
}

.icon-yen:before {
  content: "\f157";
}

.icon-rouble:before {
  content: "\f158";
}

.icon-rub:before {
  content: "\f158";
}

.icon-ruble:before {
  content: "\f158";
}

.icon-krw:before {
  content: "\f159";
}

.icon-won:before {
  content: "\f159";
}

.icon-bitcoin:before {
  content: "\f15a";
}

.icon-btc:before {
  content: "\f15a";
}

.icon-file:before {
  content: "\f15b";
}

.icon-file-text:before {
  content: "\f15c";
}

.icon-sort-alpha-asc:before {
  content: "\f15d";
}

.icon-sort-alpha-desc:before {
  content: "\f15e";
}

.icon-sort-amount-asc:before {
  content: "\f160";
}

.icon-sort-amount-desc:before {
  content: "\f161";
}

.icon-sort-numeric-asc:before {
  content: "\f162";
}

.icon-sort-numeric-desc:before {
  content: "\f163";
}

.icon-thumbs-up:before {
  content: "\f164";
}

.icon-thumbs-down:before {
  content: "\f165";
}

.icon-youtube-square:before {
  content: "\f166";
}

.icon-youtube:before {
  content: "\f167";
}

.icon-xing:before {
  content: "\f168";
}

.icon-xing-square:before {
  content: "\f169";
}

.icon-youtube-play:before {
  content: "\f16a";
}

.icon-dropbox:before {
  content: "\f16b";
}

.icon-stack-overflow:before {
  content: "\f16c";
}

.icon-instagram:before {
  content: "\f16d";
}

.icon-flickr:before {
  content: "\f16e";
}

.icon-adn:before {
  content: "\f170";
}

.icon-bitbucket:before {
  content: "\f171";
}

.icon-bitbucket-square:before {
  content: "\f172";
}

.icon-tumblr:before {
  content: "\f173";
}

.icon-tumblr-square:before {
  content: "\f174";
}

.icon-long-arrow-down:before {
  content: "\f175";
}

.icon-long-arrow-up:before {
  content: "\f176";
}

.icon-long-arrow-left:before {
  content: "\f177";
}

.icon-long-arrow-right:before {
  content: "\f178";
}

.icon-apple:before {
  content: "\f179";
}

.icon-windows:before {
  content: "\f17a";
}

.icon-android:before {
  content: "\f17b";
}

.icon-linux:before {
  content: "\f17c";
}

.icon-dribbble:before {
  content: "\f17d";
}

.icon-skype:before {
  content: "\f17e";
}

.icon-foursquare:before {
  content: "\f180";
}

.icon-trello:before {
  content: "\f181";
}

.icon-female:before {
  content: "\f182";
}

.icon-male:before {
  content: "\f183";
}

.icon-gittip:before {
  content: "\f184";
}

.icon-gratipay:before {
  content: "\f184";
}

.icon-sun-o:before {
  content: "\f185";
}

.icon-moon-o:before {
  content: "\f186";
}

.icon-archive:before {
  content: "\f187";
}

.icon-bug:before {
  content: "\f188";
}

.icon-vk:before {
  content: "\f189";
}

.icon-weibo:before {
  content: "\f18a";
}

.icon-renren:before {
  content: "\f18b";
}

.icon-pagelines:before {
  content: "\f18c";
}

.icon-stack-exchange:before {
  content: "\f18d";
}

.icon-arrow-circle-o-right:before {
  content: "\f18e";
}

.icon-arrow-circle-o-left:before {
  content: "\f190";
}

.icon-caret-square-o-left:before {
  content: "\f191";
}

.icon-toggle-left:before {
  content: "\f191";
}

.icon-dot-circle-o:before {
  content: "\f192";
}

.icon-wheelchair:before {
  content: "\f193";
}

.icon-vimeo-square:before {
  content: "\f194";
}

.icon-try:before {
  content: "\f195";
}

.icon-turkish-lira:before {
  content: "\f195";
}

.icon-plus-square-o:before {
  content: "\f196";
}

.icon-space-shuttle:before {
  content: "\f197";
}

.icon-slack:before {
  content: "\f198";
}

.icon-envelope-square:before {
  content: "\f199";
}

.icon-wordpress:before {
  content: "\f19a";
}

.icon-openid:before {
  content: "\f19b";
}

.icon-bank:before {
  content: "\f19c";
}

.icon-institution:before {
  content: "\f19c";
}

.icon-university:before {
  content: "\f19c";
}

.icon-graduation-cap:before {
  content: "\f19d";
}

.icon-mortar-board:before {
  content: "\f19d";
}

.icon-yahoo:before {
  content: "\f19e";
}

.icon-google:before {
  content: "\f1a0";
}

.icon-reddit:before {
  content: "\f1a1";
}

.icon-reddit-square:before {
  content: "\f1a2";
}

.icon-stumbleupon-circle:before {
  content: "\f1a3";
}

.icon-stumbleupon:before {
  content: "\f1a4";
}

.icon-delicious:before {
  content: "\f1a5";
}

.icon-digg:before {
  content: "\f1a6";
}

.icon-pied-piper-pp:before {
  content: "\f1a7";
}

.icon-pied-piper-alt:before {
  content: "\f1a8";
}

.icon-drupal:before {
  content: "\f1a9";
}

.icon-joomla:before {
  content: "\f1aa";
}

.icon-language:before {
  content: "\f1ab";
}

.icon-fax:before {
  content: "\f1ac";
}

.icon-building:before {
  content: "\f1ad";
}

.icon-child:before {
  content: "\f1ae";
}

.icon-paw:before {
  content: "\f1b0";
}

.icon-spoon:before {
  content: "\f1b1";
}

.icon-cube:before {
  content: "\f1b2";
}

.icon-cubes:before {
  content: "\f1b3";
}

.icon-behance:before {
  content: "\f1b4";
}

.icon-behance-square:before {
  content: "\f1b5";
}

.icon-steam:before {
  content: "\f1b6";
}

.icon-steam-square:before {
  content: "\f1b7";
}

.icon-recycle:before {
  content: "\f1b8";
}

.icon-automobile:before {
  content: "\f1b9";
}

.icon-car:before {
  content: "\f1b9";
}

.icon-cab:before {
  content: "\f1ba";
}

.icon-taxi:before {
  content: "\f1ba";
}

.icon-tree:before {
  content: "\f1bb";
}

.icon-spotify:before {
  content: "\f1bc";
}

.icon-deviantart:before {
  content: "\f1bd";
}

.icon-soundcloud:before {
  content: "\f1be";
}

.icon-database:before {
  content: "\f1c0";
}

.icon-file-pdf-o:before {
  content: "\f1c1";
}

.icon-file-word-o:before {
  content: "\f1c2";
}

.icon-file-excel-o:before {
  content: "\f1c3";
}

.icon-file-powerpoint-o:before {
  content: "\f1c4";
}

.icon-file-image-o:before {
  content: "\f1c5";
}

.icon-file-photo-o:before {
  content: "\f1c5";
}

.icon-file-picture-o:before {
  content: "\f1c5";
}

.icon-file-archive-o:before {
  content: "\f1c6";
}

.icon-file-zip-o:before {
  content: "\f1c6";
}

.icon-file-audio-o:before {
  content: "\f1c7";
}

.icon-file-sound-o:before {
  content: "\f1c7";
}

.icon-file-movie-o:before {
  content: "\f1c8";
}

.icon-file-video-o:before {
  content: "\f1c8";
}

.icon-file-code-o:before {
  content: "\f1c9";
}

.icon-vine:before {
  content: "\f1ca";
}

.icon-codepen:before {
  content: "\f1cb";
}

.icon-jsfiddle:before {
  content: "\f1cc";
}

.icon-life-bouy:before {
  content: "\f1cd";
}

.icon-life-buoy:before {
  content: "\f1cd";
}

.icon-life-ring:before {
  content: "\f1cd";
}

.icon-life-saver:before {
  content: "\f1cd";
}

.icon-support:before {
  content: "\f1cd";
}

.icon-circle-o-notch:before {
  content: "\f1ce";
}

.icon-ra:before {
  content: "\f1d0";
}

.icon-rebel:before {
  content: "\f1d0";
}

.icon-resistance:before {
  content: "\f1d0";
}

.icon-empire:before {
  content: "\f1d1";
}

.icon-ge:before {
  content: "\f1d1";
}

.icon-git-square:before {
  content: "\f1d2";
}

.icon-git:before {
  content: "\f1d3";
}

.icon-hacker-news:before {
  content: "\f1d4";
}

.icon-y-combinator-square:before {
  content: "\f1d4";
}

.icon-yc-square:before {
  content: "\f1d4";
}

.icon-tencent-weibo:before {
  content: "\f1d5";
}

.icon-qq:before {
  content: "\f1d6";
}

.icon-wechat:before {
  content: "\f1d7";
}

.icon-weixin:before {
  content: "\f1d7";
}

.icon-paper-plane:before {
  content: "\f1d8";
}

.icon-send:before {
  content: "\f1d8";
}

.icon-paper-plane-o:before {
  content: "\f1d9";
}

.icon-send-o:before {
  content: "\f1d9";
}

.icon-history:before {
  content: "\f1da";
}

.icon-circle-thin:before {
  content: "\f1db";
}

.icon-header:before {
  content: "\f1dc";
}

.icon-paragraph:before {
  content: "\f1dd";
}

.icon-sliders:before {
  content: "\f1de";
}

.icon-share-alt:before {
  content: "\f1e0";
}

.icon-share-alt-square:before {
  content: "\f1e1";
}

.icon-bomb:before {
  content: "\f1e2";
}

.icon-futbol-o:before {
  content: "\f1e3";
}

.icon-soccer-ball-o:before {
  content: "\f1e3";
}

.icon-tty:before {
  content: "\f1e4";
}

.icon-binoculars:before {
  content: "\f1e5";
}

.icon-plug:before {
  content: "\f1e6";
}

.icon-slideshare:before {
  content: "\f1e7";
}

.icon-twitch:before {
  content: "\f1e8";
}

.icon-yelp:before {
  content: "\f1e9";
}

.icon-newspaper-o:before {
  content: "\f1ea";
}

.icon-wifi:before {
  content: "\f1eb";
}

.icon-calculator:before {
  content: "\f1ec";
}

.icon-paypal:before {
  content: "\f1ed";
}

.icon-google-wallet:before {
  content: "\f1ee";
}

.icon-cc-visa:before {
  content: "\f1f0";
}

.icon-cc-mastercard:before {
  content: "\f1f1";
}

.icon-cc-discover:before {
  content: "\f1f2";
}

.icon-cc-amex:before {
  content: "\f1f3";
}

.icon-cc-paypal:before {
  content: "\f1f4";
}

.icon-cc-stripe:before {
  content: "\f1f5";
}

.icon-bell-slash:before {
  content: "\f1f6";
}

.icon-bell-slash-o:before {
  content: "\f1f7";
}

.icon-trash:before {
  content: "\f1f8";
}

.icon-copyright:before {
  content: "\f1f9";
}

.icon-at:before {
  content: "\f1fa";
}

.icon-eyedropper:before {
  content: "\f1fb";
}

.icon-paint-brush:before {
  content: "\f1fc";
}

.icon-birthday-cake:before {
  content: "\f1fd";
}

.icon-area-chart:before {
  content: "\f1fe";
}

.icon-pie-chart:before {
  content: "\f200";
}

.icon-line-chart:before {
  content: "\f201";
}

.icon-lastfm:before {
  content: "\f202";
}

.icon-lastfm-square:before {
  content: "\f203";
}

.icon-toggle-off:before {
  content: "\f204";
}

.icon-toggle-on:before {
  content: "\f205";
}

.icon-bicycle:before {
  content: "\f206";
}

.icon-bus:before {
  content: "\f207";
}

.icon-ioxhost:before {
  content: "\f208";
}

.icon-angellist:before {
  content: "\f209";
}

.icon-cc:before {
  content: "\f20a";
}

.icon-ils:before {
  content: "\f20b";
}

.icon-shekel:before {
  content: "\f20b";
}

.icon-sheqel:before {
  content: "\f20b";
}

.icon-meanpath:before {
  content: "\f20c";
}

.icon-buysellads:before {
  content: "\f20d";
}

.icon-connectdevelop:before {
  content: "\f20e";
}

.icon-dashcube:before {
  content: "\f210";
}

.icon-forumbee:before {
  content: "\f211";
}

.icon-leanpub:before {
  content: "\f212";
}

.icon-sellsy:before {
  content: "\f213";
}

.icon-shirtsinbulk:before {
  content: "\f214";
}

.icon-simplybuilt:before {
  content: "\f215";
}

.icon-skyatlas:before {
  content: "\f216";
}

.icon-cart-plus:before {
  content: "\f217";
}

.icon-cart-arrow-down:before {
  content: "\f218";
}

.icon-diamond:before {
  content: "\f219";
}

.icon-ship:before {
  content: "\f21a";
}

.icon-user-secret:before {
  content: "\f21b";
}

.icon-motorcycle:before {
  content: "\f21c";
}

.icon-street-view:before {
  content: "\f21d";
}

.icon-heartbeat:before {
  content: "\f21e";
}

.icon-venus:before {
  content: "\f221";
}

.icon-mars:before {
  content: "\f222";
}

.icon-mercury:before {
  content: "\f223";
}

.icon-intersex:before {
  content: "\f224";
}

.icon-transgender:before {
  content: "\f224";
}

.icon-transgender-alt:before {
  content: "\f225";
}

.icon-venus-double:before {
  content: "\f226";
}

.icon-mars-double:before {
  content: "\f227";
}

.icon-venus-mars:before {
  content: "\f228";
}

.icon-mars-stroke:before {
  content: "\f229";
}

.icon-mars-stroke-v:before {
  content: "\f22a";
}

.icon-mars-stroke-h:before {
  content: "\f22b";
}

.icon-neuter:before {
  content: "\f22c";
}

.icon-genderless:before {
  content: "\f22d";
}

.icon-facebook-official:before {
  content: "\f230";
}

.icon-pinterest-p:before {
  content: "\f231";
}

.icon-whatsapp:before {
  content: "\f232";
}

.icon-server:before {
  content: "\f233";
}

.icon-user-plus:before {
  content: "\f234";
}

.icon-user-times:before {
  content: "\f235";
}

.icon-bed:before {
  content: "\f236";
}

.icon-hotel:before {
  content: "\f236";
}

.icon-viacoin:before {
  content: "\f237";
}

.icon-train:before {
  content: "\f238";
}

.icon-subway:before {
  content: "\f239";
}

.icon-medium:before {
  content: "\f23a";
}

.icon-y-combinator:before {
  content: "\f23b";
}

.icon-yc:before {
  content: "\f23b";
}

.icon-optin-monster:before {
  content: "\f23c";
}

.icon-opencart:before {
  content: "\f23d";
}

.icon-expeditedssl:before {
  content: "\f23e";
}

.icon-battery:before {
  content: "\f240";
}

.icon-battery-4:before {
  content: "\f240";
}

.icon-battery-full:before {
  content: "\f240";
}

.icon-battery-3:before {
  content: "\f241";
}

.icon-battery-three-quarters:before {
  content: "\f241";
}

.icon-battery-2:before {
  content: "\f242";
}

.icon-battery-half:before {
  content: "\f242";
}

.icon-battery-1:before {
  content: "\f243";
}

.icon-battery-quarter:before {
  content: "\f243";
}

.icon-battery-0:before {
  content: "\f244";
}

.icon-battery-empty:before {
  content: "\f244";
}

.icon-mouse-pointer:before {
  content: "\f245";
}

.icon-i-cursor:before {
  content: "\f246";
}

.icon-object-group:before {
  content: "\f247";
}

.icon-object-ungroup:before {
  content: "\f248";
}

.icon-sticky-note:before {
  content: "\f249";
}

.icon-sticky-note-o:before {
  content: "\f24a";
}

.icon-cc-jcb:before {
  content: "\f24b";
}

.icon-cc-diners-club:before {
  content: "\f24c";
}

.icon-clone:before {
  content: "\f24d";
}

.icon-balance-scale:before {
  content: "\f24e";
}

.icon-hourglass-o:before {
  content: "\f250";
}

.icon-hourglass-1:before {
  content: "\f251";
}

.icon-hourglass-start:before {
  content: "\f251";
}

.icon-hourglass-2:before {
  content: "\f252";
}

.icon-hourglass-half:before {
  content: "\f252";
}

.icon-hourglass-3:before {
  content: "\f253";
}

.icon-hourglass-end:before {
  content: "\f253";
}

.icon-hourglass:before {
  content: "\f254";
}

.icon-hand-grab-o:before {
  content: "\f255";
}

.icon-hand-rock-o:before {
  content: "\f255";
}

.icon-hand-paper-o:before {
  content: "\f256";
}

.icon-hand-stop-o:before {
  content: "\f256";
}

.icon-hand-scissors-o:before {
  content: "\f257";
}

.icon-hand-lizard-o:before {
  content: "\f258";
}

.icon-hand-spock-o:before {
  content: "\f259";
}

.icon-hand-pointer-o:before {
  content: "\f25a";
}

.icon-hand-peace-o:before {
  content: "\f25b";
}

.icon-trademark:before {
  content: "\f25c";
}

.icon-registered:before {
  content: "\f25d";
}

.icon-creative-commons:before {
  content: "\f25e";
}

.icon-gg:before {
  content: "\f260";
}

.icon-gg-circle:before {
  content: "\f261";
}

.icon-tripadvisor:before {
  content: "\f262";
}

.icon-odnoklassniki:before {
  content: "\f263";
}

.icon-odnoklassniki-square:before {
  content: "\f264";
}

.icon-get-pocket:before {
  content: "\f265";
}

.icon-wikipedia-w:before {
  content: "\f266";
}

.icon-safari:before {
  content: "\f267";
}

.icon-chrome:before {
  content: "\f268";
}

.icon-firefox:before {
  content: "\f269";
}

.icon-opera:before {
  content: "\f26a";
}

.icon-internet-explorer:before {
  content: "\f26b";
}

.icon-television:before {
  content: "\f26c";
}

.icon-tv:before {
  content: "\f26c";
}

.icon-contao:before {
  content: "\f26d";
}

.icon-500px:before {
  content: "\f26e";
}

.icon-amazon:before {
  content: "\f270";
}

.icon-calendar-plus-o:before {
  content: "\f271";
}

.icon-calendar-minus-o:before {
  content: "\f272";
}

.icon-calendar-times-o:before {
  content: "\f273";
}

.icon-calendar-check-o:before {
  content: "\f274";
}

.icon-industry:before {
  content: "\f275";
}

.icon-map-pin:before {
  content: "\f276";
}

.icon-map-signs:before {
  content: "\f277";
}

.icon-map-o:before {
  content: "\f278";
}

.icon-map:before {
  content: "\f279";
}

.icon-commenting:before {
  content: "\f27a";
}

.icon-commenting-o:before {
  content: "\f27b";
}

.icon-houzz:before {
  content: "\f27c";
}

.icon-vimeo:before {
  content: "\f27d";
}
.icon-devpost:before {
  /* content: "\f6cc"; */
  content: url("../images/dev-brands.svg");
}

.icon-black-tie:before {
  content: "\f27e";
}

.icon-fonticons:before {
  content: "\f280";
}

.icon-reddit-alien:before {
  content: "\f281";
}

.icon-edge:before {
  content: "\f282";
}

.icon-credit-card-alt:before {
  content: "\f283";
}

.icon-codiepie:before {
  content: "\f284";
}

.icon-modx:before {
  content: "\f285";
}

.icon-fort-awesome:before {
  content: "\f286";
}

.icon-usb:before {
  content: "\f287";
}

.icon-product-hunt:before {
  content: "\f288";
}

.icon-mixcloud:before {
  content: "\f289";
}

.icon-scribd:before {
  content: "\f28a";
}

.icon-pause-circle:before {
  content: "\f28b";
}

.icon-pause-circle-o:before {
  content: "\f28c";
}

.icon-stop-circle:before {
  content: "\f28d";
}

.icon-stop-circle-o:before {
  content: "\f28e";
}

.icon-shopping-bag:before {
  content: "\f290";
}

.icon-shopping-basket:before {
  content: "\f291";
}

.icon-hashtag:before {
  content: "\f292";
}

.icon-bluetooth:before {
  content: "\f293";
}

.icon-bluetooth-b:before {
  content: "\f294";
}

.icon-percent:before {
  content: "\f295";
}

.icon-gitlab:before {
  content: "\f296";
}

.icon-wpbeginner:before {
  content: "\f297";
}

.icon-wpforms:before {
  content: "\f298";
}

.icon-envira:before {
  content: "\f299";
}

.icon-universal-access:before {
  content: "\f29a";
}

.icon-wheelchair-alt:before {
  content: "\f29b";
}

.icon-question-circle-o:before {
  content: "\f29c";
}

.icon-blind:before {
  content: "\f29d";
}

.icon-audio-description:before {
  content: "\f29e";
}

.icon-volume-control-phone:before {
  content: "\f2a0";
}

.icon-braille:before {
  content: "\f2a1";
}

.icon-assistive-listening-systems:before {
  content: "\f2a2";
}

.icon-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.icon-asl-interpreting:before {
  content: "\f2a3";
}

.icon-deaf:before {
  content: "\f2a4";
}

.icon-deafness:before {
  content: "\f2a4";
}

.icon-hard-of-hearing:before {
  content: "\f2a4";
}

.icon-glide:before {
  content: "\f2a5";
}

.icon-glide-g:before {
  content: "\f2a6";
}

.icon-sign-language:before {
  content: "\f2a7";
}

.icon-signing:before {
  content: "\f2a7";
}

.icon-low-vision:before {
  content: "\f2a8";
}

.icon-viadeo:before {
  content: "\f2a9";
}

.icon-viadeo-square:before {
  content: "\f2aa";
}

.icon-snapchat:before {
  content: "\f2ab";
}

.icon-snapchat-ghost:before {
  content: "\f2ac";
}

.icon-snapchat-square:before {
  content: "\f2ad";
}

.icon-pied-piper:before {
  content: "\f2ae";
}

.icon-first-order:before {
  content: "\f2b0";
}

.icon-yoast:before {
  content: "\f2b1";
}

.icon-themeisle:before {
  content: "\f2b2";
}

.icon-google-plus-circle:before {
  content: "\f2b3";
}

.icon-google-plus-official:before {
  content: "\f2b3";
}

.icon-fa:before {
  content: "\f2b4";
}

.icon-font-awesome:before {
  content: "\f2b4";
}

.icon-handshake-o:before {
  content: "\f2b5";
}

.icon-envelope-open:before {
  content: "\f2b6";
}

.icon-envelope-open-o:before {
  content: "\f2b7";
}

.icon-linode:before {
  content: "\f2b8";
}

.icon-address-book:before {
  content: "\f2b9";
}

.icon-address-book-o:before {
  content: "\f2ba";
}

.icon-address-card:before {
  content: "\f2bb";
}

.icon-vcard:before {
  content: "\f2bb";
}

.icon-address-card-o:before {
  content: "\f2bc";
}

.icon-vcard-o:before {
  content: "\f2bc";
}

.icon-user-circle:before {
  content: "\f2bd";
}

.icon-user-circle-o:before {
  content: "\f2be";
}

.icon-user-o:before {
  content: "\f2c0";
}

.icon-id-badge:before {
  content: "\f2c1";
}

.icon-drivers-license:before {
  content: "\f2c2";
}

.icon-id-card:before {
  content: "\f2c2";
}

.icon-drivers-license-o:before {
  content: "\f2c3";
}

.icon-id-card-o:before {
  content: "\f2c3";
}

.icon-quora:before {
  content: "\f2c4";
}

.icon-free-code-camp:before {
  content: "\f2c5";
}

.icon-telegram:before {
  content: "\f2c6";
}

.icon-thermometer:before {
  content: "\f2c7";
}

.icon-thermometer-4:before {
  content: "\f2c7";
}

.icon-thermometer-full:before {
  content: "\f2c7";
}

.icon-thermometer-3:before {
  content: "\f2c8";
}

.icon-thermometer-three-quarters:before {
  content: "\f2c8";
}

.icon-thermometer-2:before {
  content: "\f2c9";
}

.icon-thermometer-half:before {
  content: "\f2c9";
}

.icon-thermometer-1:before {
  content: "\f2ca";
}

.icon-thermometer-quarter:before {
  content: "\f2ca";
}

.icon-thermometer-0:before {
  content: "\f2cb";
}

.icon-thermometer-empty:before {
  content: "\f2cb";
}

.icon-shower:before {
  content: "\f2cc";
}

.icon-bath:before {
  content: "\f2cd";
}

.icon-bathtub:before {
  content: "\f2cd";
}

.icon-s15:before {
  content: "\f2cd";
}

.icon-podcast:before {
  content: "\f2ce";
}

.icon-window-maximize:before {
  content: "\f2d0";
}

.icon-window-minimize:before {
  content: "\f2d1";
}

.icon-window-restore:before {
  content: "\f2d2";
}

.icon-times-rectangle:before {
  content: "\f2d3";
}

.icon-window-close:before {
  content: "\f2d3";
}

.icon-times-rectangle-o:before {
  content: "\f2d4";
}

.icon-window-close-o:before {
  content: "\f2d4";
}

.icon-bandcamp:before {
  content: "\f2d5";
}

.icon-grav:before {
  content: "\f2d6";
}

.icon-etsy:before {
  content: "\f2d7";
}

.icon-imdb:before {
  content: "\f2d8";
}

.icon-ravelry:before {
  content: "\f2d9";
}

.icon-eercast:before {
  content: "\f2da";
}

.icon-microchip:before {
  content: "\f2db";
}

.icon-snowflake-o:before {
  content: "\f2dc";
}

.icon-superpowers:before {
  content: "\f2dd";
}

.icon-wpexplorer:before {
  content: "\f2de";
}

.icon-meetup:before {
  content: "\f2e0";
}

.icon-weather_aquarius:before {
  content: "\e900";
}

.icon-weather_aries:before {
  content: "\e901";
}

.icon-weather_cancer:before {
  content: "\e902";
}

.icon-weather_capricorn:before {
  content: "\e903";
}

.icon-weather_cloud_drop:before {
  content: "\e904";
}

.icon-weather_cloud_lightning:before {
  content: "\e905";
}

.icon-weather_cloud_snowflake:before {
  content: "\e906";
}

.icon-weather_cloud:before {
  content: "\e907";
}

.icon-weather_downpour_fullmoon:before {
  content: "\e908";
}

.icon-weather_downpour_halfmoon:before {
  content: "\e909";
}

.icon-weather_downpour_sun:before {
  content: "\e90a";
}

.icon-weather_drop:before {
  content: "\e90b";
}

.icon-weather_first_quarter:before {
  content: "\e90c";
}

.icon-weather_fog_fullmoon:before {
  content: "\e90d";
}

.icon-weather_fog_halfmoon:before {
  content: "\e90e";
}

.icon-weather_fog_sun:before {
  content: "\e90f";
}

.icon-weather_fog:before {
  content: "\e910";
}

.icon-weather_fullmoon:before {
  content: "\e911";
}

.icon-weather_gemini:before {
  content: "\e912";
}

.icon-weather_hail_fullmoon:before {
  content: "\e913";
}

.icon-weather_hail_halfmoon:before {
  content: "\e914";
}

.icon-weather_hail_sun:before {
  content: "\e915";
}

.icon-weather_hail:before {
  content: "\e916";
}

.icon-weather_last_quarter:before {
  content: "\e917";
}

.icon-weather_leo:before {
  content: "\e918";
}

.icon-weather_libra:before {
  content: "\e919";
}

.icon-weather_lightning:before {
  content: "\e91a";
}

.icon-weather_mistyrain_fullmoon:before {
  content: "\e91b";
}

.icon-weather_mistyrain_halfmoon:before {
  content: "\e91c";
}

.icon-weather_mistyrain_sun:before {
  content: "\e91d";
}

.icon-weather_mistyrain:before {
  content: "\e91e";
}

.icon-weather_moon:before {
  content: "\e91f";
}

.icon-weather_moondown_full:before {
  content: "\e920";
}

.icon-weather_moondown_half:before {
  content: "\e921";
}

.icon-weather_moonset_full:before {
  content: "\e922";
}

.icon-weather_moonset_half:before {
  content: "\e923";
}

.icon-weather_move2:before {
  content: "\e924";
}

.icon-weather_newmoon:before {
  content: "\e925";
}

.icon-weather_pisces:before {
  content: "\e926";
}

.icon-weather_rain_fullmoon:before {
  content: "\e927";
}

.icon-weather_rain_halfmoon:before {
  content: "\e928";
}

.icon-weather_rain_sun:before {
  content: "\e929";
}

.icon-weather_rain:before {
  content: "\e92a";
}

.icon-weather_sagittarius:before {
  content: "\e92b";
}

.icon-weather_scorpio:before {
  content: "\e92c";
}

.icon-weather_snow_fullmoon:before {
  content: "\e92d";
}

.icon-weather_snow_halfmoon:before {
  content: "\e92e";
}

.icon-weather_snow_sun:before {
  content: "\e92f";
}

.icon-weather_snow:before {
  content: "\e930";
}

.icon-weather_snowflake:before {
  content: "\e931";
}

.icon-weather_star:before {
  content: "\e932";
}

.icon-weather_storm_fullmoon:before {
  content: "\e933";
}

.icon-weather_storm_halfmoon:before {
  content: "\e934";
}

.icon-weather_storm_sun:before {
  content: "\e935";
}

.icon-weather_storm-11:before {
  content: "\e936";
}

.icon-weather_storm-32:before {
  content: "\e937";
}

.icon-weather_sun:before {
  content: "\e938";
}

.icon-weather_sundown:before {
  content: "\e939";
}

.icon-weather_sunset:before {
  content: "\e93a";
}

.icon-weather_taurus:before {
  content: "\e93b";
}

.icon-weather_tempest_fullmoon:before {
  content: "\e93c";
}

.icon-weather_tempest_halfmoon:before {
  content: "\e93d";
}

.icon-weather_tempest_sun:before {
  content: "\e93e";
}

.icon-weather_tempest:before {
  content: "\e93f";
}

.icon-weather_variable_fullmoon:before {
  content: "\e940";
}

.icon-weather_variable_halfmoon:before {
  content: "\e941";
}

.icon-weather_variable_sun:before {
  content: "\e942";
}

.icon-weather_virgo:before {
  content: "\e943";
}

.icon-weather_waning_cresent:before {
  content: "\e944";
}

.icon-weather_waning_gibbous:before {
  content: "\e945";
}

.icon-weather_waxing_cresent:before {
  content: "\e946";
}

.icon-weather_waxing_gibbous:before {
  content: "\e947";
}

.icon-weather_wind_E:before {
  content: "\e948";
}

.icon-weather_wind_fullmoon:before {
  content: "\e949";
}

.icon-weather_wind_halfmoon:before {
  content: "\e94a";
}

.icon-weather_wind_N:before {
  content: "\e94b";
}

.icon-weather_wind_NE:before {
  content: "\e94c";
}

.icon-weather_wind_NW:before {
  content: "\e94d";
}

.icon-weather_wind_S:before {
  content: "\e94e";
}

.icon-weather_wind_SE:before {
  content: "\e94f";
}

.icon-weather_wind_sun:before {
  content: "\e950";
}

.icon-weather_wind_SW:before {
  content: "\e951";
}

.icon-weather_wind_W:before {
  content: "\e952";
}

.icon-weather_wind:before {
  content: "\e953";
}

.icon-weather_windgust:before {
  content: "\e954";
}

.icon-software_add_vectorpoint:before {
  content: "\e955";
}

.icon-software_box_oval:before {
  content: "\e956";
}

.icon-software_box_polygon:before {
  content: "\e957";
}

.icon-software_box_rectangle:before {
  content: "\e958";
}

.icon-software_box_roundedrectangle:before {
  content: "\e959";
}

.icon-software_character:before {
  content: "\e95a";
}

.icon-software_crop:before {
  content: "\e95b";
}

.icon-software_eyedropper:before {
  content: "\e95c";
}

.icon-software_font_allcaps:before {
  content: "\e95d";
}

.icon-software_font_baseline_shift:before {
  content: "\e95e";
}

.icon-software_font_horizontal_scale:before {
  content: "\e95f";
}

.icon-software_font_kerning:before {
  content: "\e960";
}

.icon-software_font_leading:before {
  content: "\e961";
}

.icon-software_font_size:before {
  content: "\e962";
}

.icon-software_font_smallcapital:before {
  content: "\e963";
}

.icon-software_font_smallcaps:before {
  content: "\e964";
}

.icon-software_font_strikethrough:before {
  content: "\e965";
}

.icon-software_font_tracking:before {
  content: "\e966";
}

.icon-software_font_underline:before {
  content: "\e967";
}

.icon-software_font_vertical_scale:before {
  content: "\e968";
}

.icon-software_horizontal_align_center:before {
  content: "\e969";
}

.icon-software_horizontal_align_right:before {
  content: "\e96a";
}

.icon-software_horizontal_distribute_center:before {
  content: "\e96b";
}

.icon-software_horizontal_distribute_left:before {
  content: "\e96c";
}

.icon-software_horizontal_distribute_right:before {
  content: "\e96d";
}

.icon-software_indent_firstline:before {
  content: "\e96e";
}

.icon-software_indent_left:before {
  content: "\e96f";
}

.icon-software_indent_right:before {
  content: "\e970";
}

.icon-software_lasso:before {
  content: "\e971";
}

.icon-software_layers1:before {
  content: "\e972";
}

.icon-software_layers2:before {
  content: "\e973";
}

.icon-software_layout_2columns:before {
  content: "\e974";
}

.icon-software_layout_3columns:before {
  content: "\e975";
}

.icon-software_layout_4boxes:before {
  content: "\e976";
}

.icon-software_layout_4columns:before {
  content: "\e977";
}

.icon-software_layout_4lines:before {
  content: "\e978";
}

.icon-software_layout_header_2columns:before {
  content: "\e979";
}

.icon-software_layout_header_3columns:before {
  content: "\e97a";
}

.icon-software_layout_header_4boxes:before {
  content: "\e97b";
}

.icon-software_layout_header_4columns:before {
  content: "\e97c";
}

.icon-software_layout_header_complex:before {
  content: "\e97d";
}

.icon-software_layout_header_complex2:before {
  content: "\e97e";
}

.icon-software_layout_header_complex3:before {
  content: "\e97f";
}

.icon-software_layout_header_complex4:before {
  content: "\e980";
}

.icon-software_layout_header_sideleft:before {
  content: "\e981";
}

.icon-software_layout_header_sideright:before {
  content: "\e982";
}

.icon-software_layout_header:before {
  content: "\e983";
}

.icon-software_layout_sidebar_left:before {
  content: "\e984";
}

.icon-software_layout_sidebar_right:before {
  content: "\e985";
}

.icon-software_layout-8boxes:before {
  content: "\e986";
}

.icon-software_layout:before {
  content: "\e987";
}

.icon-software_magnete:before {
  content: "\e988";
}

.icon-software_pages:before {
  content: "\e989";
}

.icon-software_paintbrush:before {
  content: "\e98a";
}

.icon-software_paintbucket:before {
  content: "\e98b";
}

.icon-software_paintroller:before {
  content: "\e98c";
}

.icon-software_paragraph_align_left:before {
  content: "\e98d";
}

.icon-software_paragraph_align_right:before {
  content: "\e98e";
}

.icon-software_paragraph_center:before {
  content: "\e98f";
}

.icon-software_paragraph_justify_all:before {
  content: "\e990";
}

.icon-software_paragraph_justify_center:before {
  content: "\e991";
}

.icon-software_paragraph_justify_left:before {
  content: "\e992";
}

.icon-software_paragraph_justify_right:before {
  content: "\e993";
}

.icon-software_paragraph_space_after:before {
  content: "\e994";
}

.icon-software_paragraph_space_before:before {
  content: "\e995";
}

.icon-software_paragraph:before {
  content: "\e996";
}

.icon-software_pathfinder_exclude:before {
  content: "\e997";
}

.icon-software_pathfinder_intersect:before {
  content: "\e998";
}

.icon-software_pathfinder_subtract:before {
  content: "\e999";
}

.icon-software_pathfinder_unite:before {
  content: "\e99a";
}

.icon-software_pen_add:before {
  content: "\e99b";
}

.icon-software_pen_remove:before {
  content: "\e99c";
}

.icon-software_pen:before {
  content: "\e99d";
}

.icon-software_pencil:before {
  content: "\e99e";
}

.icon-software_polygonallasso:before {
  content: "\e99f";
}

.icon-software_reflect_horizontal:before {
  content: "\e9a0";
}

.icon-software_reflect_vertical:before {
  content: "\e9a1";
}

.icon-software_remove_vectorpoint:before {
  content: "\e9a2";
}

.icon-software_scale_expand:before {
  content: "\e9a3";
}

.icon-software_scale_reduce:before {
  content: "\e9a4";
}

.icon-software_selection_oval:before {
  content: "\e9a5";
}

.icon-software_selection_polygon:before {
  content: "\e9a6";
}

.icon-software_selection_rectangle:before {
  content: "\e9a7";
}

.icon-software_selection_roundedrectangle:before {
  content: "\e9a8";
}

.icon-software_shape_oval:before {
  content: "\e9a9";
}

.icon-software_shape_polygon:before {
  content: "\e9aa";
}

.icon-software_shape_rectangle:before {
  content: "\e9ab";
}

.icon-software_shape_roundedrectangle:before {
  content: "\e9ac";
}

.icon-software_slice:before {
  content: "\e9ad";
}

.icon-software_transform_bezier:before {
  content: "\e9ae";
}

.icon-software_vector_box:before {
  content: "\e9af";
}

.icon-software_vector_composite:before {
  content: "\e9b0";
}

.icon-software_vector_line:before {
  content: "\e9b1";
}

.icon-software_vertical_align_bottom:before {
  content: "\e9b2";
}

.icon-software_vertical_align_center:before {
  content: "\e9b3";
}

.icon-software_vertical_align_top:before {
  content: "\e9b4";
}

.icon-software_vertical_distribute_bottom:before {
  content: "\e9b5";
}

.icon-software_vertical_distribute_center:before {
  content: "\e9b6";
}

.icon-software_vertical_distribute_top:before {
  content: "\e9b7";
}

.icon-software-horizontal_align_left:before {
  content: "\e9b8";
}

.icon-music_beginning_button:before {
  content: "\e9b9";
}

.icon-music_bell:before {
  content: "\e9ba";
}

.icon-music_cd:before {
  content: "\e9bb";
}

.icon-music_diapason:before {
  content: "\e9bc";
}

.icon-music_eject_button:before {
  content: "\e9bd";
}

.icon-music_end_button:before {
  content: "\e9be";
}

.icon-music_fastforward_button:before {
  content: "\e9bf";
}

.icon-music_headphones:before {
  content: "\e9c0";
}

.icon-music_ipod:before {
  content: "\e9c1";
}

.icon-music_loudspeaker:before {
  content: "\e9c2";
}

.icon-music_microphone_old:before {
  content: "\e9c3";
}

.icon-music_microphone:before {
  content: "\e9c4";
}

.icon-music_mixer:before {
  content: "\e9c5";
}

.icon-music_mute:before {
  content: "\e9c6";
}

.icon-music_note_multiple:before {
  content: "\e9c7";
}

.icon-music_note_single:before {
  content: "\e9c8";
}

.icon-music_pause_button:before {
  content: "\e9c9";
}

.icon-music_play_button:before {
  content: "\e9ca";
}

.icon-music_playlist:before {
  content: "\e9cb";
}

.icon-music_radio_ghettoblaster:before {
  content: "\e9cc";
}

.icon-music_radio_portable:before {
  content: "\e9cd";
}

.icon-music_record:before {
  content: "\e9ce";
}

.icon-music_recordplayer:before {
  content: "\e9cf";
}

.icon-music_repeat_button:before {
  content: "\e9d0";
}

.icon-music_rewind_button:before {
  content: "\e9d1";
}

.icon-music_shuffle_button:before {
  content: "\e9d2";
}

.icon-music_stop_button:before {
  content: "\e9d3";
}

.icon-music_tape:before {
  content: "\e9d4";
}

.icon-music_volume_down:before {
  content: "\e9d5";
}

.icon-music_volume_up:before {
  content: "\e9d6";
}

.icon-ecommerce_bag_check:before {
  content: "\e9d7";
}

.icon-ecommerce_bag_cloud:before {
  content: "\e9d8";
}

.icon-ecommerce_bag_download:before {
  content: "\e9d9";
}

.icon-ecommerce_bag_minus:before {
  content: "\e9da";
}

.icon-ecommerce_bag_plus:before {
  content: "\e9db";
}

.icon-ecommerce_bag_refresh:before {
  content: "\e9dc";
}

.icon-ecommerce_bag_remove:before {
  content: "\e9dd";
}

.icon-ecommerce_bag_search:before {
  content: "\e9de";
}

.icon-ecommerce_bag_upload:before {
  content: "\e9df";
}

.icon-ecommerce_bag:before {
  content: "\e9e0";
}

.icon-ecommerce_banknote:before {
  content: "\e9e1";
}

.icon-ecommerce_banknotes:before {
  content: "\e9e2";
}

.icon-ecommerce_basket_check:before {
  content: "\e9e3";
}

.icon-ecommerce_basket_cloud:before {
  content: "\e9e4";
}

.icon-ecommerce_basket_download:before {
  content: "\e9e5";
}

.icon-ecommerce_basket_minus:before {
  content: "\e9e6";
}

.icon-ecommerce_basket_plus:before {
  content: "\e9e7";
}

.icon-ecommerce_basket_refresh:before {
  content: "\e9e8";
}

.icon-ecommerce_basket_remove:before {
  content: "\e9e9";
}

.icon-ecommerce_basket_search:before {
  content: "\e9ea";
}

.icon-ecommerce_basket_upload:before {
  content: "\e9eb";
}

.icon-ecommerce_basket:before {
  content: "\e9ec";
}

.icon-ecommerce_bath:before {
  content: "\e9ed";
}

.icon-ecommerce_cart_check:before {
  content: "\e9ee";
}

.icon-ecommerce_cart_cloud:before {
  content: "\e9ef";
}

.icon-ecommerce_cart_content:before {
  content: "\e9f0";
}

.icon-ecommerce_cart_download:before {
  content: "\e9f1";
}

.icon-ecommerce_cart_minus:before {
  content: "\e9f2";
}

.icon-ecommerce_cart_plus:before {
  content: "\e9f3";
}

.icon-ecommerce_cart_refresh:before {
  content: "\e9f4";
}

.icon-ecommerce_cart_remove:before {
  content: "\e9f5";
}

.icon-ecommerce_cart_search:before {
  content: "\e9f6";
}

.icon-ecommerce_cart_upload:before {
  content: "\e9f7";
}

.icon-ecommerce_cart:before {
  content: "\e9f8";
}

.icon-ecommerce_cent:before {
  content: "\e9f9";
}

.icon-ecommerce_colon:before {
  content: "\e9fa";
}

.icon-ecommerce_creditcard:before {
  content: "\e9fb";
}

.icon-ecommerce_diamond:before {
  content: "\e9fc";
}

.icon-ecommerce_dollar:before {
  content: "\e9fd";
}

.icon-ecommerce_euro:before {
  content: "\e9fe";
}

.icon-ecommerce_franc:before {
  content: "\e9ff";
}

.icon-ecommerce_gift:before {
  content: "\ea00";
}

.icon-ecommerce_graph_decrease:before {
  content: "\ea01";
}

.icon-ecommerce_graph_increase:before {
  content: "\ea02";
}

.icon-ecommerce_graph1:before {
  content: "\ea03";
}

.icon-ecommerce_graph2:before {
  content: "\ea04";
}

.icon-ecommerce_graph3:before {
  content: "\ea05";
}

.icon-ecommerce_guarani:before {
  content: "\ea06";
}

.icon-ecommerce_kips:before {
  content: "\ea07";
}

.icon-ecommerce_lira:before {
  content: "\ea08";
}

.icon-ecommerce_megaphone:before {
  content: "\ea09";
}

.icon-ecommerce_money:before {
  content: "\ea0a";
}

.icon-ecommerce_naira:before {
  content: "\ea0b";
}

.icon-ecommerce_pesos:before {
  content: "\ea0c";
}

.icon-ecommerce_pound:before {
  content: "\ea0d";
}

.icon-ecommerce_receipt_bath:before {
  content: "\ea0e";
}

.icon-ecommerce_receipt_cent:before {
  content: "\ea0f";
}

.icon-ecommerce_receipt_dollar:before {
  content: "\ea10";
}

.icon-ecommerce_receipt_euro:before {
  content: "\ea11";
}

.icon-ecommerce_receipt_franc:before {
  content: "\ea12";
}

.icon-ecommerce_receipt_guarani:before {
  content: "\ea13";
}

.icon-ecommerce_receipt_kips:before {
  content: "\ea14";
}

.icon-ecommerce_receipt_lira:before {
  content: "\ea15";
}

.icon-ecommerce_receipt_naira:before {
  content: "\ea16";
}

.icon-ecommerce_receipt_pesos:before {
  content: "\ea17";
}

.icon-ecommerce_receipt_pound:before {
  content: "\ea18";
}

.icon-ecommerce_receipt_rublo:before {
  content: "\ea19";
}

.icon-ecommerce_receipt_rupee:before {
  content: "\ea1a";
}

.icon-ecommerce_receipt_tugrik:before {
  content: "\ea1b";
}

.icon-ecommerce_receipt_won:before {
  content: "\ea1c";
}

.icon-ecommerce_receipt_yen:before {
  content: "\ea1d";
}

.icon-ecommerce_receipt_yen2:before {
  content: "\ea1e";
}

.icon-ecommerce_receipt:before {
  content: "\ea1f";
}

.icon-ecommerce_recept_colon:before {
  content: "\ea20";
}

.icon-ecommerce_rublo:before {
  content: "\ea21";
}

.icon-ecommerce_rupee:before {
  content: "\ea22";
}

.icon-ecommerce_safe:before {
  content: "\ea23";
}

.icon-ecommerce_sale:before {
  content: "\ea24";
}

.icon-ecommerce_sales:before {
  content: "\ea25";
}

.icon-ecommerce_ticket:before {
  content: "\ea26";
}

.icon-ecommerce_tugriks:before {
  content: "\ea27";
}

.icon-ecommerce_wallet:before {
  content: "\ea28";
}

.icon-ecommerce_won:before {
  content: "\ea29";
}

.icon-ecommerce_yen:before {
  content: "\ea2a";
}

.icon-ecommerce_yen2:before {
  content: "\ea2b";
}

.icon-basic_elaboration_bookmark_checck:before {
  content: "\ea2c";
}

.icon-basic_elaboration_bookmark_minus:before {
  content: "\ea2d";
}

.icon-basic_elaboration_bookmark_plus:before {
  content: "\ea2e";
}

.icon-basic_elaboration_bookmark_remove:before {
  content: "\ea2f";
}

.icon-basic_elaboration_briefcase_check:before {
  content: "\ea30";
}

.icon-basic_elaboration_briefcase_download:before {
  content: "\ea31";
}

.icon-basic_elaboration_briefcase_flagged:before {
  content: "\ea32";
}

.icon-basic_elaboration_briefcase_minus:before {
  content: "\ea33";
}

.icon-basic_elaboration_briefcase_plus:before {
  content: "\ea34";
}

.icon-basic_elaboration_briefcase_refresh:before {
  content: "\ea35";
}

.icon-basic_elaboration_briefcase_remove:before {
  content: "\ea36";
}

.icon-basic_elaboration_briefcase_search:before {
  content: "\ea37";
}

.icon-basic_elaboration_briefcase_star:before {
  content: "\ea38";
}

.icon-basic_elaboration_briefcase_upload:before {
  content: "\ea39";
}

.icon-basic_elaboration_browser_check:before {
  content: "\ea3a";
}

.icon-basic_elaboration_browser_download:before {
  content: "\ea3b";
}

.icon-basic_elaboration_browser_minus:before {
  content: "\ea3c";
}

.icon-basic_elaboration_browser_plus:before {
  content: "\ea3d";
}

.icon-basic_elaboration_browser_refresh:before {
  content: "\ea3e";
}

.icon-basic_elaboration_browser_remove:before {
  content: "\ea3f";
}

.icon-basic_elaboration_browser_search:before {
  content: "\ea40";
}

.icon-basic_elaboration_browser_star:before {
  content: "\ea41";
}

.icon-basic_elaboration_browser_upload:before {
  content: "\ea42";
}

.icon-basic_elaboration_calendar_check:before {
  content: "\ea43";
}

.icon-basic_elaboration_calendar_cloud:before {
  content: "\ea44";
}

.icon-basic_elaboration_calendar_download:before {
  content: "\ea45";
}

.icon-basic_elaboration_calendar_empty:before {
  content: "\ea46";
}

.icon-basic_elaboration_calendar_flagged:before {
  content: "\ea47";
}

.icon-basic_elaboration_calendar_heart:before {
  content: "\ea48";
}

.icon-basic_elaboration_calendar_minus:before {
  content: "\ea49";
}

.icon-basic_elaboration_calendar_next:before {
  content: "\ea4a";
}

.icon-basic_elaboration_calendar_noaccess:before {
  content: "\ea4b";
}

.icon-basic_elaboration_calendar_pencil:before {
  content: "\ea4c";
}

.icon-basic_elaboration_calendar_plus:before {
  content: "\ea4d";
}

.icon-basic_elaboration_calendar_previous:before {
  content: "\ea4e";
}

.icon-basic_elaboration_calendar_refresh:before {
  content: "\ea4f";
}

.icon-basic_elaboration_calendar_remove:before {
  content: "\ea50";
}

.icon-basic_elaboration_calendar_search:before {
  content: "\ea51";
}

.icon-basic_elaboration_calendar_star:before {
  content: "\ea52";
}

.icon-basic_elaboration_calendar_upload:before {
  content: "\ea53";
}

.icon-basic_elaboration_cloud_check:before {
  content: "\ea54";
}

.icon-basic_elaboration_cloud_download:before {
  content: "\ea55";
}

.icon-basic_elaboration_cloud_minus:before {
  content: "\ea56";
}

.icon-basic_elaboration_cloud_noaccess:before {
  content: "\ea57";
}

.icon-basic_elaboration_cloud_plus:before {
  content: "\ea58";
}

.icon-basic_elaboration_cloud_refresh:before {
  content: "\ea59";
}

.icon-basic_elaboration_cloud_remove:before {
  content: "\ea5a";
}

.icon-basic_elaboration_cloud_search:before {
  content: "\ea5b";
}

.icon-basic_elaboration_cloud_upload:before {
  content: "\ea5c";
}

.icon-basic_elaboration_document_check:before {
  content: "\ea5d";
}

.icon-basic_elaboration_document_cloud:before {
  content: "\ea5e";
}

.icon-basic_elaboration_document_download:before {
  content: "\ea5f";
}

.icon-basic_elaboration_document_flagged:before {
  content: "\ea60";
}

.icon-basic_elaboration_document_graph:before {
  content: "\ea61";
}

.icon-basic_elaboration_document_heart:before {
  content: "\ea62";
}

.icon-basic_elaboration_document_minus:before {
  content: "\ea63";
}

.icon-basic_elaboration_document_next:before {
  content: "\ea64";
}

.icon-basic_elaboration_document_noaccess:before {
  content: "\ea65";
}

.icon-basic_elaboration_document_note:before {
  content: "\ea66";
}

.icon-basic_elaboration_document_pencil:before {
  content: "\ea67";
}

.icon-basic_elaboration_document_picture:before {
  content: "\ea68";
}

.icon-basic_elaboration_document_plus:before {
  content: "\ea69";
}

.icon-basic_elaboration_document_previous:before {
  content: "\ea6a";
}

.icon-basic_elaboration_document_refresh:before {
  content: "\ea6b";
}

.icon-basic_elaboration_document_remove:before {
  content: "\ea6c";
}

.icon-basic_elaboration_document_search:before {
  content: "\ea6d";
}

.icon-basic_elaboration_document_star:before {
  content: "\ea6e";
}

.icon-basic_elaboration_document_upload:before {
  content: "\ea6f";
}

.icon-basic_elaboration_folder_check:before {
  content: "\ea70";
}

.icon-basic_elaboration_folder_cloud:before {
  content: "\ea71";
}

.icon-basic_elaboration_folder_document:before {
  content: "\ea72";
}

.icon-basic_elaboration_folder_download:before {
  content: "\ea73";
}

.icon-basic_elaboration_folder_flagged:before {
  content: "\ea74";
}

.icon-basic_elaboration_folder_graph:before {
  content: "\ea75";
}

.icon-basic_elaboration_folder_heart:before {
  content: "\ea76";
}

.icon-basic_elaboration_folder_minus:before {
  content: "\ea77";
}

.icon-basic_elaboration_folder_next:before {
  content: "\ea78";
}

.icon-basic_elaboration_folder_noaccess:before {
  content: "\ea79";
}

.icon-basic_elaboration_folder_note:before {
  content: "\ea7a";
}

.icon-basic_elaboration_folder_pencil:before {
  content: "\ea7b";
}

.icon-basic_elaboration_folder_picture:before {
  content: "\ea7c";
}

.icon-basic_elaboration_folder_plus:before {
  content: "\ea7d";
}

.icon-basic_elaboration_folder_previous:before {
  content: "\ea7e";
}

.icon-basic_elaboration_folder_refresh:before {
  content: "\ea7f";
}

.icon-basic_elaboration_folder_remove:before {
  content: "\ea80";
}

.icon-basic_elaboration_folder_search:before {
  content: "\ea81";
}

.icon-basic_elaboration_folder_star:before {
  content: "\ea82";
}

.icon-basic_elaboration_folder_upload:before {
  content: "\ea83";
}

.icon-basic_elaboration_mail_check:before {
  content: "\ea84";
}

.icon-basic_elaboration_mail_cloud:before {
  content: "\ea85";
}

.icon-basic_elaboration_mail_document:before {
  content: "\ea86";
}

.icon-basic_elaboration_mail_download:before {
  content: "\ea87";
}

.icon-basic_elaboration_mail_flagged:before {
  content: "\ea88";
}

.icon-basic_elaboration_mail_heart:before {
  content: "\ea89";
}

.icon-basic_elaboration_mail_next:before {
  content: "\ea8a";
}

.icon-basic_elaboration_mail_noaccess:before {
  content: "\ea8b";
}

.icon-basic_elaboration_mail_note:before {
  content: "\ea8c";
}

.icon-basic_elaboration_mail_pencil:before {
  content: "\ea8d";
}

.icon-basic_elaboration_mail_picture:before {
  content: "\ea8e";
}

.icon-basic_elaboration_mail_previous:before {
  content: "\ea8f";
}

.icon-basic_elaboration_mail_refresh:before {
  content: "\ea90";
}

.icon-basic_elaboration_mail_remove:before {
  content: "\ea91";
}

.icon-basic_elaboration_mail_search:before {
  content: "\ea92";
}

.icon-basic_elaboration_mail_star:before {
  content: "\ea93";
}

.icon-basic_elaboration_mail_upload:before {
  content: "\ea94";
}

.icon-basic_elaboration_message_check:before {
  content: "\ea95";
}

.icon-basic_elaboration_message_dots:before {
  content: "\ea96";
}

.icon-basic_elaboration_message_happy:before {
  content: "\ea97";
}

.icon-basic_elaboration_message_heart:before {
  content: "\ea98";
}

.icon-basic_elaboration_message_minus:before {
  content: "\ea99";
}

.icon-basic_elaboration_message_note:before {
  content: "\ea9a";
}

.icon-basic_elaboration_message_plus:before {
  content: "\ea9b";
}

.icon-basic_elaboration_message_refresh:before {
  content: "\ea9c";
}

.icon-basic_elaboration_message_remove:before {
  content: "\ea9d";
}

.icon-basic_elaboration_message_sad:before {
  content: "\ea9e";
}

.icon-basic_elaboration_smartphone_cloud:before {
  content: "\ea9f";
}

.icon-basic_elaboration_smartphone_heart:before {
  content: "\eaa0";
}

.icon-basic_elaboration_smartphone_noaccess:before {
  content: "\eaa1";
}

.icon-basic_elaboration_smartphone_note:before {
  content: "\eaa2";
}

.icon-basic_elaboration_smartphone_pencil:before {
  content: "\eaa3";
}

.icon-basic_elaboration_smartphone_picture:before {
  content: "\eaa4";
}

.icon-basic_elaboration_smartphone_refresh:before {
  content: "\eaa5";
}

.icon-basic_elaboration_smartphone_search:before {
  content: "\eaa6";
}

.icon-basic_elaboration_tablet_cloud:before {
  content: "\eaa7";
}

.icon-basic_elaboration_tablet_heart:before {
  content: "\eaa8";
}

.icon-basic_elaboration_tablet_noaccess:before {
  content: "\eaa9";
}

.icon-basic_elaboration_tablet_note:before {
  content: "\eaaa";
}

.icon-basic_elaboration_tablet_pencil:before {
  content: "\eaab";
}

.icon-basic_elaboration_tablet_picture:before {
  content: "\eaac";
}

.icon-basic_elaboration_tablet_refresh:before {
  content: "\eaad";
}

.icon-basic_elaboration_tablet_search:before {
  content: "\eaae";
}

.icon-basic_elaboration_todolist_2:before {
  content: "\eaaf";
}

.icon-basic_elaboration_todolist_check:before {
  content: "\eab0";
}

.icon-basic_elaboration_todolist_cloud:before {
  content: "\eab1";
}

.icon-basic_elaboration_todolist_download:before {
  content: "\eab2";
}

.icon-basic_elaboration_todolist_flagged:before {
  content: "\eab3";
}

.icon-basic_elaboration_todolist_minus:before {
  content: "\eab4";
}

.icon-basic_elaboration_todolist_noaccess:before {
  content: "\eab5";
}

.icon-basic_elaboration_todolist_pencil:before {
  content: "\eab6";
}

.icon-basic_elaboration_todolist_plus:before {
  content: "\eab7";
}

.icon-basic_elaboration_todolist_refresh:before {
  content: "\eab8";
}

.icon-basic_elaboration_todolist_remove:before {
  content: "\eab9";
}

.icon-basic_elaboration_todolist_search:before {
  content: "\eaba";
}

.icon-basic_elaboration_todolist_star:before {
  content: "\eabb";
}

.icon-basic_elaboration_todolist_upload:before {
  content: "\eabc";
}

.icon-basic_accelerator:before {
  content: "\eabd";
}

.icon-basic_alarm:before {
  content: "\eabe";
}

.icon-basic_anchor:before {
  content: "\eabf";
}

.icon-basic_anticlockwise:before {
  content: "\eac0";
}

.icon-basic_archive_full:before {
  content: "\eac1";
}

.icon-basic_archive:before {
  content: "\eac2";
}

.icon-basic_ban:before {
  content: "\eac3";
}

.icon-basic_battery_charge:before {
  content: "\eac4";
}

.icon-basic_battery_empty:before {
  content: "\eac5";
}

.icon-basic_battery_full:before {
  content: "\eac6";
}

.icon-basic_battery_half:before {
  content: "\eac7";
}

.icon-basic_bolt:before {
  content: "\eac8";
}

.icon-basic_book_pen:before {
  content: "\eac9";
}

.icon-basic_book_pencil:before {
  content: "\eaca";
}

.icon-basic_book:before {
  content: "\eacb";
}

.icon-basic_bookmark:before {
  content: "\eacc";
}

.icon-basic_calculator:before {
  content: "\eacd";
}

.icon-basic_calendar:before {
  content: "\eace";
}

.icon-basic_cards_diamonds:before {
  content: "\eacf";
}

.icon-basic_cards_hearts:before {
  content: "\ead0";
}

.icon-basic_case:before {
  content: "\ead1";
}

.icon-basic_chronometer:before {
  content: "\ead2";
}

.icon-basic_clessidre:before {
  content: "\ead3";
}

.icon-basic_clock:before {
  content: "\ead4";
}

.icon-basic_clockwise:before {
  content: "\ead5";
}

.icon-basic_cloud:before {
  content: "\ead6";
}

.icon-basic_clubs:before {
  content: "\ead7";
}

.icon-basic_compass:before {
  content: "\ead8";
}

.icon-basic_cup:before {
  content: "\ead9";
}

.icon-basic_diamonds:before {
  content: "\eada";
}

.icon-basic_display:before {
  content: "\eadb";
}

.icon-basic_download:before {
  content: "\eadc";
}

.icon-basic_exclamation:before {
  content: "\eadd";
}

.icon-basic_eye_closed:before {
  content: "\eade";
}

.icon-basic_eye:before {
  content: "\eadf";
}

.icon-basic_female:before {
  content: "\eae0";
}

.icon-basic_flag1:before {
  content: "\eae1";
}

.icon-basic_flag2:before {
  content: "\eae2";
}

.icon-basic_floppydisk:before {
  content: "\eae3";
}

.icon-basic_folder_multiple:before {
  content: "\eae4";
}

.icon-basic_folder:before {
  content: "\eae5";
}

.icon-basic_gear:before {
  content: "\eae6";
}

.icon-basic_geolocalize-01:before {
  content: "\eae7";
}

.icon-basic_geolocalize-05:before {
  content: "\eae8";
}

.icon-basic_globe:before {
  content: "\eae9";
}

.icon-basic_gunsight:before {
  content: "\eaea";
}

.icon-basic_hammer:before {
  content: "\eaeb";
}

.icon-basic_headset:before {
  content: "\eaec";
}

.icon-basic_heart_broken:before {
  content: "\eaed";
}

.icon-basic_heart:before {
  content: "\eaee";
}

.icon-basic_helm:before {
  content: "\eaef";
}

.icon-basic_home:before {
  content: "\eaf0";
}

.icon-basic_info:before {
  content: "\eaf1";
}

.icon-basic_ipod:before {
  content: "\eaf2";
}

.icon-basic_joypad:before {
  content: "\eaf3";
}

.icon-basic_key:before {
  content: "\eaf4";
}

.icon-basic_keyboard:before {
  content: "\eaf5";
}

.icon-basic_laptop:before {
  content: "\eaf6";
}

.icon-basic_life_buoy:before {
  content: "\eaf7";
}

.icon-basic_lightbulb:before {
  content: "\eaf8";
}

.icon-basic_link:before {
  content: "\eaf9";
}

.icon-basic_lock_open:before {
  content: "\eafa";
}

.icon-basic_lock:before {
  content: "\eafb";
}

.icon-basic_magic_mouse:before {
  content: "\eafc";
}

.icon-basic_magnifier_minus:before {
  content: "\eafd";
}

.icon-basic_magnifier_plus:before {
  content: "\eafe";
}

.icon-basic_magnifier:before {
  content: "\eaff";
}

.icon-basic_mail_multiple:before {
  content: "\eb00";
}

.icon-basic_mail_open_text:before {
  content: "\eb01";
}

.icon-basic_mail_open:before {
  content: "\eb02";
}

.icon-basic_mail:before {
  content: "\eb03";
}

.icon-basic_male:before {
  content: "\eb04";
}

.icon-basic_map:before {
  content: "\eb05";
}

.icon-basic_message_multiple:before {
  content: "\eb06";
}

.icon-basic_message_txt:before {
  content: "\eb07";
}

.icon-basic_message:before {
  content: "\eb08";
}

.icon-basic_mixer2:before {
  content: "\eb09";
}

.icon-basic_mouse:before {
  content: "\eb0a";
}

.icon-basic_notebook_pen:before {
  content: "\eb0b";
}

.icon-basic_notebook_pencil:before {
  content: "\eb0c";
}

.icon-basic_notebook:before {
  content: "\eb0d";
}

.icon-basic_paperplane:before {
  content: "\eb0e";
}

.icon-basic_pencil_ruler_pen:before {
  content: "\eb0f";
}

.icon-basic_pencil_ruler:before {
  content: "\eb10";
}

.icon-basic_photo:before {
  content: "\eb11";
}

.icon-basic_picture_multiple:before {
  content: "\eb12";
}

.icon-basic_picture:before {
  content: "\eb13";
}

.icon-basic_pin1:before {
  content: "\eb14";
}

.icon-basic_pin2:before {
  content: "\eb15";
}

.icon-basic_postcard_multiple:before {
  content: "\eb16";
}

.icon-basic_postcard:before {
  content: "\eb17";
}

.icon-basic_printer:before {
  content: "\eb18";
}

.icon-basic_question:before {
  content: "\eb19";
}

.icon-basic_rss:before {
  content: "\eb1a";
}

.icon-basic_server_cloud:before {
  content: "\eb1b";
}

.icon-basic_server_download:before {
  content: "\eb1c";
}

.icon-basic_server_upload:before {
  content: "\eb1d";
}

.icon-basic_server:before {
  content: "\eb1e";
}

.icon-basic_server2:before {
  content: "\eb1f";
}

.icon-basic_settings:before {
  content: "\eb20";
}

.icon-basic_share:before {
  content: "\eb21";
}

.icon-basic_sheet_multiple:before {
  content: "\eb22";
}

.icon-basic_sheet_pen:before {
  content: "\eb23";
}

.icon-basic_sheet_pencil:before {
  content: "\eb24";
}

.icon-basic_sheet_txt:before {
  content: "\eb25";
}

.icon-basic_sheet:before {
  content: "\eb26";
}

.icon-basic_signs:before {
  content: "\eb27";
}

.icon-basic_smartphone:before {
  content: "\eb28";
}

.icon-basic_spades:before {
  content: "\eb29";
}

.icon-basic_spread_bookmark:before {
  content: "\eb2a";
}

.icon-basic_spread_text_bookmark:before {
  content: "\eb2b";
}

.icon-basic_spread_text:before {
  content: "\eb2c";
}

.icon-basic_spread:before {
  content: "\eb2d";
}

.icon-basic_star:before {
  content: "\eb2e";
}

.icon-basic_tablet:before {
  content: "\eb2f";
}

.icon-basic_target:before {
  content: "\eb30";
}

.icon-basic_todo_pen:before {
  content: "\eb31";
}

.icon-basic_todo_pencil:before {
  content: "\eb32";
}

.icon-basic_todo_txt:before {
  content: "\eb33";
}

.icon-basic_todo:before {
  content: "\eb34";
}

.icon-basic_todolist_pen:before {
  content: "\eb35";
}

.icon-basic_todolist_pencil:before {
  content: "\eb36";
}

.icon-basic_trashcan_full:before {
  content: "\eb37";
}

.icon-basic_trashcan_refresh:before {
  content: "\eb38";
}

.icon-basic_trashcan_remove:before {
  content: "\eb39";
}

.icon-basic_trashcan:before {
  content: "\eb3a";
}

.icon-basic_upload:before {
  content: "\eb3b";
}

.icon-basic_usb:before {
  content: "\eb3c";
}

.icon-basic_video:before {
  content: "\eb3d";
}

.icon-basic_watch:before {
  content: "\eb3e";
}

.icon-basic_webpage_img_txt:before {
  content: "\eb3f";
}

.icon-basic_webpage_multiple:before {
  content: "\eb40";
}

.icon-basic_webpage_txt:before {
  content: "\eb41";
}

.icon-basic_webpage:before {
  content: "\eb42";
}

.icon-basic_world:before {
  content: "\eb43";
}

.icon-arrows_anticlockwise_dashed:before {
  content: "\eb44";
}

.icon-arrows_anticlockwise:before {
  content: "\eb45";
}

.icon-arrows_button_down:before {
  content: "\eb46";
}

.icon-arrows_button_off:before {
  content: "\eb47";
}

.icon-arrows_button_on:before {
  content: "\eb48";
}

.icon-arrows_button_up:before {
  content: "\eb49";
}

.icon-arrows_check:before {
  content: "\eb4a";
}

.icon-arrows_circle_check:before {
  content: "\eb4b";
}

.icon-arrows_circle_down:before {
  content: "\eb4c";
}

.icon-arrows_circle_downleft:before {
  content: "\eb4d";
}

.icon-arrows_circle_downright:before {
  content: "\eb4e";
}

.icon-arrows_circle_left:before {
  content: "\eb4f";
}

.icon-arrows_circle_minus:before {
  content: "\eb50";
}

.icon-arrows_circle_plus:before {
  content: "\eb51";
}

.icon-arrows_circle_remove:before {
  content: "\eb52";
}

.icon-arrows_circle_right:before {
  content: "\eb53";
}

.icon-arrows_circle_up:before {
  content: "\eb54";
}

.icon-arrows_circle_upleft:before {
  content: "\eb55";
}

.icon-arrows_circle_upright:before {
  content: "\eb56";
}

.icon-arrows_clockwise_dashed:before {
  content: "\eb57";
}

.icon-arrows_clockwise:before {
  content: "\eb58";
}

.icon-arrows_compress:before {
  content: "\eb59";
}

.icon-arrows_deny:before {
  content: "\eb5a";
}

.icon-arrows_diagonal:before {
  content: "\eb5b";
}

.icon-arrows_diagonal2:before {
  content: "\eb5c";
}

.icon-arrows_down_double-34:before {
  content: "\eb5d";
}

.icon-arrows_down:before {
  content: "\eb5e";
}

.icon-arrows_downleft:before {
  content: "\eb5f";
}

.icon-arrows_downright:before {
  content: "\eb60";
}

.icon-arrows_drag_down_dashed:before {
  content: "\eb61";
}

.icon-arrows_drag_down:before {
  content: "\eb62";
}

.icon-arrows_drag_horiz:before {
  content: "\eb63";
}

.icon-arrows_drag_left_dashed:before {
  content: "\eb64";
}

.icon-arrows_drag_left:before {
  content: "\eb65";
}

.icon-arrows_drag_right_dashed:before {
  content: "\eb66";
}

.icon-arrows_drag_right:before {
  content: "\eb67";
}

.icon-arrows_drag_up_dashed:before {
  content: "\eb68";
}

.icon-arrows_drag_up:before {
  content: "\eb69";
}

.icon-arrows_drag_vert:before {
  content: "\eb6a";
}

.icon-arrows_exclamation:before {
  content: "\eb6b";
}

.icon-arrows_expand_diagonal1:before {
  content: "\eb6c";
}

.icon-arrows_expand_horizontal1:before {
  content: "\eb6d";
}

.icon-arrows_expand_vertical1:before {
  content: "\eb6e";
}

.icon-arrows_expand:before {
  content: "\eb6f";
}

.icon-arrows_fit_horizontal:before {
  content: "\eb70";
}

.icon-arrows_fit_vertical:before {
  content: "\eb71";
}

.icon-arrows_glide_horizontal:before {
  content: "\eb72";
}

.icon-arrows_glide_vertical:before {
  content: "\eb73";
}

.icon-arrows_glide:before {
  content: "\eb74";
}

.icon-arrows_hamburger-2:before {
  content: "\eb75";
}

.icon-arrows_hamburger1:before {
  content: "\eb76";
}

.icon-arrows_horizontal:before {
  content: "\eb77";
}

.icon-arrows_info:before {
  content: "\eb78";
}

.icon-arrows_keyboard_alt:before {
  content: "\eb79";
}

.icon-arrows_keyboard_cmd-29:before {
  content: "\eb7a";
}

.icon-arrows_keyboard_delete:before {
  content: "\eb7b";
}

.icon-arrows_keyboard_down-28:before {
  content: "\eb7c";
}

.icon-arrows_keyboard_left:before {
  content: "\eb7d";
}

.icon-arrows_keyboard_return:before {
  content: "\eb7e";
}

.icon-arrows_keyboard_right:before {
  content: "\eb7f";
}

.icon-arrows_keyboard_shift:before {
  content: "\eb80";
}

.icon-arrows_keyboard_tab:before {
  content: "\eb81";
}

.icon-arrows_keyboard_up:before {
  content: "\eb82";
}

.icon-arrows_left_double-32:before {
  content: "\eb83";
}

.icon-arrows_left:before {
  content: "\eb84";
}

.icon-arrows_minus:before {
  content: "\eb85";
}

.icon-arrows_move_bottom:before {
  content: "\eb86";
}

.icon-arrows_move_left:before {
  content: "\eb87";
}

.icon-arrows_move_right:before {
  content: "\eb88";
}

.icon-arrows_move_top:before {
  content: "\eb89";
}

.icon-arrows_move:before {
  content: "\eb8a";
}

.icon-arrows_move2:before {
  content: "\eb8b";
}

.icon-arrows_plus:before {
  content: "\eb8c";
}

.icon-arrows_question:before {
  content: "\eb8d";
}

.icon-arrows_remove:before {
  content: "\eb8e";
}

.icon-arrows_right_double-31:before {
  content: "\eb8f";
}

.icon-arrows_right:before {
  content: "\eb90";
}

.icon-arrows_rotate_anti_dashed:before {
  content: "\eb91";
}

.icon-arrows_rotate_anti:before {
  content: "\eb92";
}

.icon-arrows_rotate_dashed:before {
  content: "\eb93";
}

.icon-arrows_rotate:before {
  content: "\eb94";
}

.icon-arrows_shrink_diagonal1:before {
  content: "\eb95";
}

.icon-arrows_shrink_diagonal2:before {
  content: "\eb96";
}

.icon-arrows_shrink_horizonal2:before {
  content: "\eb97";
}

.icon-arrows_shrink_horizontal1:before {
  content: "\eb98";
}

.icon-arrows_shrink_vertical1:before {
  content: "\eb99";
}

.icon-arrows_shrink_vertical2:before {
  content: "\eb9a";
}

.icon-arrows_shrink:before {
  content: "\eb9b";
}

.icon-arrows_sign_down:before {
  content: "\eb9c";
}

.icon-arrows_sign_left:before {
  content: "\eb9d";
}

.icon-arrows_sign_right:before {
  content: "\eb9e";
}

.icon-arrows_sign_up:before {
  content: "\eb9f";
}

.icon-arrows_slide_down1:before {
  content: "\eba0";
}

.icon-arrows_slide_down2:before {
  content: "\eba1";
}

.icon-arrows_slide_left1:before {
  content: "\eba2";
}

.icon-arrows_slide_left2:before {
  content: "\eba3";
}

.icon-arrows_slide_right1:before {
  content: "\eba4";
}

.icon-arrows_slide_right2:before {
  content: "\eba5";
}

.icon-arrows_slide_up1:before {
  content: "\eba6";
}

.icon-arrows_slide_up2:before {
  content: "\eba7";
}

.icon-arrows_slim_down_dashed:before {
  content: "\eba8";
}

.icon-arrows_slim_down:before {
  content: "\eba9";
}

.icon-arrows_slim_left_dashed:before {
  content: "\ebaa";
}

.icon-arrows_slim_left:before {
  content: "\ebab";
}

.icon-arrows_slim_right_dashed:before {
  content: "\ebac";
}

.icon-arrows_slim_right:before {
  content: "\ebad";
}

.icon-arrows_slim_up_dashed:before {
  content: "\ebae";
}

.icon-arrows_slim_up:before {
  content: "\ebaf";
}

.icon-arrows_square_check:before {
  content: "\ebb0";
}

.icon-arrows_square_down:before {
  content: "\ebb1";
}

.icon-arrows_square_downleft:before {
  content: "\ebb2";
}

.icon-arrows_square_downright:before {
  content: "\ebb3";
}

.icon-arrows_square_left:before {
  content: "\ebb4";
}

.icon-arrows_square_minus:before {
  content: "\ebb5";
}

.icon-arrows_square_plus:before {
  content: "\ebb6";
}

.icon-arrows_square_remove:before {
  content: "\ebb7";
}

.icon-arrows_square_right:before {
  content: "\ebb8";
}

.icon-arrows_square_up:before {
  content: "\ebb9";
}

.icon-arrows_square_upleft:before {
  content: "\ebba";
}

.icon-arrows_square_upright:before {
  content: "\ebbb";
}

.icon-arrows_squares:before {
  content: "\ebbc";
}

.icon-arrows_stretch_diagonal1:before {
  content: "\ebbd";
}

.icon-arrows_stretch_diagonal2:before {
  content: "\ebbe";
}

.icon-arrows_stretch_diagonal3:before {
  content: "\ebbf";
}

.icon-arrows_stretch_diagonal4:before {
  content: "\ebc0";
}

.icon-arrows_stretch_horizontal1:before {
  content: "\ebc1";
}

.icon-arrows_stretch_horizontal2:before {
  content: "\ebc2";
}

.icon-arrows_stretch_vertical1:before {
  content: "\ebc3";
}

.icon-arrows_stretch_vertical2:before {
  content: "\ebc4";
}

.icon-arrows_switch_horizontal:before {
  content: "\ebc5";
}

.icon-arrows_switch_vertical:before {
  content: "\ebc6";
}

.icon-arrows_up_double-33:before {
  content: "\ebc7";
}

.icon-arrows_up:before {
  content: "\ebc8";
}

.icon-arrows_upleft:before {
  content: "\ebc9";
}

.icon-arrows_upright:before {
  content: "\ebca";
}

.icon-arrows_vertical:before {
  content: "\ebcb";
}

label {
  margin-bottom: 0.25rem;
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
  letter-spacing: 0.1em;
}

.form-control-minimal {
  background: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
.form-control-minimal::placeholder {
  color: #999;
}
.form-control-minimal:focus {
  background: transparent;
}

.text-white .form-control {
  border-color: transparent;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.text-white .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.text-white .form-control:focus {
  border-color: #fff;
}

.custom-control-label::before {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border: 1px solid #e5e5e5;
  background: transparent;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #6E9EEB;
  background-color: #6E9EEB;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #6E9EEB;
  background-color: #6E9EEB;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.text-white .custom-control-label::before {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
}

.text-white .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #fff;
  background-color: #fff;
}

select {
  opacity: 0 !important;
}

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-radius: 0.25rem;
  border: 1px solid #e5e5e5;
  background-color: transparent;
  position: relative;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  padding: 0.9375rem 0.9375rem;
  line-height: 1.5;
  color: #151515;
  user-select: none;
}
.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3.375rem;
  text-align: center;
  font: 0/0 a;
  *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
}
.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #bbb;
  border-bottom: none;
}

.selectric-form-control-sm .selectric .label {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.5rem;
}

.selectric-form-control-sm .selectric-items li {
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
}
.selectric-form-control-sm .selectric-items li:before {
  margin-top: -0.25rem;
  width: 0.5rem;
  height: 0.5rem;
}

.selectric-form-control-lg .selectric .label {
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 1.1rem;
}

.selectric-form-control-lg .selectric-items li {
  padding: 1.1rem 1.1rem;
  font-size: 1.125rem;
}

.selectric-open {
  z-index: 9999;
}
.selectric-open .selectric {
  border-color: transparent;
}
.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
  display: none;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  border-radius: 0.25rem;
  display: none;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  border: 0;
  z-index: 1;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 0;
}
.selectric-items ul,
.selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1.5;
}
.selectric-items li {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  display: block;
  padding: 0.9375rem 0.9375rem;
  line-height: 1.5;
  font-size: 1rem;
  color: #151515;
  cursor: pointer;
}
.selectric-items li:before {
  position: absolute;
  top: 50%;
  right: 0.9375rem;
  margin-top: -0.4rem;
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: #e5e5e5;
}
.selectric-items li:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.selectric-items li:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.selectric-items li:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}
.selectric-items li.selected:before,
.selectric-items li.selected:hover:before {
  background: #6E9EEB;
}
.selectric-items li:hover {
  background: #f5f5f5;
}
.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: none;
  color: #444;
}
.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}
.selectric-items .selectric-group li {
  padding-left: 25px;
}

.selectric-form-control-minimal .selectric {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.selectric-form-control-minimal .selectric-items {
  border: 0;
}

.text-white .selectric {
  border-color: transparent;
  background: rgba(255, 255, 255, 0.1);
}

.text-white .selectric-items li {
  color: #151515;
}
.text-white .selectric-items li.selected,
.text-white .selectric-items li.selected:hover {
  color: #6E9EEB;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  margin-bottom: 0.5em;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child {
  margin-bottom: 0;
}

p:last-child,
span:last-child,
b:last-child,
ul:last-child,
ol:last-child,
blockquote:last-child {
  margin-bottom: 0;
}

figure {
  margin: 0;
}

strong,
b {
  color: #000;
  font-weight: 700;
}

code {
  display: inline-block;
  margin-bottom: 1rem;
}

p {
  line-height: 1.75em;
  color: #999;
}

small {
  color: #999;
}

a:hover {
  text-decoration: none;
}

hr {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

blockquote p {
  color: #000;
}

.text-decorated {
  position: relative;
}
.text-decorated::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0.6em;
  width: 5rem;
  height: 1px;
  background: #000;
  -webkit-transform: translate(-140%, 0);
  -moz-transform: translate(-140%, 0);
  transform: translate(-140%, 0);
}
.text-decorated[class*="padding"] {
  padding-left: 6.875rem;
}
.text-decorated[class*="padding"]::after {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
}

.text-white .text-decorated::after {
  background: #fff;
}

.gallery-thumbs .swiper-slide h5 h1,
.user-caption h1,
.user-panel h1,
.tile .tile-content h1,
.gallery-thumbs .swiper-slide h5 h2,
.user-caption h2,
.user-panel h2,
.tile .tile-content h2,
.gallery-thumbs .swiper-slide h5 h3,
.user-caption h3,
.user-panel h3,
.tile .tile-content h3,
.gallery-thumbs .swiper-slide h5 h4,
.user-caption h4,
.user-panel h4,
.tile .tile-content h4,
.gallery-thumbs .swiper-slide h5 h5,
.user-caption h5,
.user-panel h5,
.tile .tile-content h5,
.gallery-thumbs .swiper-slide h5 .h1,
.user-caption .h1,
.user-panel .h1,
.tile .tile-content .h1,
.gallery-thumbs .swiper-slide h5 .h2,
.user-caption .h2,
.user-panel .h2,
.tile .tile-content .h2,
.gallery-thumbs .swiper-slide h5 .h3,
.user-caption .h3,
.user-panel .h3,
.tile .tile-content .h3,
.gallery-thumbs .swiper-slide h5 .h4,
.user-caption .h4,
.user-panel .h4,
.tile .tile-content .h4,
.gallery-thumbs .swiper-slide h5 .h5,
.user-caption .h5,
.user-panel .h5,
.tile .tile-content .h5,
.gallery-thumbs .swiper-slide h5 p,
.user-caption p,
.user-panel p,
.tile .tile-content p,
.gallery-thumbs .swiper-slide h5 span,
.user-caption span,
.user-panel span,
.tile .tile-content span,
.gallery-thumbs .swiper-slide h5 b,
.user-caption b,
.user-panel b,
.tile .tile-content b,
.gallery-thumbs .swiper-slide h5 i,
.user-caption i,
.user-panel i,
.tile .tile-content i {
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
}

.text-shadow {
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
}

.link {
  display: inline-block;
  position: relative;
}
.link:hover::before,
.link:focus::before {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  top: calc(100% + 2px);
  opacity: 1;
}
.link::before {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  content: "";
  position: absolute;
  top: 100%;
  display: block;
  width: calc(100% - 2px);
  height: 1px;
  background: currentColor;
  opacity: 0;
}

.eyebrow,
.footer-title,
.entry time,
.widget-title,
.card-price-3 .card-title {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.error-text {
  font-size: 10rem;
  font-weight: 700;
  line-height: 10rem;
  margin-bottom: 0.25em;
}

.letter-spacing {
  letter-spacing: 0.05em;
}

@media (max-width: 1199.98px) {
  .display-1 {
    font-size: 3.75rem;
  }
  .display-2 {
    font-size: 3.28125rem;
  }
  .display-3 {
    font-size: 2.8125rem;
  }
  .display-4 {
    font-size: 2.57813rem;
  }
  h1,
  .h1 {
    font-size: 2.34375rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
  }
  h3,
  .h3 {
    font-size: 1.5rem;
  }
  h4,
  .h4 {
    font-size: 1.3125rem;
  }
  h5,
  .h5 {
    font-size: 1.125rem;
  }
}

header {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid transparent;
}
@media (min-width: 1200px) {
  header[class*="-dark"] {
    border-color: rgba(0, 0, 0, 0.1);
  }
  header[class*="-dark"] .navbar-logo-light {
    display: block !important;
  }
  header[class*="-light"] {
    border-color: #e5e5e5;
  }
  header[class*="-light"] .navbar-logo-dark {
    display: block !important;
  }
}
@media (max-width: 1199.98px) {
  header {
    border-radius: 0.25rem;
    margin: 15px;
    width: calc(100% - 30px);
    background: #fff;
  }
}

@media (min-width: 1200px) {
  .header-sticky {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    position: fixed;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
  }
  .header-sticky[class*="-unpinned"] {
    -webkit-transform: translate(0, -105%);
    -moz-transform: translate(0, -105%);
    transform: translate(0, -105%);
  }
}

@media (min-width: 1200px) {
  .header-dark[class*="-not-top"][class*="-pinned"],
  .header-light[class*="-not-top"][class*="-pinned"] {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 0;
    background: #fff;
  }
  .header-dark[class*="-not-top"][class*="-pinned"] .navbar-logo,
  .header-light[class*="-not-top"][class*="-pinned"] .navbar-logo {
    display: none !important;
  }
  .header-dark[class*="-not-top"][class*="-pinned"]
    .navbar-logo.navbar-logo-dark,
  .header-light[class*="-not-top"][class*="-pinned"]
    .navbar-logo.navbar-logo-dark {
    display: block !important;
  }
  .header-dark[class*="-not-top"][class*="-pinned"]::before,
  .header-light[class*="-not-top"][class*="-pinned"]::before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
  .header-dark[class*="-not-top"][class*="-pinned"]
    .navbar-nav
    .nav-item
    .nav-link,
  .header-light[class*="-not-top"][class*="-pinned"]
    .navbar-nav
    .nav-item
    .nav-link {
    color: #888;
  }
  .header-dark[class*="-not-top"][class*="-pinned"] .navbar-brand,
  .header-light[class*="-not-top"][class*="-pinned"] .navbar-brand {
    color: #000;
  }
}

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class*="container"] + [class*="container"] {
  margin-top: 3.125rem;
}

.container-full {
  overflow: hidden;
}

@media (min-width: 1200px) {
  .container-fluid {
    max-width: 1370px;
  }
}

@media (min-width: 1600px) {
  .container-fluid {
    max-width: 1770px;
  }
}

.pattern::before,
.pattern::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  content: "";
  display: block;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10rem;
}

.pattern::before {
  width: 80vw;
  height: 80vw;
  bottom: -40vw;
  left: 25vw;
}

.pattern::after {
  width: 60vw;
  height: 60vw;
  bottom: -30vw;
  left: 80vw;
}

.pattern.pattern-dark::before,
.pattern.pattern-dark::after {
  background: rgba(21, 21, 21, 0.05);
}

.pattern > * {
  position: relative;
  z-index: 10;
}

@media (max-width: 767.98px) {
  .pattern::before {
    width: 160vw;
    height: 160vw;
    bottom: -80vw;
  }
  .pattern::after {
    width: 120vw;
    height: 120vw;
    bottom: -60vw;
    left: 80vw;
  }
}

body > section,
body > footer {
  position: relative;
  margin: 0 auto;
  padding: 6.25rem 0;
  overflow: hidden;
}

body [class*="container"] .row:not(:last-child) {
  margin-bottom: 3.125rem;
}

@media (max-width: 767.98px) {
  body > section,
  body > footer {
    padding: 3.125rem 0;
  }
  body [class*="container"] .row:not(:last-child) {
    margin-bottom: 2.08333rem;
  }
}

.layers {
  position: relative;
  margin: 0 auto;
}

.layers-foreground {
  position: relative;
  z-index: 50;
}
@media (max-width: 767.98px) {
  .layers-foreground {
    background: rgba(72, 41, 178, 0.5);
  }
}

.layers-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
}
.layers-background > .container,
.layers-background > .container-fluid,
.layers-background > .container-full {
  height: 100%;
}
.layers-background > .container > .row,
.layers-background > .container-fluid > .row,
.layers-background > .container-full > .row {
  height: 100%;
}

.layers-background-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #6E9EEB;
  z-index: 45;
}

.split-background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.split-background .container,
.split-background .container-fluid,
.split-background .container-full {
  height: 100%;
}
.split-background .container .row,
.split-background .container-fluid .row,
.split-background .container-full .row {
  height: 100%;
}

@media (max-width: 767.98px) {
  .split-background {
    position: static;
  }
}

.overlay::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  opacity: 0.8;
  z-index: 10;
}

section[class*="large"] {
  padding: 9.375rem 0;
}

section[class*="small"] {
  padding: 4.16667rem 0;
}

[class*="separator"] {
  border-width: 0;
  border-color: #e5e5e5;
  border-style: solid;
}
[class*="separator"][class*="-top"] {
  border-top-width: 1px;
}
[class*="separator"][class*="-right"] {
  border-right-width: 1px;
}
[class*="separator"][class*="-bottom"] {
  border-bottom-width: 1px;
}
[class*="separator"][class*="-left"] {
  border-left-width: 1px;
}

.text-white [class*="separator"],
.text-white[class*="separator"] {
  border-color: rgba(255, 255, 255, 0.1);
}

.section-decorated-top {
  padding-top: 9.375rem;
}

.section-decorated-bottom {
  padding-bottom: 9.375rem;
}

.decorated-top {
  position: absolute;
  top: -1px;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 20;
  height: 3.125rem;
  transform: translateZ(0);
}
.decorated-top svg {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 100%;
}
.decorated-top svg > * {
  fill: currentColor;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}
@media (max-width: 767.98px) {
  .decorated-top {
    height: 20px;
  }
}

.decorated-bottom {
  position: absolute;
  top: auto;
  right: 0;
  bottom: -1px;
  left: 0;
  z-index: 20;
  height: 3.125rem;
  transform: translateZ(0);
}
.decorated-bottom svg {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 100%;
}
.decorated-bottom svg > * {
  fill: currentColor;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}
@media (max-width: 767.98px) {
  .decorated-bottom {
    height: 20px;
  }
}

.decorated-sm {
  height: 20px;
}

.viewport {
  position: relative;
  overflow: hidden;
}

.circle {
  position: absolute;
  top: 40%;
  width: 20vw;
  height: 20vw;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.square {
  position: absolute;
  top: 80%;
  left: 75vw;
  width: 15vw;
  height: 15vw;
  background: rgba(0, 0, 0, 0.1);
}

@media (min-width: 1600px) {
  body > section,
  body > footer {
    padding: 7.5rem 0;
  }
}

@media (max-width: 1199.98px) {
  .container > .row > *:not(:last-child),
  .container-fluid > .row > *:not(:last-child),
  .container-full > .row > *:not(:last-child) {
    margin-bottom: 1.875rem;
  }
}

.logo-sm {
  max-height: 2rem;
}

img {
  max-width: 100%;
}

.anchor {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-radius: 0.25rem;
  position: relative;
  display: block;
  background-color: #e5e5e5;
}
.anchor i {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.5rem;
  color: #000;
}
.anchor:hover {
  background-color: #6E9EEB;
}
.anchor:hover i {
  color: #fff;
}

#typed-strings {
  display: none;
}

.socials {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  display: inline-block;
  width: auto;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.socials li {
  float: left;
}
.socials a {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  display: block;
  line-height: 1em;
  width: 1em;
  padding: 1rem;
  border-radius: 50%;
}
.socials a:hover {
  background: #f5f5f584;
}
.socials[class*="vertical"] > li {
  float: none;
}
.socials[class*="-sm"] a {
  padding: 0.75rem;
}
.socials[class*="bordered"] a,
.socials[class*="boxed"] a {
  border-radius: 0;
}

.text-white .socials a,
.user-caption .socials a {
  color: #fff;
}
.text-white .socials a:hover,
.user-caption .socials a:hover {
  background: rgba(255, 255, 255, 0.2);
}

.step {
  position: relative;
  padding: 5.625rem 20% 0 0;
}
.step::before {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  content: counter(owlItem, decimal);
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 3.75rem;
  height: 3.75rem;
  line-height: calc(3.75rem - 0.25rem);
  text-align: center;
  border: 2px solid #e5e5e5;
  z-index: 20;
  border-radius: 50%;
  font-size: 1.5rem;
}
.step:hover::before,
.step.step-highlight::before {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  transform: scale(1.25);
  background: #6E9EEB;
  border-color: transparent;
  color: #fff;
}

.owl-item:not(:last-child) .step::after {
  content: "";
  display: block;
  position: absolute;
  top: 29px;
  left: 3.75rem;
  width: calc(100% - 40px);
  height: 2px;
  background: #e5e5e5;
  z-index: 10;
}

.step-title {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.025em;
}

.meteo {
  display: inline-block;
  padding: 0.5rem 1rem;
}
.meteo > * {
  vertical-align: middle;
}
.meteo .svg-icon {
  font-size: 40px;
  padding-right: 0.5rem;
}
.meteo span {
  font-size: 1.125rem;
  color: #fff !important;
}
.meteo span b {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}

.number {
  padding: 2.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.875rem;
}
.number b {
  display: block;
  font-size: 3.125rem;
  font-weight: 400;
}

.phone {
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  display: inline-block;
  padding: 1rem 1rem 1rem 5rem;
  color: #000;
  letter-spacing: 0.05em;
}
.phone::before {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3.625rem;
  content: "\ec73";
  text-align: center;
  line-height: 3.625rem;
  font-family: "icomoon";
  font-size: 20px;
  background-color: #e5e5e5;
}
.phone:hover {
  border-color: #6E9EEB !important;
}
.phone:hover::before {
  background-color: #6E9EEB !important;
  color: #fff;
}

.text-white .phone::before {
  background: rgba(255, 255, 255, 0.2);
}

.sidebar > *:not(:first-child) {
  margin-top: 30px;
}

footer {
  padding: 0 !important;
}
footer > [class*="container"] {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}
footer > [class*="container"] + [class*="container"] {
  margin-top: 0;
}
footer .nav {
  display: inline;
}
footer .nav li {
  display: inline-block;
}

.overlay {
  background: rgba(0, 0, 0, 0.1);
}

.copyright-text {
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.overlay-active {
  overflow: hidden;
}

.burger {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  z-index: 100;
}
.burger span {
  position: relative;
  margin-top: 6px;
  margin-bottom: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -1.5px;
}
.burger span,
.burger span::before,
.burger span::after {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #000;
  outline: 1px solid transparent;
  -webkit-transition-property: background-color;
  -moz-transition-property: background-color;
  -o-transition-property: background-color;
  transition-property: background-color;
  transition-property: background-color, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.burger span::before,
.burger span::after {
  position: absolute;
  content: "";
}
.burger span::before {
  top: -6px;
}
.burger span::after {
  top: 6px;
}
.burger.clicked span {
  background-color: transparent;
}
.burger.clicked span::before {
  -webkit-transform: translateY(6px) rotate(45deg);
  -moz-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}
.burger.clicked span::after {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -moz-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}
.burger.clicked span:before,
.burger.clicked span:after {
  background-color: #000;
}
.burger.burger-dot {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 50%;
}
.burger:hover {
  cursor: pointer;
}

.overlay-menu {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate(0, 100%);
  -moz-transform: translate(0, 100%);
  transform: translate(0, 100%);
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 90;
  overflow: hidden;
}
.overlay-menu.opened {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
  visibility: visible;
}

.overlay-nav {
  list-style: none;
  padding: 0;
}
.overlay-nav a {
  font-size: 30px;
  line-height: 2em;
}

.wrapper {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}
.wrapper.push {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}

.text-white,
.user-panel,
.tile .tile-content {
  color: #fff;
}
.text-white h1,
.user-panel h1,
.tile .tile-content h1,
.text-white h2,
.user-panel h2,
.tile .tile-content h2,
.text-white h3,
.user-panel h3,
.tile .tile-content h3,
.text-white h4,
.user-panel h4,
.tile .tile-content h4,
.text-white h5,
.user-panel h5,
.tile .tile-content h5,
.text-white h6,
.user-panel h6,
.tile .tile-content h6,
.text-white .h1,
.user-panel .h1,
.tile .tile-content .h1,
.text-white .h2,
.user-panel .h2,
.tile .tile-content .h2,
.text-white .h3,
.user-panel .h3,
.tile .tile-content .h3,
.text-white .h4,
.user-panel .h4,
.tile .tile-content .h4,
.text-white .h5,
.user-panel .h5,
.tile .tile-content .h5,
.text-white .h6,
.user-panel .h6,
.tile .tile-content .h6,
.text-white strong,
.user-panel strong,
.tile .tile-content strong,
.text-white b,
.user-panel b,
.tile .tile-content b,
.text-white li,
.user-panel li,
.tile .tile-content li,
.text-white a:not(.btn),
.user-panel a:not(.btn),
.tile .tile-content a:not(.btn) {
  color: #fff;
}
.text-white p,
.user-panel p,
.tile .tile-content p,
.text-white span:not(.badge),
.user-panel span:not(.badge),
.tile .tile-content span:not(.badge) {
  color: rgba(255, 255, 255, 0.5);
}
.text-white hr,
.user-panel hr,
.tile .tile-content hr {
  border-color: rgba(255, 255, 255, 0.2);
}

.revert {
  color: #151515;
}
.revert h1,
.revert h2,
.revert h3,
.revert h4,
.revert h5,
.revert h6,
.revert .h1,
.revert .h2,
.revert .h3,
.revert .h4,
.revert .h5,
.revert .h6,
.revert strong,
.revert b,
.revert li {
  color: #000;
}
.revert p,
.revert span {
  color: #151515;
}
.revert hr {
  border-color: #e5e5e5;
}

.text-white .lead {
  color: rgba(255, 255, 255, 0.75);
}

.text-white .text-muted {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.2);
}

.text-white .owl-dots .owl-dot:hover span {
  background: rgba(255, 255, 255, 0.4);
}

.text-white .owl-dots .owl-dot.active span {
  background: #fff;
}

.text-white .nav .lavalamp-object {
  border-color: #fff;
}

.text-white .nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.text-white .nav .nav-link.active,
.text-white .nav .nav-link:hover {
  color: #fff;
}

.text-white .nav.nav--line {
  border-color: rgba(255, 255, 255, 0.2);
}

.text-white .step::before {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.text-white .step:hover::before,
.text-white .step.step-highlight::before {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.text-white .step::after {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.text-white .svg-icon {
  color: #fff;
}

.table {
  table-layout: fixed;
}
.table th {
  font-weight: 400;
  color: #151515;
}
.table td {
  color: #000;
}
.table th,
.table td {
  padding: 1.875rem;
}
.table th.highlight,
.table td.highlight {
  position: relative;
  color: #6E9EEB;
}
.table th.highlight:before,
.table td.highlight:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background: #6E9EEB;
}
.table thead th {
  border-bottom-width: 1px;
}

.table-lined th,
.table-lined td {
  border-top: 0;
}

.table-lined thead th {
  border-bottom: 0;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.table-lined tbody tr:nth-child(2n + 1) > * {
  background: #e5e5e5;
}
.table-lined tbody tr:nth-child(2n + 1) > *:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.table-lined tbody tr:nth-child(2n + 1) > *:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.text-white .table {
  border-color: rgba(255, 255, 255, 0.2);
}
.text-white .table th {
  color: rgba(255, 255, 255, 0.5);
}
.text-white .table td {
  color: #fff;
}
.text-white .table th,
.text-white .table td {
  border-color: rgba(255, 255, 255, 0.2);
}
.text-white .table th.highlight,
.text-white .table td.highlight {
  color: #fff;
}
.text-white .table th.highlight:before,
.text-white .table td.highlight:before {
  background: #fff;
}
.text-white .table[class*="lined"] tbody tr:nth-child(2n + 1) > * {
  background: rgba(0, 0, 0, 0.2);
}
.text-white .table[class*="lined"] tbody {
  font-size: 1.125rem;
}
.text-white .table[class*="lined"] tbody th {
  color: #fff;
}

@media (max-width: 1199.98px) {
  .table {
    table-layout: auto;
  }
}

.navbar {
  padding-left: 0;
  padding-right: 0;
}

.navbar-brand {
  height: 46px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 3rem;
}
.navbar-brand.navbar-brand-sm {
  height: 36px;
}
.navbar-brand.navbar-brand-lg {
  height: 56px;
}
.navbar-brand .navbar-logo {
  width: auto;
  max-height: 100%;
  display: none;
}

.navbar-nav .nav-item + .nav-item {
  margin-left: 1rem;
}

.navbar-nav .nav-link {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  font-size: 0.875rem;
}

.navbar-toggler {
  padding: 0 !important;
  border: 0;
}

@media (max-width: 1199.98px) {
  .navbar-logo-dark {
    display: block !important;
  }
  .navbar .navbar-toggler {
    padding: 0.5rem;
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
  .navbar .nav-item + .nav-item {
    margin: 0;
  }
  .navbar .nav-item .nav-link {
    padding: 5px 0;
    color: #151515;
  }
  .navbar .nav-item .nav-link:focus,
  .navbar .nav-item .nav-link:hover {
    color: #000;
  }
  .navbar .nav-item.show .nav-link,
  .navbar .nav-item.active .nav-link {
    color: #000;
  }
  .navbar .banner {
    display: none;
  }
  .navbar-nav .nav-item {
    width: 100%;
    padding: 0.5rem 0;
  }
  .navbar-nav .nav-item .btn {
    display: block;
    background-color: #6E9EEB;
    color: #fff;
  }
}

.overlay-active .headroom {
  max-height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.hero[class*="-with-header"] {
  padding-top: 12.5rem;
}

.box > * {
  border-radius: 0.25rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 1.875rem;
}

.box b {
  display: block;
  margin-bottom: 0.5rem;
  line-height: 1.25;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}

.box span {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: inherit;
}

.text-white .box b {
  color: #fff;
}

.gradient-bg > * {
  position: relative;
  z-index: 40;
}

.gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}
.image[class*="-overlay"]::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(0, 0, 0, 0.25);
}
.image[class*="-overlay-dark"]::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(0, 0, 0, 0.4);
}
.image[class*="-gradient-vertical"] {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(black),
    to(rgba(0, 0, 0, 0))
  );
}
.image[class*="-gradient-horizontal"] {
  -webkit-mask-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(black),
    to(rgba(0, 0, 0, 0.5))
  );
}
.image[class*="-gradient-vertical-light"] {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(black),
    to(rgba(0, 0, 0, 0.5))
  );
}
.image[class*="-gradient-horizontal-light"] {
  -webkit-mask-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(black),
    to(rgba(0, 0, 0, 0.5))
  );
}
.image[class*="-cover"] {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
}
.image[class*="-parallax"] {
  width: 150%;
}
.image[class*="-blur"] {
  margin: -20px;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}

.image-zoom {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 10s;
  -moz-transition: all 10s;
  transition: all 10s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}

.swiper-slide-active .image-zoom {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.image-scrolling {
  height: 125%;
  top: -25%;
}

.caption {
  position: relative;
  z-index: 50;
  height: 100%;
}

.caption-background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 25;
}
.caption-background .container,
.caption-background .container-fluid,
.caption-background .container-full {
  height: 100%;
}
.caption-background .container .row,
.caption-background .container-fluid .row,
.caption-background .container-full .row {
  height: 100%;
}

.mouse {
  position: relative;
  display: inline-block;
  width: 1.875rem;
  height: 3.125rem;
  border-radius: 10rem;
  border: 2px solid #999;
}
.mouse::after {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  content: "";
  position: absolute;
  display: block;
  top: 25%;
  left: 50%;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  -webkit-animation: mouse 2s linear infinite;
  -moz-animation: mouse 2s linear infinite;
  animation: mouse 2s linear infinite;
}

.text-white .mouse {
  border-color: #fff;
}
.text-white .mouse::after {
  background: #fff;
}

@-webkit-keyframes mouse {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, 20px);
    -moz-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
  }
}

@-moz-keyframes mouse {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, 20px);
    -moz-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
  }
}

@keyframes mouse {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, 20px);
    -moz-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
  }
}

.swiper-container .swiper-slide,
.gallery-container .swiper-slide {
  height: auto;
  overflow: hidden;
}

.swiper-container .container,
.swiper-container .container-fluid,
.gallery-container .container,
.gallery-container .container-fluid {
  height: 100%;
}
.swiper-container .container > .row,
.swiper-container .container-fluid > .row,
.gallery-container .container > .row,
.gallery-container .container-fluid > .row {
  height: 100%;
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.swiper-wrapper {
  z-index: 20;
}

.container .swiper-container .swiper-slide,
.container .gallery-container .swiper-slide,
.container-fluid .swiper-container .swiper-slide,
.container-fluid .gallery-container .swiper-slide {
  border-radius: 0.25rem;
}

.swiper-footer {
  -webkit-transform: translate(0, 50%);
  -moz-transform: translate(0, 50%);
  transform: translate(0, 50%);
  width: 100%;
  position: absolute;
  bottom: 3.125rem;
  z-index: 40;
}
.swiper-footer .container > .row,
.swiper-footer .container-fluid > .row {
  padding: 0;
}

.gallery .container > .row,
.gallery .container-fluid > .row {
  padding-bottom: 9.375rem;
}

.gallery-thumbs {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 25;
}
.gallery-thumbs .swiper-slide {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  padding: 2.5rem 0;
  text-align: center;
  cursor: pointer;
}
.gallery-thumbs .swiper-slide h5 {
  font-size: 1rem;
  letter-spacing: 0.025em;
  color: #fff;
  line-height: 1rem;
  font-weight: 400;
}
.gallery-thumbs .swiper-slide:hover {
  background: rgba(255, 255, 255, 0.1);
}
.gallery-thumbs .swiper-slide-active {
  margin-top: -1px;
  border-top: 1px solid #fff;
}

.swiper-container-carousel {
  overflow: visible;
}

.swiper-container-horizontal .swiper-pagination-bullets {
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  width: auto;
  bottom: 3.125rem;
  left: 50%;
  z-index: 25;
}
.swiper-container-horizontal
  .swiper-pagination-bullets
  .swiper-pagination-bullet {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  float: left;
  width: 2rem;
  height: 2rem;
  margin: 0;
  opacity: 1;
  background: transparent;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  outline: none;
}
.swiper-container-horizontal
  .swiper-pagination-bullets
  .swiper-pagination-bullet::after {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}
.swiper-container-horizontal
  .swiper-pagination-bullets
  .swiper-pagination-bullet:hover::after {
  background: rgba(255, 255, 255, 0.4);
}
.swiper-container-horizontal
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  background: #fff;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.swiper-container-horizontal .swiper-pagination-vertical {
  -webkit-transform: translate(0, 50%);
  -moz-transform: translate(0, 50%);
  transform: translate(0, 50%);
  right: 15px;
  bottom: 50%;
  left: auto;
  width: 2rem;
}

.swiper-button-prev,
.swiper-button-next {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  float: left;
  position: relative;
  display: block;
  width: 3.75rem;
  height: 3.75rem;
  margin-top: 15px;
  text-align: center;
  background-image: none !important;
  border: 1px solid transparent;
  z-index: 20;
  outline: none;
}
.swiper-button-prev::before,
.swiper-button-next::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #000;
  line-height: 3.75rem;
  font-family: "icomoon";
  font-size: 1.5rem;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  border-color: #e5e5e5;
}
.swiper-button-prev:hover::before,
.swiper-button-next:hover::before {
  color: #000;
}

.swiper-button-prev {
  left: 0;
}
.swiper-button-prev::before {
  content: "\ebfb";
}

.swiper-button-next {
  right: 0;
}
.swiper-button-next::before {
  content: "\ebfc";
}

@media (max-width: 1199.98px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

[class*="bordered"] {
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  background-color: transparent;
  border: 1px solid #e5e5e5;
}

.text-white [class*="bordered"],
.user-caption .socials {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.selectric-items,
.container .swiper-container .swiper-slide,
.container .gallery-container .swiper-slide,
.container-fluid .swiper-container .swiper-slide,
.container-fluid .gallery-container .swiper-slide,
[class*="boxed"],
.nav-docs .lavalamp-object,
.accordion-group-minimal .accordion-control::after,
.accordion-group-highlight .accordion.open,
.accordion-group-portal,
.feed-item img,
.card,
.card-minimal .card-img-container,
.component > .tab-content > .tab-pane,
.presentation-circle > figure {
  border-radius: 0.25rem;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.selectric-items,
.box > *,
[class*="floating"],
.owl-carousel-single .owl-stage-outer,
.video-js,
.demo-preview .card-minimal .card-img-container .card-img {
  -webkit-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
}

[class*="rounded"] {
  border-radius: 10rem !important;
}

.card-minimal .card-img-container {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}
.card-minimal .card-img-container:hover {
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  transform: translate(0, -10px);
  position: relative;
  z-index: 50;
  -webkit-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  [class*="scaling"] {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
  }
  [class*="scaling"]:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 50;
  }
  [class*="rising"] {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
  }
  [class*="rising"]:hover {
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    transform: translate(0, -10px);
    position: relative;
    z-index: 50;
    -webkit-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  }
}

.separated {
  overflow: hidden;
}
.separated > * {
  -webkit-box-shadow: -1px -1px 0px 0px #e5e5e5;
  -moz-box-shadow: -1px -1px 0px 0px #e5e5e5;
  box-shadow: -1px -1px 0px 0px #e5e5e5;
}
.separated > *:not(:last-child) {
  margin-bottom: 0 !important;
}

.text-white .separated > * {
  -webkit-box-shadow: -1px -1px 0px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: -1px -1px 0px 0px rgba(255, 255, 255, 0.2);
  box-shadow: -1px -1px 0px 0px rgba(255, 255, 255, 0.2);
}

.anchor,
.box,
.equal,
.feature-square,
.tile,
.presentation-circle {
  position: relative;
}
.anchor::before,
.box::before,
.equal::before,
.feature-square::before,
.tile::before,
.presentation-circle::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.anchor > *,
.box > *,
.equal > *,
.feature-square > *,
.tile > *,
.presentation-circle > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.anchor[class*="-short"]::before,
.box[class*="-short"]::before,
.equal[class*="-short"]::before,
.feature-square[class*="-short"]::before,
.tile[class*="-short"]::before,
.presentation-circle[class*="-short"]::before {
  padding-top: 75%;
}
.anchor[class*="-long"]::before,
.box[class*="-long"]::before,
.equal[class*="-long"]::before,
.feature-square[class*="-long"]::before,
.tile[class*="-long"]::before,
.presentation-circle[class*="-long"]::before {
  padding-top: 125%;
}

.equal .equal-header,
.equal .equal-footer {
  position: absolute;
  right: 0;
  left: 0;
  padding: 30px;
}

.equal .equal-header {
  top: 0;
}

.equal .equal-footer {
  bottom: 0;
}

.equal .row {
  height: 100%;
}

.align-bottom .owl-stage {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.scrolling-gallery {
  height: 50vh;
}

@media (max-width: 1199.98px) {
  .scrolling-gallery {
    overflow: hidden;
    margin-bottom: -5rem;
  }
}

.photo {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.photo img {
  width: 100%;
}
.photo div {
  display: block;
  margin: 0;
  background-size: cover;
  background-position: center;
}
.photo a {
  display: block;
  margin: 0;
  background-size: cover;
  background-position: center;
}
.photo div::before {
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}
.photo a::before {
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}
.photo:hover a:before {
  opacity: 0.75;
}
.photo:hover div:before {
  opacity: 0.75;
}
.photo:hover .photo-caption {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

.photo-caption {
  -webkit-transform: translate(0, 100%);
  -moz-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0.625rem;
  left: 0.625rem;
  padding: 0.5rem;
  opacity: 0;
  color: #fff;
}

.gutter-0 .photo {
  border-radius: 0;
}

.scrolling--gallery {
  position: absolute;
  top: 0;
  width: 100%;
}

.nav {
  display: inline-block;
  position: relative;
}
.nav .nav-link {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  display: inline-block;
  color: #999;
  z-index: 5;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.nav .lavalamp-object {
  display: none;
  z-index: 1;
}

.nav-text {
  margin: -0.5rem;
}
.nav-text .nav-link {
  margin: 0.5rem;
  padding: 0 0 1rem 0;
  line-height: 1em;
  font-weight: 300;
  font-size: 1.875rem;
  z-index: 5;
}
.nav-text .nav-link.active {
  color: #6E9EEB;
  border-color: #6E9EEB;
}
.nav-text .nav-link:hover {
  color: #6E9EEB;
}

.nav-tabs {
  border: 0;
}
.nav-tabs .lavalamp-object {
  display: block;
  position: absolute;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: #6E9EEB;
}
.nav-tabs .nav-item {
  margin-right: 30px;
}
.nav-tabs .nav-link {
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
.nav-tabs .nav-link.active {
  background-color: transparent;
}
.nav-tabs.nav-vertical {
  border-width: 0 0 0 1px;
}
.nav-tabs.nav-vertical .lavalamp-object {
  border-width: 0 0 0 2px;
  border-bottom: 0;
}
.nav-tabs.nav-vertical .nav-link {
  padding: 0.5rem 1rem;
  margin: 0;
}

.nav-pills .lavalamp-object {
  display: block;
  position: absolute;
  border: 2px solid #6E9EEB;
  border-radius: 10rem;
}

.nav-pills .nav-link {
  z-index: 5;
  padding: 0.75rem 1.5rem;
}
.nav-pills .nav-link.active,
.nav-pills .nav-link:hover {
  color: #444;
}

.nav-switch {
  display: inline-block;
  border: 1px solid #e5e5e5;
  border-radius: 22.5px;
  overflow: hidden;
}
.nav-switch .lavalamp-object {
  display: block;
  position: absolute;
  background: #e5e5e5;
  border-radius: 10rem;
}
.nav-switch .nav-link {
  padding: 15px 25px;
  line-height: 15px;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  z-index: 5;
  cursor: pointer;
}
.nav-switch .nav-link.active,
.nav-switch .nav-link:hover {
  color: #444;
}

.nav-docs {
  display: inline-block;
}
.nav-docs .lavalamp-object {
  border-radius: 0.25rem;
  display: block;
  position: absolute;
}
.nav-docs .nav-link {
  padding: 10px 20px;
  line-height: 20px;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  z-index: 5;
  cursor: pointer;
}
.nav-docs .nav-link.active,
.nav-docs .nav-link:hover {
  color: #000;
}

.nav-vertical {
  display: inline-block;
}
.nav-vertical .nav-link {
  display: block;
}

.tab-content {
  position: relative;
}
.tab-content .tab-pane {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  width: 100%;
  display: block;
}
.tab-content .tab-pane:not(.active) {
  visibility: hidden;
  position: absolute;
  top: 0;
  animation: fadeOutBottom 0.25s ease forwards;
  -webkit-animation: fadeOutBottom 0.25s ease forwards;
}
.tab-content .tab-pane.active {
  top: 0;
  position: relative;
  z-index: 90;
  animation: fadeInTop 0.5s ease forwards;
  -webkit-animation: fadeInTop 0.5s ease forwards;
}

@keyframes fadeOutBottom {
  from {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: translate(0, 10%);
    -moz-transform: translate(0, 10%);
    transform: translate(0, 10%);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-font-smoothing: antialiased;
  }
}

@-webkit-keyframes fadeOutBottom {
  from {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate(0, 10%);
    -moz-transform: translate(0, 10%);
    transform: translate(0, 10%);
    -webkit-font-smoothing: antialiased;
  }
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate(0, 10%);
    -moz-transform: translate(0, 10%);
    transform: translate(0, 10%);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-font-smoothing: antialiased;
  }
}

@-webkit-keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate(0, 10%);
    -moz-transform: translate(0, 10%);
    transform: translate(0, 10%);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-font-smoothing: antialiased;
  }
}

.text-white .nav-switch {
  border-color: rgba(255, 255, 255, 0.2);
}
.text-white .nav-switch .lavalamp-object {
  background: rgba(255, 255, 255, 0.2);
}

[class*="boxed"] > .row {
  padding: 0;
}

.feature-square {
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  text-align: center;
}
.feature-square:hover {
  background: #f5f5f5;
}
.feature-square div {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.feature-list {
  list-style: none;
  padding: 0;
}
.feature-list::after {
  display: block;
  clear: both;
  content: "";
}
.feature-list li {
  float: left;
}
.feature-list .media-body {
  flex: none;
}

.accordion-group {
  border-radius: 0.25rem;
}

.accordion {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  padding: 0.625rem;
  border: 1px solid #e5e5e5;
}
.accordion.open .accordion-control::after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
.accordion:not(:first-child) {
  border-top-width: 0;
}

.accordion-control {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.accordion-control > * {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  margin: 0;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  vertical-align: middle;
}
.accordion-control h5 {
  line-height: 1.5em;
}
.accordion-control::after {
  content: "\ebfa";
  position: absolute;
  width: 1em;
  line-height: 1em;
  right: 1rem;
  top: 50%;
  font-family: "icomoon" !important;
  font-size: 1.5rem;
  margin-top: -0.5em;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}

.accordion-content {
  overflow: hidden;
}
.accordion-content .accordion-content-wrapper {
  padding: 0 2rem 1rem 2rem;
}

.text-white .accordion-group .accordion {
  border: 0;
  background-color: rgba(255, 255, 255, 0.05);
}
.text-white .accordion-group .accordion:not(:first-child) {
  margin-top: 2px;
}
.text-white .accordion-group .accordion:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.text-white .accordion-group .accordion-control h5 {
  color: inherit;
}

.text-white .accordion-group .accordion-control::after {
  color: rgba(255, 255, 255, 0.5);
}

.accordion-group-feature .accordion {
  border-radius: 0.25rem;
  padding: 1rem;
}
.accordion-group-feature .accordion:not(:first-child) {
  margin-top: 0.625rem;
}
.accordion-group-feature .accordion:not(:first-child) {
  border-top-width: 1px;
}

.accordion-group-feature .accordion-control,
.accordion-group-feature .accordion-content > div {
  padding: 1rem;
}

.text-white .accordion-group-feature .accordion:not(:first-child) {
  margin-top: 0.625rem;
}

.accordion-group-minimal .accordion {
  border: 0;
  padding: 0 0 0 3rem;
}
.accordion-group-minimal .accordion:not(:first-child) {
  margin-top: 1.25rem;
}

.accordion-group-minimal .accordion-control {
  min-height: 3.75rem;
}
.accordion-group-minimal .accordion-control::after {
  left: -3rem;
  width: 3.75rem;
  color: #000;
  margin-top: -1.875rem;
  line-height: 3.75rem;
  text-align: center;
  border-radius: 50%;
}

.text-white .accordion-group-minimal .accordion {
  background: transparent;
  color: #fff;
}
.text-white .accordion-group-minimal .accordion:not(:first-child) {
  margin-top: 1.25rem;
}
.text-white .accordion-group-minimal .accordion:hover {
  background-color: transparent;
}

.text-white .accordion-group-minimal .accordion-control::after {
  color: #000;
}

.accordion-group-steps {
  overflow: visible;
}
.accordion-group-steps .accordion {
  padding-left: 3.75rem;
  counter-increment: accordion;
  background: transparent;
  border: 0;
}
.accordion-group-steps .accordion.open .accordion-control::before {
  background: #6E9EEB;
  border-color: #6E9EEB;
  color: #fff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}
.accordion-group-steps .accordion:hover .accordion-control::before {
  border-color: #6E9EEB;
}
.accordion-group-steps .accordion:not(:first-child) {
  margin: 0;
}
.accordion-group-steps .accordion:not(:first-child)::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 29px;
  height: calc(50% - 30px);
  width: 2px;
  background: #e5e5e5;
  z-index: 10;
}
.accordion-group-steps .accordion:not(:last-child)::after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% + 30px);
  left: 29px;
  height: calc(50% - 30px);
  width: 2px;
  background: #e5e5e5;
  z-index: 10;
}
.accordion-group-steps .accordion-control {
  position: static;
}
.accordion-group-steps .accordion-control > * {
  display: block;
}
.accordion-group-steps .accordion-control::after {
  display: none;
}
.accordion-group-steps .accordion-control::before {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  content: counter(accordion, decimal);
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  width: 3.75rem;
  height: 3.75rem;
  color: #000;
  margin-top: -30px;
  line-height: calc(3.75rem - 4px);
  text-align: center;
  border: 2px solid #e5e5e5;
  z-index: 20;
  border-radius: 50%;
  font-size: 20px;
}

.text-white .accordion-group-steps .accordion {
  background-color: transparent;
}
.text-white .accordion-group-steps .accordion:hover {
  background-color: transparent;
}
.text-white .accordion-group-steps .accordion:not(:first-child) {
  margin-top: 0;
}
.text-white .accordion-group-steps .accordion:not(:first-child)::before {
  background: rgba(255, 255, 255, 0.2);
}
.text-white .accordion-group-steps .accordion:not(:last-child)::after {
  background: rgba(255, 255, 255, 0.2);
}

.text-white .accordion-group-steps .accordion-control::before {
  color: inherit;
  border-color: rgba(255, 255, 255, 0.2);
}

.accordion-group-highlight {
  overflow: visible;
}
.accordion-group-highlight .accordion {
  background: transparent;
  border: 0;
}

.text-white .accordion-group-highlight .accordion {
  background: transparent;
}
.text-white .accordion-group-highlight .accordion:not(:first-child) {
  margin-top: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.text-white .accordion-group-highlight .accordion.open {
  background-color: rgba(255, 255, 255, 0.05);
}

.accordion-group-portal {
  overflow: hidden;
}
.accordion-group-portal .accordion.open {
  background: #f5f5f5;
}
.accordion-group-portal .accordion.open .accordion-control::after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.accordion-group-portal .accordion-control {
  padding: 2rem;
}
.accordion-group-portal .accordion-control::after {
  content: "\ec7d";
}
.accordion-group-portal .accordion-control h5 {
  font-size: 1.25rem;
  font-family: "Rubik", sans-serif;
}
.accordion-group-portal .accordion-content > div {
  padding-bottom: 2rem;
}

@media (max-width: 1199.98px) {
  .accordion-control {
    display: block;
  }
  .accordion-control > *:not(:first-child) {
    margin-top: 0.25rem;
  }
  .accordion-group-feature .accordion-control > * {
    display: inline-block;
  }
}

.owl-carousel[data-dots="true"] {
  margin-bottom: 60px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  border-radius: 50%;
  top: 50%;
  width: 4rem;
  height: 4rem;
  text-align: center;
  z-index: 20;
}
.owl-carousel .owl-nav button.owl-prev::before,
.owl-carousel .owl-nav button.owl-next::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: 4rem;
  font-family: "icomoon";
  font-size: 1.8rem;
  color: #fff;
}
.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  background: rgba(0, 0, 0, 0.5);
}
.owl-carousel .owl-nav button.owl-prev span,
.owl-carousel .owl-nav button.owl-next span {
  display: none;
}

.owl-carousel .owl-nav .owl-prev {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 15px;
}
.owl-carousel .owl-nav .owl-prev::before {
  content: "\ebfb";
}
.owl-carousel .owl-nav .owl-prev[class*="disabled"] {
  -webkit-transform: translate(-100%, -50%);
  -moz-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}

.owl-carousel .owl-nav .owl-next {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 15px;
}
.owl-carousel .owl-nav .owl-next::before {
  content: "\ebfc";
}
.owl-carousel .owl-nav .owl-next[class*="disabled"] {
  -webkit-transform: translate(100%, -50%);
  -moz-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}

.owl-dots {
  -webkit-transform: translate(0, 50%);
  -moz-transform: translate(0, 50%);
  transform: translate(0, 50%);
  position: absolute;
  bottom: -30px;
  width: 100%;
  text-align: center;
}
.owl-dots.disabled {
  display: none;
}
.owl-dots .owl-dot {
  display: inline-block;
  vertical-align: top;
}
.owl-dots .owl-dot span {
  display: block;
  width: 8px;
  height: 8px;
  margin: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}
.owl-dots .owl-dot:hover span {
  background: rgba(0, 0, 0, 0.4);
}
.owl-dots .owl-dot.active span {
  background: #000;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform: scale(1.5);
}

.owl-carousel-single .owl-stage-outer {
  border-radius: 0.25rem;
  overflow: hidden;
}

.owl-carousel-single .owl-nav button.owl-prev,
.owl-carousel-single .owl-nav button.owl-next {
  background: #6E9EEB;
  width: 5.5rem;
  height: 5.5rem;
}
.owl-carousel-single .owl-nav button.owl-prev::before,
.owl-carousel-single .owl-nav button.owl-next::before {
  line-height: 5.5rem;
}
.owl-carousel-single .owl-nav button.owl-prev:hover,
.owl-carousel-single .owl-nav button.owl-next:hover {
  background: rgba(72, 41, 178, 0.9);
}

.owl-carousel-single .owl-nav .owl-prev {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 0;
}

.owl-carousel-single .owl-nav .owl-next {
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  right: 0;
}

.owl-carousel-library .owl-nav {
  margin-top: 1rem;
}
.owl-carousel-library .owl-nav button.owl-prev,
.owl-carousel-library .owl-nav button.owl-next {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
  position: static;
  border-radius: 0.25rem;
  background: none;
  border: 1px solid transparent;
}
.owl-carousel-library .owl-nav button.owl-prev::before,
.owl-carousel-library .owl-nav button.owl-next::before {
  color: #000;
}
.owl-carousel-library .owl-nav button.owl-prev:hover,
.owl-carousel-library .owl-nav button.owl-next:hover {
  background: transparent;
  border-color: #e5e5e5;
}
.owl-carousel-library .owl-nav .owl-prev[class*="disabled"],
.owl-carousel-library .owl-nav .owl-nav[class*="disabled"] {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
  visibility: visible;
  opacity: 0.5;
}

.text-white .owl-carousel-library .owl-nav button.owl-prev:hover,
.text-white .owl-carousel-library .owl-nav button.owl-next:hover {
  border-color: rgba(255, 255, 255, 0.2);
}

.text-white .owl-carousel-library .owl-nav button.owl-prev::before,
.text-white .owl-carousel-library .owl-nav button.owl-next::before {
  color: #fff;
}

.owl-carousel .owl-stage-outer .owl-stage .owl-item {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  counter-increment: owlItem;
}
.owl-carousel .owl-stage-outer .owl-stage .owl-item img {
  width: auto;
  max-width: 100%;
}

.owl-carousel.visible .owl-stage-outer {
  overflow: visible;
}

.owl-carousel.accent .owl-item {
  opacity: 0.25;
}
.owl-carousel.accent .owl-item.active {
  opacity: 1;
}

.user {
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
}
.user:hover .user-caption {
  padding-top: 6rem;
  padding-bottom: 5.75rem;
}
.user:hover .socials {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
}
.user[class*="-circle"] {
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
}
.user[class*="-circle"] .socials {
  left: 50%;
  -webkit-transform: translate(-50%, calc(100% + 2.5rem));
  -moz-transform: translate(-50%, calc(100% + 2.5rem));
  transform: translate(-50%, calc(100% + 2.5rem));
}
.user[class*="-circle"]:hover .socials {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.gutter-0 .user {
  border-radius: 0;
}

.user-photo {
  display: block;
  overflow: hidden;
  margin: 0;
}
.user-photo img {
  max-width: 100%;
}

.user-caption {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1.875rem;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 );
}
.user-caption > * {
  display: block;
}
.user-caption h4 {
  color: #fff;
  margin: 0;
}
.user-caption span {
  color: rgba(255, 255, 255, 0.5);
}
.user-caption .socials {
  -webkit-transform: translate(0, calc(100% + 2.5rem));
  -moz-transform: translate(0, calc(100% + 2.5rem));
  transform: translate(0, calc(100% + 2.5rem));
  position: absolute;
  bottom: 1.875rem;
}

.expanded + .user-panel {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
  visibility: visible;
  opacity: 1;
  padding: 15rem 0 5rem 0;
}
.expanded + .user-panel > .container {
  opacity: 1;
  transition-delay: 0.2s;
}

.user-panel {
  -webkit-transform: translate(0, 100%);
  -moz-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  padding: 0;
  opacity: 0;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
}
.user-panel::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
}
.user-panel > .container {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}
.user-panel .collapse {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  position: absolute;
  bottom: 15px;
  left: 50%;
  display: block;
  border-radius: 50%;
  text-align: center;
  width: 4.25rem;
  height: 4.25rem;
  line-height: 4.25rem;
  text-align: center;
  cursor: pointer;
  color: #000;
}
.user-panel .collapse::before {
  font-family: "icomoon";
  font-size: 1.875rem;
  content: "\ecce";
  color: #fff;
}
.user-panel .collapse:hover {
  background: rgba(255, 255, 255, 0.1);
}

.avatar {
  border-radius: 0.25rem;
  width: 3.75rem;
  height: 3.75rem;
}
.avatar[class*="-sm"] {
  width: 2.5rem;
  height: auto;
}
.avatar[class*="-lg"] {
  width: 5rem;
  height: auto;
}
.avatar[class*="-xl"] {
  width: 10rem;
  height: auto;
}

.user-grid {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  list-style: none;
  padding: 0;
}
.user-grid::after {
  display: block;
  clear: both;
  content: "";
}
.user-grid > li {
  float: left;
}
.user-grid .user {
  overflow: visible;
}
.user-grid .user-photo {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-radius: 50%;
  cursor: pointer;
}
.user-grid .user-photo:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
}
.user-grid .user-photo.expanded {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
}
.user-grid .user-photo img {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-radius: 50%;
}

@media (max-width: 1199.98px) {
  .user-grid > li {
    width: 20%;
  }
  .user-grid > li:nth-child(6) {
    margin-left: calc(20% / 2);
  }
}

@media (min-width: 1200px) {
  .user-grid > li {
    width: 12.5%;
  }
  .user-grid > li:nth-child(9) {
    margin-left: calc(12.5% / 2);
  }
}

.user-list {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.user-list > li {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  border-radius: 50%;
  z-index: 10;
  overflow: hidden;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}
.user-list > li:not(:first-child) {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  margin-left: -1rem;
}
.user-list > li:hover {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  z-index: 20;
}

.speakers {
  margin-right: 1.25rem;
}

@media (max-width: 1199.98px) {
  .speakers {
    display: none;
  }
}

.entry {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  overflow: hidden;
  position: relative;
}
.entry > a {
  padding: 2.5rem;
  color: inherit;
}
.entry:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}
.entry::after {
  position: absolute;
  top: 50%;
  right: 2.5rem;
  display: block;
  content: "\f105";
  font-size: 1.375rem;
  font-family: "icomoon";
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.entry:hover {
  background: #e5e5e5;
}
.entry:hover::after {
  right: 1.875rem;
}

.entry-title {
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.text-white .entry:not(:first-child) {
  border-color: rgba(255, 255, 255, 0.2);
}

.text-white .entry:hover {
  background: rgba(255, 255, 255, 0.2);
}

.news {
  margin-top: 0.3125rem;
}
.news:first-child {
  margin-top: 0;
}
.news a {
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  padding: 1.875rem;
  display: inline-block;
  background: #e5e5e5;
  color: inherit;
}
.news a:hover {
  padding-left: 2.5rem;
}
.news time {
  -webkit-transform: translate(1.25rem, -50%);
  -moz-transform: translate(1.25rem, -50%);
  transform: translate(1.25rem, -50%);
  left: 100%;
  top: 50%;
  position: absolute;
  white-space: nowrap;
}

.news-title {
  margin: 0;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.text-white .news a {
  background: rgba(0, 0, 0, 0.1);
}

.text-white .news time {
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 767.98px) {
  .news time {
    position: static;
    font-size: 0.875rem;
    letter-spacing: 0.025em;
  }
}

.tile {
  border-radius: 0.25rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
}
.tile .tile-content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: rgba(0, 0, 0, 0.2);
}
.tile:hover .tile-image {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
}
.tile:hover .tile-header {
  -webkit-transform: translate(0, 5%);
  -moz-transform: translate(0, 5%);
  transform: translate(0, 5%);
}
.tile:hover .tile-footer {
  -webkit-transform: translate(0, -5%);
  -moz-transform: translate(0, -5%);
  transform: translate(0, -5%);
}
.tile:hover .on-hover {
  opacity: 1;
}
.tile:hover .on-hover.tile-header {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
}
.tile:hover .on-hover.tile-footer {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
}

.tile-image {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  background-size: cover;
  background-position: 50% 50%;
}

.tile-header,
.tile-footer {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1.875rem;
}

.on-hover {
  opacity: 0;
}
.on-hover.tile-header {
  -webkit-transform: translate(0, -25%);
  -moz-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
.on-hover.tile-footer {
  -webkit-transform: translate(0, 25%);
  -moz-transform: translate(0, 25%);
  transform: translate(0, 25%);
}

.tile-footer {
  top: auto;
  bottom: 0;
}

.widget + .widget {
  margin-top: 3.125rem;
}

.widget-title {
  display: block;
  margin-bottom: 1rem;
}

.tag-cloud {
  margin: -0.25rem;
}
.tag-cloud a {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  float: left;
  display: block;
  margin: 0.25rem;
  background: #e5e5e5;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #151515;
  border-radius: 10rem;
}
.tag-cloud a:hover {
  background: #6E9EEB;
  color: #fff;
}

.list-group-categories .list-group-item {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  color: #000;
}
.list-group-categories .list-group-item:hover {
  color: #6E9EEB;
}
.list-group-categories .list-group-item:hover .badge {
  color: #6E9EEB;
}

.list-group-categories .badge {
  background: #e5e5e5;
}

.feed {
  padding: 0;
  list-style: none;
}
.feed li:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.feed-item {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.feed-item:hover .feed-item-content h3 {
  color: #6E9EEB;
}
.feed-item img {
  border-radius: 0.25rem;
  width: 70px;
}

.feed-item-content {
  padding-left: 15px;
}
.feed-item-content h3 {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
}
.feed-item-content span {
  font-size: 0.875rem;
  color: #151515;
}

.card-price .svg-icon {
  margin-bottom: 2.8125rem;
}

.card-price .card-footer {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  padding: 1.875rem !important;
  border-top: 1px solid #e5e5e5;
  color: #151515;
}
.card-price .card-footer::after {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  position: absolute;
  top: 50%;
  right: 2.875rem;
  display: block;
  content: "\ebde";
  font-size: 1.375rem;
  font-family: "icomoon";
}
.card-price .card-footer:hover {
  background: #e5e5e5;
}
.card-price .card-footer:hover::after {
  right: 1.875rem;
  color: inherit;
}

.card-price .card-price {
  font-size: 2.5rem;
  font-weight: 400;
}

.card-price-2 .card-title {
  margin-top: 1.875rem;
  margin-bottom: 0.46875rem;
  font-size: 1.25rem;
  font-weight: 400;
}

.card-price-2 .card-price {
  font-size: 3.125rem;
  font-weight: 400;
}

.card-price-2 .card-footer {
  padding-top: 0;
}

.card-price-3 {
  text-align: center;
}
.card-price-3 .card-title {
  display: block;
  color: #151515;
  margin-bottom: 2.8125rem;
}
.card-price-3 .card-price {
  font-size: 3.75rem;
  font-weight: 400;
}
.card-price-3 .card-body {
  padding-bottom: 2.8125rem;
}

.text-white .card-price .card-footer {
  border-color: rgba(255, 255, 255, 0.2);
}
.text-white .card-price .card-footer:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.btn-blue {
  color: #fff;
  background-color: #0030ae;
  border-color: #0030ae;
}
.btn-blue:hover {
  color: #fff;
  background-color: #002588;
  border-color: #00227b;
}
.btn-blue:focus,
.btn-blue.focus {
  box-shadow: 0 0 0 0 rgba(38, 79, 186, 0.5);
}
.btn-blue.disabled,
.btn-blue:disabled {
  color: #fff;
  background-color: #0030ae;
  border-color: #0030ae;
}
.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #00227b;
  border-color: #001e6e;
}
.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 79, 186, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-indigo:hover {
  color: #fff;
  background-color: #560bd0;
  border-color: #510bc4;
}
.btn-indigo:focus,
.btn-indigo.focus {
  box-shadow: 0 0 0 0 rgba(125, 52, 244, 0.5);
}
.btn-indigo.disabled,
.btn-indigo:disabled {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active,
.show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #510bc4;
  border-color: #4c0ab8;
}
.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(125, 52, 244, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-purple:hover {
  color: #fff;
  background-color: #3b2293;
  border-color: #371f89;
}
.btn-purple:focus,
.btn-purple.focus {
  box-shadow: 0 0 0 0 rgba(99, 73, 190, 0.5);
}
.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #371f89;
  border-color: #331d7e;
}
.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(99, 73, 190, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}
.btn-pink:hover {
  color: #fff;
  background-color: #e41c78;
  border-color: #d91a72;
}
.btn-pink:focus,
.btn-pink.focus {
  box-shadow: 0 0 0 0 rgba(235, 91, 157, 0.5);
}
.btn-pink.disabled,
.btn-pink:disabled {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}
.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #d91a72;
  border-color: #ce196c;
}
.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(235, 91, 157, 0.5);
}

.btn-red {
  color: #fff;
  background-color: #fc2c38;
  border-color: #fc2c38;
}
.btn-red:hover {
  color: #fff;
  background-color: #fb0614;
  border-color: #f20311;
}
.btn-red:focus,
.btn-red.focus {
  box-shadow: 0 0 0 0 rgba(252, 76, 86, 0.5);
}
.btn-red.disabled,
.btn-red:disabled {
  color: #fff;
  background-color: #fc2c38;
  border-color: #fc2c38;
}
.btn-red:not(:disabled):not(.disabled):active,
.btn-red:not(:disabled):not(.disabled).active,
.show > .btn-red.dropdown-toggle {
  color: #fff;
  background-color: #f20311;
  border-color: #e50310;
}
.btn-red:not(:disabled):not(.disabled):active:focus,
.btn-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(252, 76, 86, 0.5);
}

.btn-orange {
  color: #151515;
  background-color: #f3a83c;
  border-color: #f3a83c;
}
.btn-orange:hover {
  color: #151515;
  background-color: #f19818;
  border-color: #ed920f;
}
.btn-orange:focus,
.btn-orange.focus {
  box-shadow: 0 0 0 0 rgba(210, 146, 54, 0.5);
}
.btn-orange.disabled,
.btn-orange:disabled {
  color: #151515;
  background-color: #f3a83c;
  border-color: #f3a83c;
}
.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #151515;
  background-color: #ed920f;
  border-color: #e18b0e;
}
.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(210, 146, 54, 0.5);
}

.btn-yellow {
  color: #151515;
  background-color: #e3c038;
  border-color: #e3c038;
}
.btn-yellow:hover {
  color: #151515;
  background-color: #d7b11e;
  border-color: #cba81d;
}
.btn-yellow:focus,
.btn-yellow.focus {
  box-shadow: 0 0 0 0 rgba(196, 166, 51, 0.5);
}
.btn-yellow.disabled,
.btn-yellow:disabled {
  color: #151515;
  background-color: #e3c038;
  border-color: #e3c038;
}
.btn-yellow:not(:disabled):not(.disabled):active,
.btn-yellow:not(:disabled):not(.disabled).active,
.show > .btn-yellow.dropdown-toggle {
  color: #151515;
  background-color: #cba81d;
  border-color: #c09e1b;
}
.btn-yellow:not(:disabled):not(.disabled):active:focus,
.btn-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(196, 166, 51, 0.5);
}

.btn-green {
  color: #fff;
  background-color: #54af5d;
  border-color: #54af5d;
}
.btn-green:hover {
  color: #fff;
  background-color: #46964e;
  border-color: #428e4a;
}
.btn-green:focus,
.btn-green.focus {
  box-shadow: 0 0 0 0 rgba(110, 187, 117, 0.5);
}
.btn-green.disabled,
.btn-green:disabled {
  color: #fff;
  background-color: #54af5d;
  border-color: #54af5d;
}
.btn-green:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: #428e4a;
  border-color: #3e8545;
}
.btn-green:not(:disabled):not(.disabled):active:focus,
.btn-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(110, 187, 117, 0.5);
}

.btn-teal {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-teal:hover {
  color: #fff;
  background-color: #1ba87e;
  border-color: #199d76;
}
.btn-teal:focus,
.btn-teal.focus {
  box-shadow: 0 0 0 0 rgba(65, 209, 167, 0.5);
}
.btn-teal.disabled,
.btn-teal:disabled {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active,
.show > .btn-teal.dropdown-toggle {
  color: #fff;
  background-color: #199d76;
  border-color: #17926e;
}
.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(65, 209, 167, 0.5);
}

.btn-cyan {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-cyan:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-cyan:focus,
.btn-cyan.focus {
  box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
}
.btn-cyan.disabled,
.btn-cyan:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-cyan:not(:disabled):not(.disabled):active,
.btn-cyan:not(:disabled):not(.disabled).active,
.show > .btn-cyan.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-cyan:not(:disabled):not(.disabled):active:focus,
.btn-cyan:not(:disabled):not(.disabled).active:focus,
.show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
}

.btn-white {
  color: #151515;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #151515;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white:focus,
.btn-white.focus {
  box-shadow: 0 0 0 0 rgba(220, 220, 220, 0.5);
}
.btn-white.disabled,
.btn-white:disabled {
  color: #151515;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #151515;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(220, 220, 220, 0.5);
}

.btn-gray {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-gray:hover {
  color: #fff;
  background-color: #424242;
  border-color: #3c3c3c;
}
.btn-gray:focus,
.btn-gray.focus {
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}
.btn-gray.disabled,
.btn-gray:disabled {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-gray:not(:disabled):not(.disabled):active,
.btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: #3c3c3c;
  border-color: #353535;
}
.btn-gray:not(:disabled):not(.disabled):active:focus,
.btn-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}

.btn-gray-dark {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-gray-dark:hover {
  color: #fff;
  background-color: #202020;
  border-color: #1a1a1a;
}
.btn-gray-dark:focus,
.btn-gray-dark.focus {
  box-shadow: 0 0 0 0 rgba(82, 82, 82, 0.5);
}
.btn-gray-dark.disabled,
.btn-gray-dark:disabled {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-gray-dark:not(:disabled):not(.disabled):active,
.btn-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #131313;
}
.btn-gray-dark:not(:disabled):not(.disabled):active:focus,
.btn-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 82, 82, 0.5);
}

.btn-dark-blue {
  color: #fff;
  background-color: #0030ae;
  border-color: #0030ae;
}
.btn-dark-blue:hover {
  color: #fff;
  background-color: #002588;
  border-color: #00227b;
}
.btn-dark-blue:focus,
.btn-dark-blue.focus {
  box-shadow: 0 0 0 0 rgba(38, 79, 186, 0.5);
}
.btn-dark-blue.disabled,
.btn-dark-blue:disabled {
  color: #fff;
  background-color: #0030ae;
  border-color: #0030ae;
}
.btn-dark-blue:not(:disabled):not(.disabled):active,
.btn-dark-blue:not(:disabled):not(.disabled).active,
.show > .btn-dark-blue.dropdown-toggle {
  color: #fff;
  background-color: #00227b;
  border-color: #001e6e;
}
.btn-dark-blue:not(:disabled):not(.disabled):active:focus,
.btn-dark-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 79, 186, 0.5);
}

.btn-transparent-white {
  color: #151515;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.btn-transparent-white:hover {
  color: #151515;
  background-color: rgba(236, 236, 236, 0.2);
  border-color: rgba(230, 230, 230, 0.2);
}
.btn-transparent-white:focus,
.btn-transparent-white.focus {
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}
.btn-transparent-white.disabled,
.btn-transparent-white:disabled {
  color: #151515;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.btn-transparent-white:not(:disabled):not(.disabled):active,
.btn-transparent-white:not(:disabled):not(.disabled).active,
.show > .btn-transparent-white.dropdown-toggle {
  color: #151515;
  background-color: rgba(230, 230, 230, 0.2);
  border-color: rgba(223, 223, 223, 0.2);
}
.btn-transparent-white:not(:disabled):not(.disabled):active:focus,
.btn-transparent-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-transparent-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}

.btn-transparent-black {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-transparent-black:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-transparent-black:focus,
.btn-transparent-black.focus {
  box-shadow: 0 0 0 0 rgba(156, 156, 156, 0.5);
}
.btn-transparent-black.disabled,
.btn-transparent-black:disabled {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-transparent-black:not(:disabled):not(.disabled):active,
.btn-transparent-black:not(:disabled):not(.disabled).active,
.show > .btn-transparent-black.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-transparent-black:not(:disabled):not(.disabled):active:focus,
.btn-transparent-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-transparent-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(156, 156, 156, 0.5);
}

.btn-outline-blue {
  color: #0030ae;
  border-color: #0030ae;
}
.btn-outline-blue:hover {
  color: #fff;
  background-color: #0030ae;
  border-color: #0030ae;
}
.btn-outline-blue:focus,
.btn-outline-blue.focus {
  box-shadow: 0 0 0 0 rgba(0, 48, 174, 0.5);
}
.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
  color: #0030ae;
  background-color: transparent;
}
.btn-outline-blue:not(:disabled):not(.disabled):active,
.btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #0030ae;
  border-color: #0030ae;
}
.btn-outline-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 48, 174, 0.5);
}

.btn-outline-indigo {
  color: #6610f2;
  border-color: #6610f2;
}
.btn-outline-indigo:hover {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-outline-indigo:focus,
.btn-outline-indigo.focus {
  box-shadow: 0 0 0 0 rgba(102, 16, 242, 0.5);
}
.btn-outline-indigo.disabled,
.btn-outline-indigo:disabled {
  color: #6610f2;
  background-color: transparent;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active,
.btn-outline-indigo:not(:disabled):not(.disabled).active,
.show > .btn-outline-indigo.dropdown-toggle {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active:focus,
.btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(102, 16, 242, 0.5);
}

.btn-outline-purple {
  color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-outline-purple:hover {
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-outline-purple:focus,
.btn-outline-purple.focus {
  box-shadow: 0 0 0 0 rgba(72, 41, 178, 0.5);
}
.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
  color: #6E9EEB;
  background-color: transparent;
}
.btn-outline-purple:not(:disabled):not(.disabled):active,
.btn-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #6E9EEB;
  border-color: #6E9EEB;
}
.btn-outline-purple:not(:disabled):not(.disabled):active:focus,
.btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(72, 41, 178, 0.5);
}

.btn-outline-pink {
  color: #e83e8c;
  border-color: #e83e8c;
}
.btn-outline-pink:hover {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}
.btn-outline-pink:focus,
.btn-outline-pink.focus {
  box-shadow: 0 0 0 0 rgba(232, 62, 140, 0.5);
}
.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
  color: #e83e8c;
  background-color: transparent;
}
.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}
.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(232, 62, 140, 0.5);
}

.btn-outline-red {
  color: #fc2c38;
  border-color: #fc2c38;
}
.btn-outline-red:hover {
  color: #fff;
  background-color: #fc2c38;
  border-color: #fc2c38;
}
.btn-outline-red:focus,
.btn-outline-red.focus {
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.5);
}
.btn-outline-red.disabled,
.btn-outline-red:disabled {
  color: #fc2c38;
  background-color: transparent;
}
.btn-outline-red:not(:disabled):not(.disabled):active,
.btn-outline-red:not(:disabled):not(.disabled).active,
.show > .btn-outline-red.dropdown-toggle {
  color: #fff;
  background-color: #fc2c38;
  border-color: #fc2c38;
}
.btn-outline-red:not(:disabled):not(.disabled):active:focus,
.btn-outline-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.5);
}

.btn-outline-orange {
  color: #f3a83c;
  border-color: #f3a83c;
}
.btn-outline-orange:hover {
  color: #151515;
  background-color: #f3a83c;
  border-color: #f3a83c;
}
.btn-outline-orange:focus,
.btn-outline-orange.focus {
  box-shadow: 0 0 0 0 rgba(243, 168, 60, 0.5);
}
.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
  color: #f3a83c;
  background-color: transparent;
}
.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #151515;
  background-color: #f3a83c;
  border-color: #f3a83c;
}
.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(243, 168, 60, 0.5);
}

.btn-outline-yellow {
  color: #e3c038;
  border-color: #e3c038;
}
.btn-outline-yellow:hover {
  color: #151515;
  background-color: #e3c038;
  border-color: #e3c038;
}
.btn-outline-yellow:focus,
.btn-outline-yellow.focus {
  box-shadow: 0 0 0 0 rgba(227, 192, 56, 0.5);
}
.btn-outline-yellow.disabled,
.btn-outline-yellow:disabled {
  color: #e3c038;
  background-color: transparent;
}
.btn-outline-yellow:not(:disabled):not(.disabled):active,
.btn-outline-yellow:not(:disabled):not(.disabled).active,
.show > .btn-outline-yellow.dropdown-toggle {
  color: #151515;
  background-color: #e3c038;
  border-color: #e3c038;
}
.btn-outline-yellow:not(:disabled):not(.disabled):active:focus,
.btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(227, 192, 56, 0.5);
}

.btn-outline-green {
  color: #54af5d;
  border-color: #54af5d;
}
.btn-outline-green:hover {
  color: #fff;
  background-color: #54af5d;
  border-color: #54af5d;
}
.btn-outline-green:focus,
.btn-outline-green.focus {
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.5);
}
.btn-outline-green.disabled,
.btn-outline-green:disabled {
  color: #54af5d;
  background-color: transparent;
}
.btn-outline-green:not(:disabled):not(.disabled):active,
.btn-outline-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-green.dropdown-toggle {
  color: #fff;
  background-color: #54af5d;
  border-color: #54af5d;
}
.btn-outline-green:not(:disabled):not(.disabled):active:focus,
.btn-outline-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.5);
}

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997;
}
.btn-outline-teal:hover {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-outline-teal:focus,
.btn-outline-teal.focus {
  box-shadow: 0 0 0 0 rgba(32, 201, 151, 0.5);
}
.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
  color: #20c997;
  background-color: transparent;
}
.btn-outline-teal:not(:disabled):not(.disabled):active,
.btn-outline-teal:not(:disabled):not(.disabled).active,
.show > .btn-outline-teal.dropdown-toggle {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-outline-teal:not(:disabled):not(.disabled):active:focus,
.btn-outline-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(32, 201, 151, 0.5);
}

.btn-outline-cyan {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-cyan:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-cyan:focus,
.btn-outline-cyan.focus {
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}
.btn-outline-cyan.disabled,
.btn-outline-cyan:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active,
.btn-outline-cyan:not(:disabled):not(.disabled).active,
.show > .btn-outline-cyan.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active:focus,
.btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #151515;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:focus,
.btn-outline-white.focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #151515;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-gray {
  color: #555;
  border-color: #555;
}
.btn-outline-gray:hover {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-outline-gray:focus,
.btn-outline-gray.focus {
  box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5);
}
.btn-outline-gray.disabled,
.btn-outline-gray:disabled {
  color: #555;
  background-color: transparent;
}
.btn-outline-gray:not(:disabled):not(.disabled):active,
.btn-outline-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-outline-gray:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5);
}

.btn-outline-gray-dark {
  color: #333;
  border-color: #333;
}
.btn-outline-gray-dark:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-outline-gray-dark:focus,
.btn-outline-gray-dark.focus {
  box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.5);
}
.btn-outline-gray-dark.disabled,
.btn-outline-gray-dark:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-gray-dark:not(:disabled):not(.disabled):active,
.btn-outline-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.5);
}

.btn-outline-dark-blue {
  color: #0030ae;
  border-color: #0030ae;
}
.btn-outline-dark-blue:hover {
  color: #fff;
  background-color: #0030ae;
  border-color: #0030ae;
}
.btn-outline-dark-blue:focus,
.btn-outline-dark-blue.focus {
  box-shadow: 0 0 0 0 rgba(0, 48, 174, 0.5);
}
.btn-outline-dark-blue.disabled,
.btn-outline-dark-blue:disabled {
  color: #0030ae;
  background-color: transparent;
}
.btn-outline-dark-blue:not(:disabled):not(.disabled):active,
.btn-outline-dark-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-blue.dropdown-toggle {
  color: #fff;
  background-color: #0030ae;
  border-color: #0030ae;
}
.btn-outline-dark-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 48, 174, 0.5);
}

.btn-outline-transparent-white {
  color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.btn-outline-transparent-white:hover {
  color: #151515;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.btn-outline-transparent-white:focus,
.btn-outline-transparent-white.focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-transparent-white.disabled,
.btn-outline-transparent-white:disabled {
  color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
}
.btn-outline-transparent-white:not(:disabled):not(.disabled):active,
.btn-outline-transparent-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-transparent-white.dropdown-toggle {
  color: #151515;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.btn-outline-transparent-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-transparent-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-transparent-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-transparent-black {
  color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-outline-transparent-black:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-outline-transparent-black:focus,
.btn-outline-transparent-black.focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}
.btn-outline-transparent-black.disabled,
.btn-outline-transparent-black:disabled {
  color: rgba(0, 0, 0, 0.2);
  background-color: transparent;
}
.btn-outline-transparent-black:not(:disabled):not(.disabled):active,
.btn-outline-transparent-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-transparent-black.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-outline-transparent-black:not(:disabled):not(.disabled):active:focus,
.btn-outline-transparent-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-transparent-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
}
.btn-facebook:focus,
.btn-facebook.focus {
  box-shadow: 0 0 0 0 rgba(88, 114, 167, 0.5);
}
.btn-facebook.disabled,
.btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #293e6a;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(88, 114, 167, 0.5);
}

.btn-google-plus {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-google-plus:hover {
  color: #fff;
  background-color: #cd3623;
  border-color: #c23321;
}
.btn-google-plus:focus,
.btn-google-plus.focus {
  box-shadow: 0 0 0 0 rgba(226, 102, 87, 0.5);
}
.btn-google-plus.disabled,
.btn-google-plus:disabled {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-google-plus:not(:disabled):not(.disabled):active,
.btn-google-plus:not(:disabled):not(.disabled).active,
.show > .btn-google-plus.dropdown-toggle {
  color: #fff;
  background-color: #c23321;
  border-color: #b7301f;
}
.btn-google-plus:not(:disabled):not(.disabled):active:focus,
.btn-google-plus:not(:disabled):not(.disabled).active:focus,
.show > .btn-google-plus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(226, 102, 87, 0.5);
}

.btn-twitter {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}
.btn-twitter:hover {
  color: #fff;
  background-color: #0090c7;
  border-color: #0087ba;
}
.btn-twitter:focus,
.btn-twitter.focus {
  box-shadow: 0 0 0 0 rgba(38, 184, 240, 0.5);
}
.btn-twitter.disabled,
.btn-twitter:disabled {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}
.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0087ba;
  border-color: #007ead;
}
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 184, 240, 0.5);
}

.btn-linkedin {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6;
}
.btn-linkedin:hover {
  color: #fff;
  background-color: #006190;
  border-color: #005983;
}
.btn-linkedin:focus,
.btn-linkedin.focus {
  box-shadow: 0 0 0 0 rgba(38, 143, 193, 0.5);
}
.btn-linkedin.disabled,
.btn-linkedin:disabled {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6;
}
.btn-linkedin:not(:disabled):not(.disabled):active,
.btn-linkedin:not(:disabled):not(.disabled).active,
.show > .btn-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #005983;
  border-color: #005076;
}
.btn-linkedin:not(:disabled):not(.disabled):active:focus,
.btn-linkedin:not(:disabled):not(.disabled).active:focus,
.show > .btn-linkedin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 143, 193, 0.5);
}

.btn-pinterest {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
}
.btn-pinterest:hover {
  color: #fff;
  background-color: #aa1b21;
  border-color: #9f191f;
}
.btn-pinterest:focus,
.btn-pinterest.focus {
  box-shadow: 0 0 0 0 rgba(211, 65, 71, 0.5);
}
.btn-pinterest.disabled,
.btn-pinterest:disabled {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
}
.btn-pinterest:not(:disabled):not(.disabled):active,
.btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #9f191f;
  border-color: #94171c;
}
.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 65, 71, 0.5);
}

.btn-git {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-git:hover {
  color: #fff;
  background-color: #535353;
  border-color: #4d4d4d;
}
.btn-git:focus,
.btn-git.focus {
  box-shadow: 0 0 0 0 rgba(125, 125, 125, 0.5);
}
.btn-git.disabled,
.btn-git:disabled {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-git:not(:disabled):not(.disabled):active,
.btn-git:not(:disabled):not(.disabled).active,
.show > .btn-git.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #464646;
}
.btn-git:not(:disabled):not(.disabled):active:focus,
.btn-git:not(:disabled):not(.disabled).active:focus,
.show > .btn-git.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(125, 125, 125, 0.5);
}

.btn-tumblr {
  color: #fff;
  background-color: #32506d;
  border-color: #32506d;
}
.btn-tumblr:hover {
  color: #fff;
  background-color: #263d53;
  border-color: #22364a;
}
.btn-tumblr:focus,
.btn-tumblr.focus {
  box-shadow: 0 0 0 0 rgba(81, 106, 131, 0.5);
}
.btn-tumblr.disabled,
.btn-tumblr:disabled {
  color: #fff;
  background-color: #32506d;
  border-color: #32506d;
}
.btn-tumblr:not(:disabled):not(.disabled):active,
.btn-tumblr:not(:disabled):not(.disabled).active,
.show > .btn-tumblr.dropdown-toggle {
  color: #fff;
  background-color: #22364a;
  border-color: #1e3041;
}
.btn-tumblr:not(:disabled):not(.disabled):active:focus,
.btn-tumblr:not(:disabled):not(.disabled).active:focus,
.show > .btn-tumblr.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(81, 106, 131, 0.5);
}

.btn-vimeo {
  color: #151515;
  background-color: #aad450;
  border-color: #aad450;
}
.btn-vimeo:hover {
  color: #151515;
  background-color: #9bcc32;
  border-color: #93c130;
}
.btn-vimeo:focus,
.btn-vimeo.focus {
  box-shadow: 0 0 0 0 rgba(148, 183, 71, 0.5);
}
.btn-vimeo.disabled,
.btn-vimeo:disabled {
  color: #151515;
  background-color: #aad450;
  border-color: #aad450;
}
.btn-vimeo:not(:disabled):not(.disabled):active,
.btn-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-vimeo.dropdown-toggle {
  color: #151515;
  background-color: #93c130;
  border-color: #8bb72d;
}
.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(148, 183, 71, 0.5);
}

.btn-flickr {
  color: #fff;
  background-color: #ff0084;
  border-color: #ff0084;
}
.btn-flickr:hover {
  color: #fff;
  background-color: #d90070;
  border-color: #cc006a;
}
.btn-flickr:focus,
.btn-flickr.focus {
  box-shadow: 0 0 0 0 rgba(255, 38, 150, 0.5);
}
.btn-flickr.disabled,
.btn-flickr:disabled {
  color: #fff;
  background-color: #ff0084;
  border-color: #ff0084;
}
.btn-flickr:not(:disabled):not(.disabled):active,
.btn-flickr:not(:disabled):not(.disabled).active,
.show > .btn-flickr.dropdown-toggle {
  color: #fff;
  background-color: #cc006a;
  border-color: #bf0063;
}
.btn-flickr:not(:disabled):not(.disabled):active:focus,
.btn-flickr:not(:disabled):not(.disabled).active:focus,
.show > .btn-flickr.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 38, 150, 0.5);
}

.btn-reddit {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500;
}
.btn-reddit:hover {
  color: #fff;
  background-color: #d93b00;
  border-color: #cc3700;
}
.btn-reddit:focus,
.btn-reddit.focus {
  box-shadow: 0 0 0 0 rgba(255, 97, 38, 0.5);
}
.btn-reddit.disabled,
.btn-reddit:disabled {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500;
}
.btn-reddit:not(:disabled):not(.disabled):active,
.btn-reddit:not(:disabled):not(.disabled).active,
.show > .btn-reddit.dropdown-toggle {
  color: #fff;
  background-color: #cc3700;
  border-color: #bf3400;
}
.btn-reddit:not(:disabled):not(.disabled):active:focus,
.btn-reddit:not(:disabled):not(.disabled).active:focus,
.show > .btn-reddit.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 97, 38, 0.5);
}

.btn-dribbble {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:hover {
  color: #fff;
  background-color: #e62a72;
  border-color: #e51e6b;
}
.btn-dribbble:focus,
.btn-dribbble.focus {
  box-shadow: 0 0 0 0 rgba(237, 103, 155, 0.5);
}
.btn-dribbble.disabled,
.btn-dribbble:disabled {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled):active,
.btn-dribbble:not(:disabled):not(.disabled).active,
.show > .btn-dribbble.dropdown-toggle {
  color: #fff;
  background-color: #e51e6b;
  border-color: #dc1a65;
}
.btn-dribbble:not(:disabled):not(.disabled):active:focus,
.btn-dribbble:not(:disabled):not(.disabled).active:focus,
.show > .btn-dribbble.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(237, 103, 155, 0.5);
}

.btn-skype {
  color: #fff;
  background-color: #00aff0;
  border-color: #00aff0;
}
.btn-skype:hover {
  color: #fff;
  background-color: #0093ca;
  border-color: #008abd;
}
.btn-skype:focus,
.btn-skype.focus {
  box-shadow: 0 0 0 0 rgba(38, 187, 242, 0.5);
}
.btn-skype.disabled,
.btn-skype:disabled {
  color: #fff;
  background-color: #00aff0;
  border-color: #00aff0;
}
.btn-skype:not(:disabled):not(.disabled):active,
.btn-skype:not(:disabled):not(.disabled).active,
.show > .btn-skype.dropdown-toggle {
  color: #fff;
  background-color: #008abd;
  border-color: #0081b0;
}
.btn-skype:not(:disabled):not(.disabled):active:focus,
.btn-skype:not(:disabled):not(.disabled).active:focus,
.show > .btn-skype.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 187, 242, 0.5);
}

.btn-instagram {
  color: #fff;
  background-color: #517fa4;
  border-color: #517fa4;
}
.btn-instagram:hover {
  color: #fff;
  background-color: #446b8a;
  border-color: #406582;
}
.btn-instagram:focus,
.btn-instagram.focus {
  box-shadow: 0 0 0 0 rgba(107, 146, 178, 0.5);
}
.btn-instagram.disabled,
.btn-instagram:disabled {
  color: #fff;
  background-color: #517fa4;
  border-color: #517fa4;
}
.btn-instagram:not(:disabled):not(.disabled):active,
.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #406582;
  border-color: #3c5e79;
}
.btn-instagram:not(:disabled):not(.disabled):active:focus,
.btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(107, 146, 178, 0.5);
}

.btn-behance {
  color: #fff;
  background-color: #1769ff;
  border-color: #1769ff;
}
.btn-behance:hover {
  color: #fff;
  background-color: #0055f0;
  border-color: #0050e3;
}
.btn-behance:focus,
.btn-behance.focus {
  box-shadow: 0 0 0 0 rgba(58, 128, 255, 0.5);
}
.btn-behance.disabled,
.btn-behance:disabled {
  color: #fff;
  background-color: #1769ff;
  border-color: #1769ff;
}
.btn-behance:not(:disabled):not(.disabled):active,
.btn-behance:not(:disabled):not(.disabled).active,
.show > .btn-behance.dropdown-toggle {
  color: #fff;
  background-color: #0050e3;
  border-color: #004cd6;
}
.btn-behance:not(:disabled):not(.disabled):active:focus,
.btn-behance:not(:disabled):not(.disabled).active:focus,
.show > .btn-behance.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(58, 128, 255, 0.5);
}

.btn-rss {
  color: #fff;
  background-color: #f26522;
  border-color: #f26522;
}
.btn-rss:hover {
  color: #fff;
  background-color: #e1510d;
  border-color: #d54d0d;
}
.btn-rss:focus,
.btn-rss.focus {
  box-shadow: 0 0 0 0 rgba(244, 124, 67, 0.5);
}
.btn-rss.disabled,
.btn-rss:disabled {
  color: #fff;
  background-color: #f26522;
  border-color: #f26522;
}
.btn-rss:not(:disabled):not(.disabled):active,
.btn-rss:not(:disabled):not(.disabled).active,
.show > .btn-rss.dropdown-toggle {
  color: #fff;
  background-color: #d54d0d;
  border-color: #c8490c;
}
.btn-rss:not(:disabled):not(.disabled):active:focus,
.btn-rss:not(:disabled):not(.disabled).active:focus,
.show > .btn-rss.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(244, 124, 67, 0.5);
}

.btn-outline-facebook {
  color: #3b5998;
  border-color: #3b5998;
}
.btn-outline-facebook:hover {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-outline-facebook:focus,
.btn-outline-facebook.focus {
  box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.5);
}
.btn-outline-facebook.disabled,
.btn-outline-facebook:disabled {
  color: #3b5998;
  background-color: transparent;
}
.btn-outline-facebook:not(:disabled):not(.disabled):active,
.btn-outline-facebook:not(:disabled):not(.disabled).active,
.show > .btn-outline-facebook.dropdown-toggle {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-outline-facebook:not(:disabled):not(.disabled):active:focus,
.btn-outline-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.5);
}

.btn-outline-google-plus {
  color: #dd4b39;
  border-color: #dd4b39;
}
.btn-outline-google-plus:hover {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-outline-google-plus:focus,
.btn-outline-google-plus.focus {
  box-shadow: 0 0 0 0 rgba(221, 75, 57, 0.5);
}
.btn-outline-google-plus.disabled,
.btn-outline-google-plus:disabled {
  color: #dd4b39;
  background-color: transparent;
}
.btn-outline-google-plus:not(:disabled):not(.disabled):active,
.btn-outline-google-plus:not(:disabled):not(.disabled).active,
.show > .btn-outline-google-plus.dropdown-toggle {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-outline-google-plus:not(:disabled):not(.disabled):active:focus,
.btn-outline-google-plus:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-google-plus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221, 75, 57, 0.5);
}

.btn-outline-twitter {
  color: #00aced;
  border-color: #00aced;
}
.btn-outline-twitter:hover {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}
.btn-outline-twitter:focus,
.btn-outline-twitter.focus {
  box-shadow: 0 0 0 0 rgba(0, 172, 237, 0.5);
}
.btn-outline-twitter.disabled,
.btn-outline-twitter:disabled {
  color: #00aced;
  background-color: transparent;
}
.btn-outline-twitter:not(:disabled):not(.disabled):active,
.btn-outline-twitter:not(:disabled):not(.disabled).active,
.show > .btn-outline-twitter.dropdown-toggle {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}
.btn-outline-twitter:not(:disabled):not(.disabled):active:focus,
.btn-outline-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 172, 237, 0.5);
}

.btn-outline-linkedin {
  color: #007bb6;
  border-color: #007bb6;
}
.btn-outline-linkedin:hover {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6;
}
.btn-outline-linkedin:focus,
.btn-outline-linkedin.focus {
  box-shadow: 0 0 0 0 rgba(0, 123, 182, 0.5);
}
.btn-outline-linkedin.disabled,
.btn-outline-linkedin:disabled {
  color: #007bb6;
  background-color: transparent;
}
.btn-outline-linkedin:not(:disabled):not(.disabled):active,
.btn-outline-linkedin:not(:disabled):not(.disabled).active,
.show > .btn-outline-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6;
}
.btn-outline-linkedin:not(:disabled):not(.disabled):active:focus,
.btn-outline-linkedin:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-linkedin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 123, 182, 0.5);
}

.btn-outline-pinterest {
  color: #cb2027;
  border-color: #cb2027;
}
.btn-outline-pinterest:hover {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
}
.btn-outline-pinterest:focus,
.btn-outline-pinterest.focus {
  box-shadow: 0 0 0 0 rgba(203, 32, 39, 0.5);
}
.btn-outline-pinterest.disabled,
.btn-outline-pinterest:disabled {
  color: #cb2027;
  background-color: transparent;
}
.btn-outline-pinterest:not(:disabled):not(.disabled):active,
.btn-outline-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-outline-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
}
.btn-outline-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-outline-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(203, 32, 39, 0.5);
}

.btn-outline-git {
  color: #666666;
  border-color: #666666;
}
.btn-outline-git:hover {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-outline-git:focus,
.btn-outline-git.focus {
  box-shadow: 0 0 0 0 rgba(102, 102, 102, 0.5);
}
.btn-outline-git.disabled,
.btn-outline-git:disabled {
  color: #666666;
  background-color: transparent;
}
.btn-outline-git:not(:disabled):not(.disabled):active,
.btn-outline-git:not(:disabled):not(.disabled).active,
.show > .btn-outline-git.dropdown-toggle {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-outline-git:not(:disabled):not(.disabled):active:focus,
.btn-outline-git:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-git.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(102, 102, 102, 0.5);
}

.btn-outline-tumblr {
  color: #32506d;
  border-color: #32506d;
}
.btn-outline-tumblr:hover {
  color: #fff;
  background-color: #32506d;
  border-color: #32506d;
}
.btn-outline-tumblr:focus,
.btn-outline-tumblr.focus {
  box-shadow: 0 0 0 0 rgba(50, 80, 109, 0.5);
}
.btn-outline-tumblr.disabled,
.btn-outline-tumblr:disabled {
  color: #32506d;
  background-color: transparent;
}
.btn-outline-tumblr:not(:disabled):not(.disabled):active,
.btn-outline-tumblr:not(:disabled):not(.disabled).active,
.show > .btn-outline-tumblr.dropdown-toggle {
  color: #fff;
  background-color: #32506d;
  border-color: #32506d;
}
.btn-outline-tumblr:not(:disabled):not(.disabled):active:focus,
.btn-outline-tumblr:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-tumblr.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(50, 80, 109, 0.5);
}

.btn-outline-vimeo {
  color: #aad450;
  border-color: #aad450;
}
.btn-outline-vimeo:hover {
  color: #151515;
  background-color: #aad450;
  border-color: #aad450;
}
.btn-outline-vimeo:focus,
.btn-outline-vimeo.focus {
  box-shadow: 0 0 0 0 rgba(170, 212, 80, 0.5);
}
.btn-outline-vimeo.disabled,
.btn-outline-vimeo:disabled {
  color: #aad450;
  background-color: transparent;
}
.btn-outline-vimeo:not(:disabled):not(.disabled):active,
.btn-outline-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-outline-vimeo.dropdown-toggle {
  color: #151515;
  background-color: #aad450;
  border-color: #aad450;
}
.btn-outline-vimeo:not(:disabled):not(.disabled):active:focus,
.btn-outline-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-vimeo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(170, 212, 80, 0.5);
}

.btn-outline-flickr {
  color: #ff0084;
  border-color: #ff0084;
}
.btn-outline-flickr:hover {
  color: #fff;
  background-color: #ff0084;
  border-color: #ff0084;
}
.btn-outline-flickr:focus,
.btn-outline-flickr.focus {
  box-shadow: 0 0 0 0 rgba(255, 0, 132, 0.5);
}
.btn-outline-flickr.disabled,
.btn-outline-flickr:disabled {
  color: #ff0084;
  background-color: transparent;
}
.btn-outline-flickr:not(:disabled):not(.disabled):active,
.btn-outline-flickr:not(:disabled):not(.disabled).active,
.show > .btn-outline-flickr.dropdown-toggle {
  color: #fff;
  background-color: #ff0084;
  border-color: #ff0084;
}
.btn-outline-flickr:not(:disabled):not(.disabled):active:focus,
.btn-outline-flickr:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-flickr.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 0, 132, 0.5);
}

.btn-outline-reddit {
  color: #ff4500;
  border-color: #ff4500;
}
.btn-outline-reddit:hover {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500;
}
.btn-outline-reddit:focus,
.btn-outline-reddit.focus {
  box-shadow: 0 0 0 0 rgba(255, 69, 0, 0.5);
}
.btn-outline-reddit.disabled,
.btn-outline-reddit:disabled {
  color: #ff4500;
  background-color: transparent;
}
.btn-outline-reddit:not(:disabled):not(.disabled):active,
.btn-outline-reddit:not(:disabled):not(.disabled).active,
.show > .btn-outline-reddit.dropdown-toggle {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500;
}
.btn-outline-reddit:not(:disabled):not(.disabled):active:focus,
.btn-outline-reddit:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-reddit.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 69, 0, 0.5);
}

.btn-outline-dribbble {
  color: #ea4c89;
  border-color: #ea4c89;
}
.btn-outline-dribbble:hover {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-outline-dribbble:focus,
.btn-outline-dribbble.focus {
  box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.5);
}
.btn-outline-dribbble.disabled,
.btn-outline-dribbble:disabled {
  color: #ea4c89;
  background-color: transparent;
}
.btn-outline-dribbble:not(:disabled):not(.disabled):active,
.btn-outline-dribbble:not(:disabled):not(.disabled).active,
.show > .btn-outline-dribbble.dropdown-toggle {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-outline-dribbble:not(:disabled):not(.disabled):active:focus,
.btn-outline-dribbble:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dribbble.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.5);
}

.btn-outline-skype {
  color: #00aff0;
  border-color: #00aff0;
}
.btn-outline-skype:hover {
  color: #fff;
  background-color: #00aff0;
  border-color: #00aff0;
}
.btn-outline-skype:focus,
.btn-outline-skype.focus {
  box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.5);
}
.btn-outline-skype.disabled,
.btn-outline-skype:disabled {
  color: #00aff0;
  background-color: transparent;
}
.btn-outline-skype:not(:disabled):not(.disabled):active,
.btn-outline-skype:not(:disabled):not(.disabled).active,
.show > .btn-outline-skype.dropdown-toggle {
  color: #fff;
  background-color: #00aff0;
  border-color: #00aff0;
}
.btn-outline-skype:not(:disabled):not(.disabled):active:focus,
.btn-outline-skype:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-skype.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.5);
}

.btn-outline-instagram {
  color: #517fa4;
  border-color: #517fa4;
}
.btn-outline-instagram:hover {
  color: #fff;
  background-color: #517fa4;
  border-color: #517fa4;
}
.btn-outline-instagram:focus,
.btn-outline-instagram.focus {
  box-shadow: 0 0 0 0 rgba(81, 127, 164, 0.5);
}
.btn-outline-instagram.disabled,
.btn-outline-instagram:disabled {
  color: #517fa4;
  background-color: transparent;
}
.btn-outline-instagram:not(:disabled):not(.disabled):active,
.btn-outline-instagram:not(:disabled):not(.disabled).active,
.show > .btn-outline-instagram.dropdown-toggle {
  color: #fff;
  background-color: #517fa4;
  border-color: #517fa4;
}
.btn-outline-instagram:not(:disabled):not(.disabled):active:focus,
.btn-outline-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(81, 127, 164, 0.5);
}

.btn-outline-behance {
  color: #1769ff;
  border-color: #1769ff;
}
.btn-outline-behance:hover {
  color: #fff;
  background-color: #1769ff;
  border-color: #1769ff;
}
.btn-outline-behance:focus,
.btn-outline-behance.focus {
  box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.5);
}
.btn-outline-behance.disabled,
.btn-outline-behance:disabled {
  color: #1769ff;
  background-color: transparent;
}
.btn-outline-behance:not(:disabled):not(.disabled):active,
.btn-outline-behance:not(:disabled):not(.disabled).active,
.show > .btn-outline-behance.dropdown-toggle {
  color: #fff;
  background-color: #1769ff;
  border-color: #1769ff;
}
.btn-outline-behance:not(:disabled):not(.disabled):active:focus,
.btn-outline-behance:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-behance.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.5);
}

.btn-outline-rss {
  color: #f26522;
  border-color: #f26522;
}
.btn-outline-rss:hover {
  color: #fff;
  background-color: #f26522;
  border-color: #f26522;
}
.btn-outline-rss:focus,
.btn-outline-rss.focus {
  box-shadow: 0 0 0 0 rgba(242, 101, 34, 0.5);
}
.btn-outline-rss.disabled,
.btn-outline-rss:disabled {
  color: #f26522;
  background-color: transparent;
}
.btn-outline-rss:not(:disabled):not(.disabled):active,
.btn-outline-rss:not(:disabled):not(.disabled).active,
.show > .btn-outline-rss.dropdown-toggle {
  color: #fff;
  background-color: #f26522;
  border-color: #f26522;
}
.btn-outline-rss:not(:disabled):not(.disabled):active:focus,
.btn-outline-rss:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-rss.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(242, 101, 34, 0.5);
}

.btn-100 {
  color: #151515;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-100:hover {
  color: #151515;
  background-color: #e2e2e2;
  border-color: gainsboro;
}
.btn-100:focus,
.btn-100.focus {
  box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5);
}
.btn-100.disabled,
.btn-100:disabled {
  color: #151515;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-100:not(:disabled):not(.disabled):active,
.btn-100:not(:disabled):not(.disabled).active,
.show > .btn-100.dropdown-toggle {
  color: #151515;
  background-color: gainsboro;
  border-color: #d5d5d5;
}
.btn-100:not(:disabled):not(.disabled):active:focus,
.btn-100:not(:disabled):not(.disabled).active:focus,
.show > .btn-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5);
}

.btn-200 {
  color: #151515;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-200:hover {
  color: #151515;
  background-color: #d2d2d2;
  border-color: #cccccc;
}
.btn-200:focus,
.btn-200.focus {
  box-shadow: 0 0 0 0 rgba(198, 198, 198, 0.5);
}
.btn-200.disabled,
.btn-200:disabled {
  color: #151515;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-200:not(:disabled):not(.disabled):active,
.btn-200:not(:disabled):not(.disabled).active,
.show > .btn-200.dropdown-toggle {
  color: #151515;
  background-color: #cccccc;
  border-color: #c5c5c5;
}
.btn-200:not(:disabled):not(.disabled):active:focus,
.btn-200:not(:disabled):not(.disabled).active:focus,
.show > .btn-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(198, 198, 198, 0.5);
}

.btn-300 {
  color: #151515;
  background-color: #999;
  border-color: #999;
}
.btn-300:hover {
  color: #fff;
  background-color: #868686;
  border-color: gray;
}
.btn-300:focus,
.btn-300.focus {
  box-shadow: 0 0 0 0 rgba(133, 133, 133, 0.5);
}
.btn-300.disabled,
.btn-300:disabled {
  color: #151515;
  background-color: #999;
  border-color: #999;
}
.btn-300:not(:disabled):not(.disabled):active,
.btn-300:not(:disabled):not(.disabled).active,
.show > .btn-300.dropdown-toggle {
  color: #fff;
  background-color: gray;
  border-color: #797979;
}
.btn-300:not(:disabled):not(.disabled):active:focus,
.btn-300:not(:disabled):not(.disabled).active:focus,
.show > .btn-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(133, 133, 133, 0.5);
}

.btn-400 {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-400:hover {
  color: #fff;
  background-color: #757575;
  border-color: #6f6f6f;
}
.btn-400:focus,
.btn-400.focus {
  box-shadow: 0 0 0 0 rgba(154, 154, 154, 0.5);
}
.btn-400.disabled,
.btn-400:disabled {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-400:not(:disabled):not(.disabled):active,
.btn-400:not(:disabled):not(.disabled).active,
.show > .btn-400.dropdown-toggle {
  color: #fff;
  background-color: #6f6f6f;
  border-color: #686868;
}
.btn-400:not(:disabled):not(.disabled):active:focus,
.btn-400:not(:disabled):not(.disabled).active:focus,
.show > .btn-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(154, 154, 154, 0.5);
}

.btn-500 {
  color: #fff;
  background-color: #777;
  border-color: #777;
}
.btn-500:hover {
  color: #fff;
  background-color: #646464;
  border-color: #5e5e5e;
}
.btn-500:focus,
.btn-500.focus {
  box-shadow: 0 0 0 0 rgba(139, 139, 139, 0.5);
}
.btn-500.disabled,
.btn-500:disabled {
  color: #fff;
  background-color: #777;
  border-color: #777;
}
.btn-500:not(:disabled):not(.disabled):active,
.btn-500:not(:disabled):not(.disabled).active,
.show > .btn-500.dropdown-toggle {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #575757;
}
.btn-500:not(:disabled):not(.disabled):active:focus,
.btn-500:not(:disabled):not(.disabled).active:focus,
.show > .btn-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(139, 139, 139, 0.5);
}

.btn-600 {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-600:hover {
  color: #fff;
  background-color: #424242;
  border-color: #3c3c3c;
}
.btn-600:focus,
.btn-600.focus {
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}
.btn-600.disabled,
.btn-600:disabled {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-600:not(:disabled):not(.disabled):active,
.btn-600:not(:disabled):not(.disabled).active,
.show > .btn-600.dropdown-toggle {
  color: #fff;
  background-color: #3c3c3c;
  border-color: #353535;
}
.btn-600:not(:disabled):not(.disabled):active:focus,
.btn-600:not(:disabled):not(.disabled).active:focus,
.show > .btn-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}

.btn-700 {
  color: #fff;
  background-color: #444;
  border-color: #444;
}
.btn-700:hover {
  color: #fff;
  background-color: #313131;
  border-color: #2b2b2b;
}
.btn-700:focus,
.btn-700.focus {
  box-shadow: 0 0 0 0 rgba(96, 96, 96, 0.5);
}
.btn-700.disabled,
.btn-700:disabled {
  color: #fff;
  background-color: #444;
  border-color: #444;
}
.btn-700:not(:disabled):not(.disabled):active,
.btn-700:not(:disabled):not(.disabled).active,
.show > .btn-700.dropdown-toggle {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #242424;
}
.btn-700:not(:disabled):not(.disabled):active:focus,
.btn-700:not(:disabled):not(.disabled).active:focus,
.show > .btn-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(96, 96, 96, 0.5);
}

.btn-800 {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-800:hover {
  color: #fff;
  background-color: #202020;
  border-color: #1a1a1a;
}
.btn-800:focus,
.btn-800.focus {
  box-shadow: 0 0 0 0 rgba(82, 82, 82, 0.5);
}
.btn-800.disabled,
.btn-800:disabled {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-800:not(:disabled):not(.disabled):active,
.btn-800:not(:disabled):not(.disabled).active,
.show > .btn-800.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #131313;
}
.btn-800:not(:disabled):not(.disabled):active:focus,
.btn-800:not(:disabled):not(.disabled).active:focus,
.show > .btn-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 82, 82, 0.5);
}

.btn-900 {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}
.btn-900:hover {
  color: #fff;
  background-color: #020202;
  border-color: black;
}
.btn-900:focus,
.btn-900.focus {
  box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5);
}
.btn-900.disabled,
.btn-900:disabled {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}
.btn-900:not(:disabled):not(.disabled):active,
.btn-900:not(:disabled):not(.disabled).active,
.show > .btn-900.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-900:not(:disabled):not(.disabled):active:focus,
.btn-900:not(:disabled):not(.disabled).active:focus,
.show > .btn-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5);
}

.btn-outline-100 {
  color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-100:hover {
  color: #151515;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-100:focus,
.btn-outline-100.focus {
  box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
}
.btn-outline-100.disabled,
.btn-outline-100:disabled {
  color: #f5f5f5;
  background-color: transparent;
}
.btn-outline-100:not(:disabled):not(.disabled):active,
.btn-outline-100:not(:disabled):not(.disabled).active,
.show > .btn-outline-100.dropdown-toggle {
  color: #151515;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-100:not(:disabled):not(.disabled):active:focus,
.btn-outline-100:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5);
}

.btn-outline-200 {
  color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-outline-200:hover {
  color: #151515;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-outline-200:focus,
.btn-outline-200.focus {
  box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5);
}
.btn-outline-200.disabled,
.btn-outline-200:disabled {
  color: #e5e5e5;
  background-color: transparent;
}
.btn-outline-200:not(:disabled):not(.disabled):active,
.btn-outline-200:not(:disabled):not(.disabled).active,
.show > .btn-outline-200.dropdown-toggle {
  color: #151515;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-outline-200:not(:disabled):not(.disabled):active:focus,
.btn-outline-200:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5);
}

.btn-outline-300 {
  color: #999;
  border-color: #999;
}
.btn-outline-300:hover {
  color: #151515;
  background-color: #999;
  border-color: #999;
}
.btn-outline-300:focus,
.btn-outline-300.focus {
  box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5);
}
.btn-outline-300.disabled,
.btn-outline-300:disabled {
  color: #999;
  background-color: transparent;
}
.btn-outline-300:not(:disabled):not(.disabled):active,
.btn-outline-300:not(:disabled):not(.disabled).active,
.show > .btn-outline-300.dropdown-toggle {
  color: #151515;
  background-color: #999;
  border-color: #999;
}
.btn-outline-300:not(:disabled):not(.disabled):active:focus,
.btn-outline-300:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5);
}

.btn-outline-400 {
  color: #888;
  border-color: #888;
}
.btn-outline-400:hover {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-outline-400:focus,
.btn-outline-400.focus {
  box-shadow: 0 0 0 0 rgba(136, 136, 136, 0.5);
}
.btn-outline-400.disabled,
.btn-outline-400:disabled {
  color: #888;
  background-color: transparent;
}
.btn-outline-400:not(:disabled):not(.disabled):active,
.btn-outline-400:not(:disabled):not(.disabled).active,
.show > .btn-outline-400.dropdown-toggle {
  color: #fff;
  background-color: #888;
  border-color: #888;
}
.btn-outline-400:not(:disabled):not(.disabled):active:focus,
.btn-outline-400:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(136, 136, 136, 0.5);
}

.btn-outline-500 {
  color: #777;
  border-color: #777;
}
.btn-outline-500:hover {
  color: #fff;
  background-color: #777;
  border-color: #777;
}
.btn-outline-500:focus,
.btn-outline-500.focus {
  box-shadow: 0 0 0 0 rgba(119, 119, 119, 0.5);
}
.btn-outline-500.disabled,
.btn-outline-500:disabled {
  color: #777;
  background-color: transparent;
}
.btn-outline-500:not(:disabled):not(.disabled):active,
.btn-outline-500:not(:disabled):not(.disabled).active,
.show > .btn-outline-500.dropdown-toggle {
  color: #fff;
  background-color: #777;
  border-color: #777;
}
.btn-outline-500:not(:disabled):not(.disabled):active:focus,
.btn-outline-500:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(119, 119, 119, 0.5);
}

.btn-outline-600 {
  color: #555;
  border-color: #555;
}
.btn-outline-600:hover {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-outline-600:focus,
.btn-outline-600.focus {
  box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5);
}
.btn-outline-600.disabled,
.btn-outline-600:disabled {
  color: #555;
  background-color: transparent;
}
.btn-outline-600:not(:disabled):not(.disabled):active,
.btn-outline-600:not(:disabled):not(.disabled).active,
.show > .btn-outline-600.dropdown-toggle {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-outline-600:not(:disabled):not(.disabled):active:focus,
.btn-outline-600:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5);
}

.btn-outline-700 {
  color: #444;
  border-color: #444;
}
.btn-outline-700:hover {
  color: #fff;
  background-color: #444;
  border-color: #444;
}
.btn-outline-700:focus,
.btn-outline-700.focus {
  box-shadow: 0 0 0 0 rgba(68, 68, 68, 0.5);
}
.btn-outline-700.disabled,
.btn-outline-700:disabled {
  color: #444;
  background-color: transparent;
}
.btn-outline-700:not(:disabled):not(.disabled):active,
.btn-outline-700:not(:disabled):not(.disabled).active,
.show > .btn-outline-700.dropdown-toggle {
  color: #fff;
  background-color: #444;
  border-color: #444;
}
.btn-outline-700:not(:disabled):not(.disabled):active:focus,
.btn-outline-700:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(68, 68, 68, 0.5);
}

.btn-outline-800 {
  color: #333;
  border-color: #333;
}
.btn-outline-800:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-outline-800:focus,
.btn-outline-800.focus {
  box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.5);
}
.btn-outline-800.disabled,
.btn-outline-800:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-800:not(:disabled):not(.disabled):active,
.btn-outline-800:not(:disabled):not(.disabled).active,
.show > .btn-outline-800.dropdown-toggle {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-outline-800:not(:disabled):not(.disabled):active:focus,
.btn-outline-800:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.5);
}

.btn-outline-900 {
  color: #151515;
  border-color: #151515;
}
.btn-outline-900:hover {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}
.btn-outline-900:focus,
.btn-outline-900.focus {
  box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5);
}
.btn-outline-900.disabled,
.btn-outline-900:disabled {
  color: #151515;
  background-color: transparent;
}
.btn-outline-900:not(:disabled):not(.disabled):active,
.btn-outline-900:not(:disabled):not(.disabled).active,
.show > .btn-outline-900.dropdown-toggle {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}
.btn-outline-900:not(:disabled):not(.disabled):active:focus,
.btn-outline-900:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5);
}

.btn-rounded {
  border-radius: 10rem;
}

.btn-with-ico {
  position: relative;
  padding-left: 5.25rem;
}
.btn-with-ico [class*="icon"] {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  line-height: 3.375rem;
  font-size: 24px;
  width: 3.375rem;
  background: rgba(0, 0, 0, 0.1);
}
.btn-with-ico.btn-lg,
.btn-group-lg > .btn-with-ico.btn {
  padding-right: 5.9rem;
}
.btn-with-ico.btn-lg [class*="icon"],
.btn-group-lg > .btn-with-ico.btn [class*="icon"] {
  line-height: 3.7rem;
  width: 3.7rem;
}
.btn-with-ico.btn-sm,
.btn-group-sm > .btn-with-ico.btn {
  padding-right: 3.5rem;
}
.btn-with-ico.btn-sm [class*="icon"],
.btn-group-sm > .btn-with-ico.btn [class*="icon"] {
  line-height: 2.5rem;
  width: 2.5rem;
}
.btn-with-ico.btn-rounded [class*="icon"] {
  border-radius: 50%;
}
.btn-with-ico[class*="-outline-"] {
  padding-left: 3.84375rem;
}
.btn-with-ico[class*="-outline-"] [class*="icon"] {
  background: transparent;
}
.btn-with-ico[class*="-outline-"].btn-lg,
.btn-group-lg > .btn-with-ico.btn[class*="-outline-"] {
  padding-right: 4.25rem;
}
.btn-with-ico[class*="-outline-"].btn-sm,
.btn-group-sm > .btn-with-ico.btn[class*="-outline-"] {
  padding-right: 2.75rem;
}

.btn-ico {
  position: relative;
  width: 3.375rem;
  height: 3.375rem;
  padding: 0;
}
.btn-ico [class*="icon"] {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.btn-ico.btn-sm,
.btn-group-sm > .btn-ico.btn {
  width: 2.5rem;
  height: 2.5rem;
}
.btn-ico.btn-lg,
.btn-group-lg > .btn-ico.btn {
  width: 3.7rem;
  height: 3.7rem;
}
.btn-ico.btn-xl {
  width: 5.9rem;
  height: 5.9rem;
}

.partners .owl-stage-outer .owl-stage {
  display: -ms-flexbox !important;
  display: flex !important;
}
.partners .owl-stage-outer .owl-stage .owl-item {
  text-align: center;
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.partners .owl-stage-outer .owl-stage .owl-item img {
  display: inline-block;
  width: initial;
  max-width: 60%;
}

.blockquote {
  position: relative;
  margin: inherit;
  padding: 2rem 0 0 2rem;
  color: #000;
}
.blockquote::before {
  content: "\201C";
  position: absolute;
  display: inline;
  top: -3rem;
  left: -1rem;
  color: inherit;
  font-family: "Nunito Sans", sans-serif;
  font-size: 30rem;
  color: rgba(0, 0, 0, 0.1);
  line-height: 1em;
  z-index: 1;
}
.blockquote p {
  position: relative;
  color: inherit;
  font-size: 1.625rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  z-index: 5;
}

.blockquote-footer {
  position: relative;
  margin-top: 0.625rem;
  color: #999;
  z-index: 5;
}
.blockquote-footer::before {
  display: none;
}

.text-white .blockquote {
  color: #fff;
}
.text-white .blockquote::before {
  color: rgba(255, 255, 255, 0.1);
}
.text-white .blockquote .blockquote-footer {
  color: rgba(255, 255, 255, 0.5);
}

.blockquote-list > * {
  margin-right: 15%;
  margin-bottom: 1.25rem;
}
.blockquote-list > *:last-child {
  margin-bottom: 0;
}
.blockquote-list > *:nth-child(2n + 2) {
  margin-right: 0;
  margin-left: 15%;
}

.blockquote-1 {
  margin-right: 0;
}

.blockquote-2 {
  border-radius: 0 2.5rem 2.5rem 2.5rem;
  padding: 2.5rem 2.5rem 2.5rem 6.5rem;
}
.blockquote-2:nth-child(2n + 2) {
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 0;
}
.blockquote-2::before {
  top: 2.25rem;
  font-size: 6rem;
  left: 2.5rem;
}
.blockquote-2 p {
  font-size: 1.125rem;
  font-weight: 400;
}
.blockquote-2 .blockquote-footer::before {
  display: none;
}

.review-listing .owl-item {
  padding: 6.25rem;
}
.review-listing .owl-item.active + .active {
  border-left: 1px solid #e5e5e5;
}

.review p {
  margin-top: 50px;
  font-size: 1.25rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
}

.review hr {
  width: 25%;
  margin-left: 0;
}

.review-score {
  border-radius: 0.25rem;
  display: inline-block;
  width: 5rem;
  line-height: 5rem;
  font-size: 1.5rem;
  color: #fff;
  background: #6E9EEB;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .review-listing .owl-item {
    text-align: center;
    padding: 6.25rem 0;
  }
  .review-listing hr {
    margin-left: auto;
    margin-right: auto;
  }
}

.text-white .bubble::before {
  color: inherit;
}

.text-white .bubble p {
  color: #fff;
}

.text-white .testimonial p {
  color: #fff;
}

.job {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-radius: 0.25rem;
  padding: 30px 15px;
  margin: 0;
  background: rgba(0, 0, 0, 0.1);
}
.job:hover {
  background: rgba(0, 0, 0, 0.2);
}
.job:not(:last-child) {
  margin-bottom: 10px !important;
}
.job > * {
  margin: 0;
}

.list-group-item {
  position: relative;
}
.list-group-item span {
  margin-bottom: 0;
}
.list-group-item [class*="icon"]:first-child {
  padding-right: 1rem;
}
.list-group-item [class*="icon"]:last-child {
  padding-left: 1rem;
}
.list-group-item a {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  display: inline-block;
  color: inherit;
}
.list-group-item a:hover {
  color: #6E9EEB;
}

.list-group-line .list-group-item {
  padding-left: 0;
  padding-right: 0;
  border: 0;
}
.list-group-line .list-group-item:not(:first-child)::before {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  width: 25%;
  height: 1px;
  background: #e5e5e5;
}

.list-group-minimal .list-group-item {
  padding: 0 0 0.5em 0;
  border: 0;
}
.list-group-minimal .list-group-item:last-child {
  padding: 0;
}
.list-group-minimal .list-group-item.active {
  background: transparent;
  color: #000;
}
.list-group-minimal .list-group-item [class*="icon"] {
  padding-right: 0;
}

.text-white .list-group-item:not(:first-child) {
  border-color: rgba(255, 255, 255, 0.2);
}

.text-white .list-group-item a {
  color: rgba(255, 255, 255, 0.5);
}
.text-white .list-group-item a:hover {
  color: #fff;
}

.text-white .list-group-item span {
  color: #fff;
}

.video-js {
  border-radius: 0.25rem;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  z-index: 50;
  background: transparent;
}

.video-3-4 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 42.6%;
}

.video-16-9 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.youtube .vjs-control-bar {
  display: none;
}

.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  line-height: 80px;
  font-size: 40px;
  background: none;
  color: #fff;
  border: 0;
  z-index: 5;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}
.video-js .vjs-big-play-button::before {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  display: block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #fff;
  content: "";
  font-size: 20px;
  color: #000;
  border-radius: 50%;
}

.video-js:hover .vjs-big-play-button {
  background: none;
}
.video-js:hover .vjs-big-play-button::before {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.jquery-background-video-wrapper {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
}

.jquery-background-video {
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
}

/**
 * Fade in videos
 * Note the .js class - so non js users still
 * see the video
 */
.js .video-bg video {
  opacity: 0;
}

.js .video-bg video.is-playing {
  opacity: 1;
}

.card-header,
.card-footer {
  padding: 1.875rem;
}

.card-body + .card-footer,
.card-header + .card-body {
  padding-top: 0;
}

.card-img-container {
  position: relative;
  overflow: hidden;
}
.card-img-container::before {
  border-radius: 0.25rem;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
}
.card-img-container .card-footer,
.card-img-container .card-header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.card-img-container .card-title {
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
  font-size: 2rem;
  margin: 0;
  color: #fff;
}

.card-meta {
  font-size: 1rem;
  color: #999;
}

.card-title a {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  color: #000;
}
.card-title a:hover {
  color: #6E9EEB;
}

.card-header-tabs {
  margin-top: -0.9375rem;
  margin-left: 0;
  border-right: 0;
}

.card-header-pills {
  margin-top: 0;
  margin-left: 0;
  border-right: 0;
}

.card-minimal {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent;
}
.card-minimal .card-body {
  padding: 0.9375rem;
}
.card-minimal .card-img-container {
  background: transparent;
}
.card-minimal .card-img-container::before {
  display: none;
}

.demo-preview .card-minimal .card-img-container {
  background: rgba(21, 21, 21, 0.1);
  padding: 2rem 2rem 0 2rem;
}
.demo-preview .card-minimal .card-img-container .card-img {
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
@media (max-width: 767.98px) {
  .demo-preview .card-minimal .card-img-container {
    padding: 1rem 1rem 0 1rem;
  }
}

.map > [class*="container"] {
  position: static;
  visibility: hidden;
}
.map > [class*="container"] [class*="col"] {
  visibility: visible;
  position: relative;
  z-index: 20;
}

.map-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.map-circle {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

@media (max-width: 1199.98px) {
  .map {
    padding-bottom: 50vh;
  }
}

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  width: 1em;
  line-height: 1em;
  box-sizing: content-box;
  text-align: center;
  border: 1px solid transparent;
}
[class^="icon-"][class*="-bordered"],
[class*=" icon-"][class*="-bordered"] {
  border-color: currentColor;
  border-radius: 50%;
  border-width: 2px;
  padding: 0.5em;
}
[class^="icon-"][class*="-filled"],
[class*=" icon-"][class*="-filled"] {
  border-radius: 50%;
  border-color: transparent;
  padding: 0.5em;
}

.svg-icon {
  display: inline-block;
}
.svg-icon svg {
  display: block;
  height: 1em;
  width: 1em;
  stroke: currentColor;
  fill: currentColor;
}
.svg-icon svg * {
  stroke: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.dropdown-menu {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 0;
}

.dropdown-item {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  font-size: 0.875rem;
}
.dropdown-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.dropdown-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.dropdown-item:active {
  color: rgba(255, 255, 255, 0.5);
}
.dropdown-item:active span {
  color: #fff;
}
.dropdown-item span {
  color: #333;
  font-size: 1rem;
}

.dropdown-divider {
  margin: 0;
}

@media (min-width: 1200px) {
  .navbar .dropdown-menu.show {
    animation: dropdown 0.2s ease forwards;
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-mega {
  position: static;
}
.dropdown-mega .dropdown-menu {
  top: 85px;
  width: 100%;
}
.dropdown-mega .dropdown-menu > .row {
  margin-right: 0;
  margin-left: 0;
}
.dropdown-mega .dropdown-menu > .row > [class*="col"] {
  padding-top: 15px;
  padding-bottom: 15px;
}
.dropdown-mega .dropdown-menu > .row > [class*="col"]:not(:first-child) {
  border-left: 1px solid #e5e5e5;
}

.mega-title {
  display: block;
  padding: 0 0.625rem;
  margin-bottom: 0.5rem;
  color: #000;
  letter-spacing: 0.05em;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.mega-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mega-list li a {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-radius: 0.25rem;
  display: block;
  font-size: 0.875rem;
  padding: 0.625rem;
  color: #999;
}
.mega-list li a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.mega-list li a.highlight {
  border: 1px solid #e5e5e5;
}

/* PrismJS 1.14.0
http://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*="language-"],
pre[class*="language-"] {
  font-family: "Roboto Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
code[class*="language-"]::-moz-selection,
code[class*="language-"]::selection,
pre[class*="language-"]::-moz-selection,
pre[class*="language-"]::selection {
  color: #fff;
  background: #6E9EEB;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*="language-"] {
  border-radius: 0.25rem;
  margin-bottom: 0;
  overflow: auto;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #999;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  background: rgba(255, 255, 255, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function,
.token.class-name {
  color: #dd4a68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.text-white .token.punctuation {
  color: rgba(255, 255, 255, 0.55);
}

.text-white .token.property,
.text-white .token.tag,
.text-white .token.boolean,
.text-white .token.number,
.text-white .token.constant,
.text-white .token.symbol,
.text-white .token.deleted {
  color: rgba(255, 255, 255, 0.55);
}

.text-white code[class*="language-"],
.text-white pre[class*="language-"] {
  color: #fff;
}

.text-white .token.selector,
.text-white .token.attr-name,
.text-white .token.string,
.text-white .token.char,
.text-white .token.builtin,
.text-white .token.inserted {
  color: #f3a83c;
}

.text-white .token.atrule,
.text-white .token.attr-value,
.text-white .token.keyword {
  color: #e3c038;
}

.component {
  padding-bottom: 0;
  overflow: visible;
}
.component > .container {
  margin-bottom: 20px;
}
.component [class*="container"] .row:not(:last-child) {
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .component {
    max-width: 95%;
  }
}
@media (min-width: 1600px) {
  .component {
    max-width: 80%;
  }
}
.component > .tab-content > .tab-pane {
  border-radius: 0.25rem;
  overflow: hidden;
}
.component > .tab-content > .tab-pane[class*="bg-light"] {
  border: 1px solid #e5e5e5;
}

.component-example {
  position: relative;
  padding: 75px 0;
  overflow: hidden;
}
.component-example[class*="-fit"] {
  padding: 0;
}
.component-example[class*="-fluid"] {
  padding: 30px 15px;
}

.component-details {
  position: relative;
  padding: 40px 0;
  border-top: 1px solid #e5e5e5;
}

.component-details-markup,
.component-details-reference {
  position: relative;
  padding-top: 30px;
}
.component-details-markup::before,
.component-details-reference::before {
  position: absolute;
  top: 0;
  left: 15px;
  content: "Markup";
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.component-details-reference::before {
  content: "Reference";
}

.btn-demo > .btn {
  margin: 0 0.5rem 0.5rem 0;
}

.text-white.component-details {
  border-color: rgba(255, 255, 255, 0.2);
}

[class*="badge"] {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}

.badge-blue {
  color: #fff;
  background-color: #0030ae;
}
a.badge-blue:hover,
a.badge-blue:focus {
  color: #fff;
  background-color: #00227b;
}
a.badge-blue:focus,
a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 48, 174, 0.5);
}

.badge-indigo {
  color: #fff;
  background-color: #6610f2;
}
a.badge-indigo:hover,
a.badge-indigo:focus {
  color: #fff;
  background-color: #510bc4;
}
a.badge-indigo:focus,
a.badge-indigo.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(102, 16, 242, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #6E9EEB;
}
a.badge-purple:hover,
a.badge-purple:focus {
  color: #fff;
  background-color: #371f89;
}
a.badge-purple:focus,
a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(72, 41, 178, 0.5);
}

.badge-pink {
  color: #fff;
  background-color: #e83e8c;
}
a.badge-pink:hover,
a.badge-pink:focus {
  color: #fff;
  background-color: #d91a72;
}
a.badge-pink:focus,
a.badge-pink.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(232, 62, 140, 0.5);
}

.badge-red {
  color: #fff;
  background-color: #fc2c38;
}
a.badge-red:hover,
a.badge-red:focus {
  color: #fff;
  background-color: #f20311;
}
a.badge-red:focus,
a.badge-red.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(252, 44, 56, 0.5);
}

.badge-orange {
  color: #151515;
  background-color: #f3a83c;
}
a.badge-orange:hover,
a.badge-orange:focus {
  color: #151515;
  background-color: #ed920f;
}
a.badge-orange:focus,
a.badge-orange.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(243, 168, 60, 0.5);
}

.badge-yellow {
  color: #151515;
  background-color: #e3c038;
}
a.badge-yellow:hover,
a.badge-yellow:focus {
  color: #151515;
  background-color: #cba81d;
}
a.badge-yellow:focus,
a.badge-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(227, 192, 56, 0.5);
}

.badge-green {
  color: #fff;
  background-color: #54af5d;
}
a.badge-green:hover,
a.badge-green:focus {
  color: #fff;
  background-color: #428e4a;
}
a.badge-green:focus,
a.badge-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(84, 175, 93, 0.5);
}

.badge-teal {
  color: #fff;
  background-color: #20c997;
}
a.badge-teal:hover,
a.badge-teal:focus {
  color: #fff;
  background-color: #199d76;
}
a.badge-teal:focus,
a.badge-teal.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(32, 201, 151, 0.5);
}

.badge-cyan {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-cyan:hover,
a.badge-cyan:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-cyan:focus,
a.badge-cyan.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.badge-white {
  color: #151515;
  background-color: #fff;
}
a.badge-white:hover,
a.badge-white:focus {
  color: #151515;
  background-color: #e6e6e6;
}
a.badge-white:focus,
a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.badge-gray {
  color: #fff;
  background-color: #555;
}
a.badge-gray:hover,
a.badge-gray:focus {
  color: #fff;
  background-color: #3c3c3c;
}
a.badge-gray:focus,
a.badge-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5);
}

.badge-gray-dark {
  color: #fff;
  background-color: #333;
}
a.badge-gray-dark:hover,
a.badge-gray-dark:focus {
  color: #fff;
  background-color: #1a1a1a;
}
a.badge-gray-dark:focus,
a.badge-gray-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.5);
}

.badge-dark-blue {
  color: #fff;
  background-color: #0030ae;
}
a.badge-dark-blue:hover,
a.badge-dark-blue:focus {
  color: #fff;
  background-color: #00227b;
}
a.badge-dark-blue:focus,
a.badge-dark-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 48, 174, 0.5);
}

.badge-transparent-white {
  color: #151515;
  background-color: rgba(255, 255, 255, 0.2);
}
a.badge-transparent-white:hover,
a.badge-transparent-white:focus {
  color: #151515;
  background-color: rgba(230, 230, 230, 0.2);
}
a.badge-transparent-white:focus,
a.badge-transparent-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.badge-transparent-black {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}
a.badge-transparent-black:hover,
a.badge-transparent-black:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}
a.badge-transparent-black:focus,
a.badge-transparent-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}

.badge-outline-blue {
  color: #0030ae;
  border: 1px solid #0030ae;
  background-color: transparent;
}

.badge-outline-indigo {
  color: #6610f2;
  border: 1px solid #6610f2;
  background-color: transparent;
}

.badge-outline-purple {
  color: #6E9EEB;
  border: 1px solid #6E9EEB;
  background-color: transparent;
}

.badge-outline-pink {
  color: #e83e8c;
  border: 1px solid #e83e8c;
  background-color: transparent;
}

.badge-outline-red {
  color: #fc2c38;
  border: 1px solid #fc2c38;
  background-color: transparent;
}

.badge-outline-orange {
  color: #f3a83c;
  border: 1px solid #f3a83c;
  background-color: transparent;
}

.badge-outline-yellow {
  color: #e3c038;
  border: 1px solid #e3c038;
  background-color: transparent;
}

.badge-outline-green {
  color: #54af5d;
  border: 1px solid #54af5d;
  background-color: transparent;
}

.badge-outline-teal {
  color: #20c997;
  border: 1px solid #20c997;
  background-color: transparent;
}

.badge-outline-cyan {
  color: #17a2b8;
  border: 1px solid #17a2b8;
  background-color: transparent;
}

.badge-outline-white {
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
}

.badge-outline-gray {
  color: #555;
  border: 1px solid #555;
  background-color: transparent;
}

.badge-outline-gray-dark {
  color: #333;
  border: 1px solid #333;
  background-color: transparent;
}

.badge-outline-dark-blue {
  color: #0030ae;
  border: 1px solid #0030ae;
  background-color: transparent;
}

.badge-outline-transparent-white {
  color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.badge-outline-transparent-black {
  color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
}

.badge-outline-primary {
  color: #6E9EEB;
  border: 1px solid #6E9EEB;
  background-color: transparent;
}

.badge-outline-secondary {
  color: #e5e5e5;
  border: 1px solid #e5e5e5;
  background-color: transparent;
}

.badge-outline-success {
  color: #54af5d;
  border: 1px solid #54af5d;
  background-color: transparent;
}

.badge-outline-info {
  color: #17a2b8;
  border: 1px solid #17a2b8;
  background-color: transparent;
}

.badge-outline-warning {
  color: #e3c038;
  border: 1px solid #e3c038;
  background-color: transparent;
}

.badge-outline-danger {
  color: #fc2c38;
  border: 1px solid #fc2c38;
  background-color: transparent;
}

.badge-outline-light {
  color: #f5f5f5;
  border: 1px solid #f5f5f5;
  background-color: transparent;
}

.badge-outline-dark {
  color: #151515;
  border: 1px solid #151515;
  background-color: transparent;
}

.breadcrumb {
  display: inline-flex;
  border: 1px solid #e5e5e5;
}

.breadcrumb-item {
  font-size: 0.875rem;
}
.breadcrumb-item a {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  color: #777;
}
.breadcrumb-item a:hover {
  opacity: 0.8;
}

.text-white .breadcrumb {
  border-color: rgba(255, 255, 255, 0.2);
}
.text-white .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.2);
}
.text-white .breadcrumb .breadcrumb-item a {
  color: #fff;
}
.text-white .breadcrumb .breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.5);
}

.breadcrumb-custom {
  border: 0;
  padding: 0;
}
.breadcrumb-custom .breadcrumb-item {
  position: relative;
  overflow: hidden;
}
.breadcrumb-custom .breadcrumb-item:nth-child(1) {
  z-index: 1;
}
.breadcrumb-custom .breadcrumb-item:nth-child(2) {
  z-index: 2;
}
.breadcrumb-custom .breadcrumb-item:nth-child(3) {
  z-index: 3;
}
.breadcrumb-custom .breadcrumb-item:nth-child(4) {
  z-index: 4;
}
.breadcrumb-custom .breadcrumb-item:nth-child(5) {
  z-index: 5;
}
.breadcrumb-custom .breadcrumb-item.active,
.breadcrumb-custom .breadcrumb-item a {
  display: block;
  padding: 15px 30px;
  border: 1px solid #f5f5f5;
  border-radius: 10rem;
  background: #fff;
}
.breadcrumb-custom .breadcrumb-item a {
  padding-right: 60px;
}
.breadcrumb-custom .breadcrumb-item a:hover {
  background: #6E9EEB;
  color: #fff;
}
.breadcrumb-custom .breadcrumb-item + .breadcrumb-item {
  margin-left: -40px;
}
.breadcrumb-custom .breadcrumb-item + .breadcrumb-item::before {
  display: none;
}

.masonry {
  padding: 0;
  list-style: none;
}
.masonry > li {
  position: relative;
}

.presentation-circle {
  overflow: visible;
}
.presentation-circle > figure {
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  z-index: 10;
}
.presentation-circle > figure::before {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: pulse 3s infinite;
  -moz-animation: pulse 3s infinite;
  -o-animation: pulse 3s infinite;
  animation: pulse 3s infinite;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: -1;
  animation-timing-function: cubic-bezier(0.1, 0.2, 0.3, 1);
}
.presentation-circle.with-delay > figure::before {
  animation-delay: 1s;
}

@-webkit-keyframes pulse {
  0% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 125%;
    height: 125%;
    opacity: 0;
  }
}

@-moz-keyframes pulse {
  0% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 125%;
    height: 125%;
    opacity: 0;
  }
}

@-o-keyframes pulse {
  0% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 125%;
    height: 125%;
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 125%;
    height: 125%;
    opacity: 0;
  }
}

.presentation-container > * {
  position: relative;
  z-index: 5;
}

.presentation {
  position: relative;
  z-index: 0;
}
.presentation img {
  position: absolute;
  max-width: initial;
  max-height: initial;
}
.presentation .top-0 {
  top: 0;
}
.presentation .top-25 {
  top: 25%;
}
.presentation .top-50 {
  top: 50%;
}
.presentation .top-75 {
  top: 75%;
}
.presentation .bottom-0 {
  bottom: 0;
}
.presentation .bottom-25 {
  bottom: 25%;
}
.presentation .bottom-50 {
  bottom: 50%;
}
.presentation .bottom-75 {
  bottom: 75%;
}
.presentation .left-0 {
  left: 0;
}
.presentation .left-25 {
  left: 25%;
}
.presentation .left-50 {
  left: 50%;
}
.presentation .left-75 {
  left: 75%;
}
.presentation .right-0 {
  right: 0;
}
.presentation .right-25 {
  right: 25%;
}
.presentation .right-50 {
  right: 50%;
}
.presentation .right-75 {
  right: 75%;
}
.presentation .vertical-align {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.presentation .horizontal-align {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

@media (max-width: 1199.98px) {
  .presentation-responsive {
    height: 40vh !important;
  }
  .presentation-responsive img {
    top: 0 !important;
    left: 0 !important;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .presentation-responsive img.vertical-align,
  .presentation-responsive img.horizontal-align {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.progress {
  border-radius: 0.25rem;
  overflow: visible;
}
.progress .progress-bar {
  border-radius: 0.25rem;
  position: relative;
}
.progress .progress-bar span {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  font-size: 12px;
  font-weight: 400;
  line-height: 1rem;
}

.progress-title {
  margin-bottom: 1em;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.text-white .progress {
  background: rgba(255, 255, 255, 0.1);
}

.text-white .progress-bar {
  background: #fff;
}
.text-white .progress-bar span {
  color: #fff;
}

.progress-circle {
  position: relative;
}
.progress-circle canvas {
  height: 100% !important;
  width: 100% !important;
}
.progress-circle strong {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 2.5rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  top: 50%;
  left: 50%;
}

.modal-content {
  position: relative;
}
.modal-content > *:not(.image) {
  z-index: 5;
}
.modal-content .image {
  border-radius: 0.25rem;
}
.modal-content .close {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  display: inline-block;
  margin: 0;
  padding: 0.5em;
  opacity: 1;
  background: #fff;
  border-radius: 50%;
}
.modal-content .close span {
  color: #000;
}
.modal-content .close:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
}

.modal-header::after {
  display: block;
  clear: both;
  content: "";
}

.modal-image {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  position: relative;
}
.modal-image .close {
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
}

/*# sourceMappingURL=style.css.map */

.navbar-logo {
  height: 50px !important;
  margin-top: 5px;
}

.dark-navigation-bar {
  height: 75px;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;

  user-select: none;
  position: absolute;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid transparent;
  /* background-color: white; */
  color: black;
}



.transparent-navigation-bar {
  height: 75px;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  user-select: none;
  position: absolute;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid transparent;
}

.sticky-navigation-bar {
  height: 75px;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  user-select: none;
  position: fixed;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid transparent;
  background-color: white;
  color: black;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
}
.navbar-nav {
  margin-left: 2.2rem;
  margin-top: 0.3rem;
}

.text-white {
  color: white !important;
  cursor: pointer;
}

.text-black {
  color: black !important;
  cursor: pointer;
}

#menuToggle
{
  position: relative;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}



#menuToggle a
{
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: tomato;
}


#menuToggle input
{
  width: 40px;
  height: 32px;
  position: fixed;

  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: none;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */


.background-black {
  background: black;
}

.background-white {
  background: white;
}



/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}

.menu-theme {
  color: black !important;
  cursor: pointer;
  font-size: 20px;
}

@media screen and (max-width: 640px) {

  .dark-navigation-bar {
    height: 70px;

  }
.sticky-navigation-bar {
  height: 70px;

}
.transparent-navigation-bar {
  height: 70px;
}
.navbar-logo {
  margin-left: 10px;
  height: 55px !important;
  margin-top: 0px;
}

#menuToggle  {
  padding-top: 5px;
  padding-right: 20px !important;
}

#menuToggle span {
  display:block;
  width: 30px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  
  border-radius: 1px;
  
  z-index: 1;
  
  transform-origin: 3px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}
#menu
{
  position: fixed;
  top: 0px;
  right: 0px;
  width: 70vw;
  margin-top: 0px;
  padding-top: 170px;
  padding-left: 20px;
  height: 100vh;  
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  background-color: white;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

}
}